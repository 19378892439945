import FlagsIcon from "@components/FlagsIcon/FlagsIcon"
import CSBadge from "@components/Form/CSBadge"
import SectionSubGroup from "@components/SectionGroup/SectionSubGroup"
import { Customer, CustomerShippingInfo as _CustomerShippingInfo } from "cs.node.utils/csapi/provisioning"
import Localization from "@localization/Index"
import { useState } from "react"
import { Badge } from "react-bootstrap"
import EditCustomerShippingInfo from "./EditCustomerShippingInfo"

type Props = {
    isEditable: boolean
    isDeletable: boolean
    index: number
    shippingInfo: _CustomerShippingInfo
    onChange: (shippingInfo?: _CustomerShippingInfo)=>unknown
    onSetDefault: ()=>unknown
}

const CustomerShippingInfo = (props: Props)=>{
    const { isEditable, isDeletable, shippingInfo, index, onChange, onSetDefault } = props
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const { alias, first_name, last_name, company_name, address={}, is_default, ext_ids, contacts } = shippingInfo
    const { address_lines, city, province, province_code, postal_code, country_code } = address
    const nominee = (first_name && last_name) ? `${first_name} ${last_name}`: company_name

    const remove = ()=>{
        return onChange(undefined)
    }

    const openEditShippingInfo = ()=>{
        setIsEditModalOpen(true)
    }

    return <>
        <div className="p-0">
            <SectionSubGroup className="m-2" >
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column justify-content-center align-items-left" style={{minHeight: '125px'}}>
                    <div className="fw-bold fs-5 d-flex align-items-center">
                        <span>{alias ?? `${Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.INDIRIZZO} #${index+1}`}</span>
                        {is_default && <Badge className="ms-3" bg="info">Default</Badge>}
                    </div>
                    {nominee && <div className="fs-6">{nominee}</div>}
                    {(address_lines ?? [])?.length > 0 &&
                        <div className="d-flex align-items-center justify-content-start">
                            {address_lines?.[0] && <div className="fs-6">{address_lines?.[0]}</div>}
                            {address_lines?.[1] && <div className="fs-6 ms-1">{` \\ ${address_lines?.[1]}`}</div>}
                        </div>
                    }
                    <div className="fs-6">
                        {postal_code} {city}
                    </div>
                    <div className="fs-6">
                        {province} {province_code && `(${province_code})`}
                    </div>
                    {country_code &&
                        <div className="d-flex align-items-center justify-content-start fs-6">
                            <FlagsIcon flagId={country_code.toLowerCase()} round />
                            <span className="ms-2">{Localization.COUNTRIES_CODE[country_code as keyof typeof Localization.COUNTRIES_CODE]}</span>
                        </div>
                    }
                </div>
                {isEditable && <div className="d-flex align-items-center justify-content-end">
                    {isDeletable && <i role="button" className={`bi bi-trash3 text-muted fs-4 ms-2`} onClick={() => {remove()}}></i>}
                    <i role="button" className={`bi bi-pencil text-muted fs-4 ms-2`} onClick={() => {openEditShippingInfo()}}></i>
                    <i role="button" className={`bi ${is_default?"bi-check-square text-info":"bi-square text-muted"} fs-4 ms-2`} onClick={() => {if(!is_default) {onSetDefault()}}}></i>
                </div>}
                {!isEditable && <div className="d-flex align-items-center justify-content-end">
                    <i role="button" className={`bi bi-eye text-muted fs-4 ms-2`} onClick={() => {openEditShippingInfo()}}></i>
                    <i className={`bi ${is_default?"bi-check-square text-info":"bi-square text-muted"} fs-4 ms-2`}></i>
                </div>}
            </div>
        </SectionSubGroup>
        {isEditModalOpen && <EditCustomerShippingInfo shippingInfo={shippingInfo} show={isEditModalOpen} onHide={()=>setIsEditModalOpen(false)} isEditable={isEditable} onUpdate={(updatedShippingInfo)=>{
            onChange(updatedShippingInfo)
        }} />}
        </div>
    </>
}

export default CustomerShippingInfo