import SectionGroup from "@components/SectionGroup/SectionGroup"
import { Variant } from "@interfaces/Variant"
import Localization from "@localization/Index"

type Props = {
    icon?: string,
    title?: string,
    subtitle?: string,
    variant?: Variant,
    data_cy?: string
}

const InfoBoxSearch = (props: Props) =>{
    const {icon="bi-search", title, subtitle=Localization.FILTERS.SELEZIONARE_ALMENO_UN_FILTRO, variant="muted", data_cy} = props

    return (
        <SectionGroup>
            <div className="d-flex flex-column align-items-center justify-content-center" style={{height: '400px'}}  data-cy={`${data_cy}`}>
                <i className={`${icon} font-5x text-${variant} mb-3`}></i>
                {title && <span className={`h2 text-${variant}`}><strong>{title}</strong></span>}
                {subtitle && <span className={`text-${variant}`}>{subtitle}</span>}
            </div>
        </SectionGroup>
    )
}

export const NoFilterBoxSearch = (props: {subtitle?: string})=>{
    const { subtitle=Localization.FILTERS.SELEZIONARE_ALMENO_UN_FILTRO } = props
    return (
        <InfoBoxSearch icon="bi-search" subtitle={subtitle} data_cy="infobox-search-nofilter" />
    )
}

export const ErrorBoxSearch = ({subtitle=Localization.ERRORE_GENERICO})=>{
    return (
        <InfoBoxSearch variant="danger" icon="bi-x-circle" subtitle={subtitle} data_cy="infobox-error"/>
    )
}

export const WarningBoxSearch = ({subtitle=Localization.ATTENZIONE})=>{
    return (
        <InfoBoxSearch variant="warning" icon="bi-exclamation-triangle" subtitle={subtitle} data_cy="infobox-warning"/>
    )
}

export default InfoBoxSearch