
import { getWarehouses } from "@api/Warehouses"
import { Warehouse } from "cs.node.utils/csapi/provisioning"
import Localization from "@localization/Index"
import { getLocalStorageItem, setLocalStorageItem } from "@utils/LocalStorage"

export enum OVERRIDE_WAREHOUSE_ALGORITHM {
    INHERIT= "INHERIT",
    NONE= 'NONE',
    NO_OP= 'NO_OP',
    WHS_PRIORITY= 'WHS_PRIORITY',
    TEMP_COMMIT= 'TEMP_COMMIT',
    SHOP_DRIVEN= 'SHOP_DRIVEN'
}

const overrideWarehouseAlgorithms = [
    {id: OVERRIDE_WAREHOUSE_ALGORITHM.INHERIT, label: Localization.OVERRIDE_WAREHOUSE_ALGORITHM.EREDITA_STRATEGIA_DEFAULT},
    // {id: OVERRIDE_WAREHOUSE_ALGORITHM.NONE, label: Localization.OVERRIDE_WAREHOUSE_ALGORITHM.NONE},
    {id: OVERRIDE_WAREHOUSE_ALGORITHM.NO_OP, label: Localization.OVERRIDE_WAREHOUSE_ALGORITHM.NO_OP},
    {id: OVERRIDE_WAREHOUSE_ALGORITHM.WHS_PRIORITY, label: Localization.OVERRIDE_WAREHOUSE_ALGORITHM.WHS_PRIORITY},
    // {id: OVERRIDE_WAREHOUSE_ALGORITHM.TEMP_COMMIT, label: Localization.OVERRIDE_WAREHOUSE_ALGORITHM.TEMP_COMMIT},
    // {id: OVERRIDE_WAREHOUSE_ALGORITHM.SHOP_DRIVEN, label: Localization.OVERRIDE_WAREHOUSE_ALGORITHM.SHOP_DRIVEN},
]
/**
 * Get from local storage the list of user warehouses if exists, else load them from the server
 * @returns the list of user sho
 * 
 */
const getUserWarehouses = async ()=>{
    let userWarehouses = getLocalStorageItem('user.warehouses') as Warehouse[]
    if(!userWarehouses) {
        // fetch warehouses from server
        const warehousesResponse = await getWarehouses()
        userWarehouses = warehousesResponse.content ?? []
        setLocalStorageItem('user.warehouses', userWarehouses)
    }
    return userWarehouses
}

const getWarehousesFromIds = (warehousesIds:string[] = []) => {
    const userWarehouses = getLocalStorageItem('user.warehouses') as Warehouse[]
    if(!userWarehouses) { return [] }
    return userWarehouses.filter(warehouse=>warehousesIds.includes(warehouse._id?.$oid!))
}

const getWarehouseFromId = (warehouseId:string | undefined) => {
    if(!warehouseId) return undefined;
    const userWarehouses = getLocalStorageItem('user.warehouses') as Warehouse[]
    if(!userWarehouses) { return undefined }
    return userWarehouses.find(warehouse=>warehouse._id?.$oid === warehouseId)
}

const getWarehouseIdFromName = (warehouseName:string | undefined) => {
    if(!warehouseName) return undefined;
    const userWarehouses = getLocalStorageItem('user.warehouses') as Warehouse[]
    if(!userWarehouses) { return undefined }
    return userWarehouses.find(warehouse=>warehouse.name === warehouseName)
}

const getOverrideWarehouseAlgorithm = (id: OVERRIDE_WAREHOUSE_ALGORITHM) =>{
    return overrideWarehouseAlgorithms.find(owa=>owa.id === id)
}


export { overrideWarehouseAlgorithms, getOverrideWarehouseAlgorithm, getUserWarehouses, getWarehousesFromIds, getWarehouseFromId,getWarehouseIdFromName}