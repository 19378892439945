import { Order } from "cs.node.utils/csapi/provisioning"
import OrdersListItem from "@components/OrdersList/OrdersListItem"
import { getShopsFromIds } from "@utils/ShopsUtils"

type OrdersListProps = {
    orders?: Order[],
    loading?: boolean,
    onSelectItem?: (id:string)=>any
    onContextMenu?: (e: React.MouseEvent, id: string)=>any
}

const OrdersList = (props: OrdersListProps)=>{
    const { orders, onSelectItem, loading=false, onContextMenu } = props
    const shopIds = orders?.map(order=>order.shop_id?.$oid!) ?? []
    const shops = getShopsFromIds(shopIds)
    
    if(loading) return <>
        <OrdersListItem loading />
        <OrdersListItem loading />
        <OrdersListItem loading />
        <OrdersListItem loading />
        <OrdersListItem loading />
    </>

    return <>
        {orders?.map(order=>{
            const shop = shops.find(shop => shop._id?.$oid === order.shop_id?.$oid)
            return <OrdersListItem 
                        order={order} 
                        shop={shop} 
                        key={`orderItem_${order._id?.$oid}`} 
                        onClick={()=>{
                            return onSelectItem?.(order._id?.$oid!)
                        }} 
                        onContextMenu={onContextMenu}
                        />
        })}
    </>
}

export default OrdersList