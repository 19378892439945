import Localization from '@localization/Index'
import { debounce } from '@utils/FunctionUtils'
import { forwardRef } from 'react'
import Form from 'react-bootstrap/esm/Form'
import FormLabel from 'react-bootstrap/esm/FormLabel'
import Select, { OptionsOrGroups, GroupBase, MenuPlacement, StylesConfig, SelectInstance, ThemeConfig, CSSObjectWithLabel } from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import CSLabel from './CSLabel'
import { checkResponseStatus } from '@api/Utils'

type ClientProps = {
    client: true
    server?: undefined
    options: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined,
}

type ServerProps = {
    server: true
    client?: undefined,
    loadOptions: Function
}

type Props =  {
    controlId: string,
    label?: string | JSX.Element,
    mandatory?: boolean,
    hasError?: boolean,
    errorText?: string,
    multi?: boolean,
    width?: number,
    onChange?: Function,
    value?: CSOption | ReadonlyArray<CSOption> | null,
    defaultValue?: CSOption | ReadonlyArray<CSOption> | null,
    isOptionDisabled?: (option:unknown)=>boolean,
    placeholder?: string,
    isClearable?: boolean,
    readonly?: boolean,
    disabled?: boolean,
    menuPlacement?: MenuPlacement
    data_cy?: string,
    styles?: StylesConfig<unknown, boolean, GroupBase<unknown>>
    components?: Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>>
    className?: string
    isCreatable?: boolean
    helpInline?: JSX.Element
    tabIndex?: number

} & (
    | ServerProps
    | ClientProps
)

export interface CSOption {
    value: string,
    label: string,
    isDisabled?: boolean
}

const CSReactSelect = forwardRef<SelectInstance, Props>((props:Props, ref:any) =>{

    const { mandatory=false, className, server, client, label, errorText, hasError, multi, controlId, width, onChange, value, defaultValue, placeholder=Localization.REACT_SELECT.PLACEHOLDER, isClearable=false, data_cy, styles, components, readonly=false, menuPlacement='auto', isOptionDisabled=()=>false, disabled=false, isCreatable=false, helpInline, tabIndex } = props
    const { options } = props as ClientProps
    const { loadOptions } = props as ServerProps

    const debouncedLoadOptions = debounce(async (inputValue:string, callback:Function) =>{
        try{
            const results = await loadOptions(inputValue)
            callback(results)
        } catch(error: any) {
            checkResponseStatus(error)
            callback([])
        }
    }, 500)

    const readonlyValue = defaultValue?(multi?(defaultValue as CSOption[]).map(option=>option.label).join(', '):(defaultValue as CSOption).label): <>&nbsp;</>

    const theme:ThemeConfig=(theme) => ({
        ...theme,
        borderRadius: 10,
        colors: {
            ...theme.colors,
            primary25: '#f9f9f9',
            primary: '#c4cada',
            dangerLight: theme.colors.neutral10,
            danger: theme.colors.primary
        },
    })

    const selectStyles:StylesConfig = {
        ...styles,
        menu: (baseStyle) =>{
            return {
                ...baseStyle,
                zIndex: 100
            }
        },
        control:(baseStyles) => {
        const appliedStyles:CSSObjectWithLabel = {
            ...baseStyles,
            minHeight: '45px',
            paddingLeft: '10px'
        }
        if(hasError) {
            appliedStyles.borderColor = '#dc3545'
        }

        return appliedStyles

    }}

    const SelectComponent = isCreatable?CreatableSelect:Select
    const AsyncSelectComponent = isCreatable?AsyncCreatableSelect: AsyncSelect

    return (
        <Form.Group className={className} controlId={controlId} style={{position: 'relative', width: !!width?`${width}px`:'100%'}} data-cy={`select-${data_cy}`}>
            {label && <CSLabel mandatory={mandatory} label={label} helpInline={helpInline} />}
            {client && !readonly && <SelectComponent
                ref={ref}
                menuPlacement={menuPlacement}
                onChange={(newValue)=>onChange?.(newValue ?? undefined)}
                value={!value?null:value}
                defaultValue={defaultValue}
                isOptionDisabled={(option:unknown)=>{return isOptionDisabled(option)}}
                id={`CSSelect_${controlId}`}
                inputId={controlId}
                options={options} 
                noOptionsMessage={()=>{return Localization.REACT_SELECT.NESSUN_RISULTATO}}
                isMulti={multi}
                isDisabled={disabled}
                data-cy={`select-${data_cy}`} 
                placeholder={placeholder} 
                isClearable={isClearable}
                theme={theme}
                styles={selectStyles}
                formatCreateLabel={(value)=>value}
                components={components}
                tabIndex={tabIndex}
                />
            }
            {server && !readonly && <AsyncSelectComponent 
                        ref={ref}
                        onChange={(newValue)=>onChange?.(newValue ?? undefined)}
                        value={!value?null:value}
                        defaultValue={defaultValue}
                        isOptionDisabled={(option:unknown)=>{return isOptionDisabled(option)}}
                        id={`CSSelect_${controlId}`}
                        inputId={controlId}
                        isMulti={multi}
                        isDisabled={disabled}
                        placeholder={placeholder} 
                        loadOptions={debouncedLoadOptions}
                        noOptionsMessage={()=>{return Localization.REACT_SELECT.NESSUN_RISULTATO}}
                        isClearable={isClearable}
                        theme={theme}
                        styles={selectStyles}
                        formatCreateLabel={(value)=>value}
                        components={components}
                        tabIndex={tabIndex}
                        allowCreateWhileLoading
                    />
            }
            {readonly &&<div style={{width}}><p className="text-muted"><strong>{readonlyValue}</strong></p></div>}
            <FormLabel className='cs-text text-danger' style={{position: 'absolute', bottom:'-25px'}} visuallyHidden={!hasError}>
                <small>{errorText}</small>
            </FormLabel>
        </Form.Group>
        
    )
})

export default CSReactSelect