import { ShipmentStatus as Shipment_Status, ShipmentStatusStatusEnum } from "cs.node.utils/csapi/provisioning";

const shipmentStatuses = [
    { status: ShipmentStatusStatusEnum.Ongoing, icon: "bi-pencil-square", color: "secondary"},
    { status: ShipmentStatusStatusEnum.PartiallyShipped, icon: "bi-truck-flatbed", color: "info"},
    { status: ShipmentStatusStatusEnum.Shipped, icon: "bi-truck", color: "primary"},
    { status: ShipmentStatusStatusEnum.Delivered, icon: "bi-check-square", color: "success"},
    
]

function getNearestShipmentStatus(statusHistory: Shipment_Status[]) : Shipment_Status | null {
  const now = new Date();
  let nearestStatus: Shipment_Status | null = null;
  let timeDifference = Infinity;

  for (const status of statusHistory) {
    const statusDate: Date = new Date(status.date?.$date || '');

    if (!isNaN(statusDate.getTime())) { // Assicurati che la data sia valida
      const difference = Math.abs(now.getTime() - statusDate.getTime());

      if (difference < timeDifference) {
        timeDifference = difference;
        nearestStatus = status;
      }
    }
  }
  return nearestStatus;
}


const getShipmentStatus = (status?:ShipmentStatusStatusEnum)=>{
    return shipmentStatuses.find(orderStatus=>orderStatus.status===status) ?? { status: "OTHER", icon: "bi-question-circle", color: "muted"}
}

export { getShipmentStatus, shipmentStatuses, getNearestShipmentStatus }