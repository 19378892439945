import { BusinessConditionStatusEnum, CustomerBusinessConditionReferenceStatusEnum, ShopBusinessConditionReferenceStatusEnum } from "cs.node.utils/csapi/provisioning"
import { Variant } from "@interfaces/Variant"
import Localization from "@localization/Index"
import { QueueEntryObjectStatusEnum } from "./QueueUtils"

export type BusinessConditionStatus = {
    id: BusinessConditionStatusEnum,
    label: string,
    variant: Variant
    bgVariant?: Variant
    shortDescription: string,
    extendedDescription: string
}

export type BusinessConditionShopAssociationStatus = {
    id: ShopBusinessConditionReferenceStatusEnum,
    label: string,
    variant: Variant
}

export type BusinessConditionCustomerAssociationStatus = {
    id: CustomerBusinessConditionReferenceStatusEnum,
    label: string,
    variant: Variant
}

export type BusinessConditionQueueEntry = {
    status: QueueEntryObjectStatusEnum,
    report: {
        step1?: {
            finished: boolean,
            elapsed_ms: number
        },
        step2?: {
            finished: boolean,
            elapsed_ms: number
        },
        step3?: {
            finished: boolean,
            elapsed_ms: number,
            current_shop?: number,
            total_shops?: number
        },
        step4?: {
            finished: boolean,
            elapsed_ms: number
        },
        step5?: {
            finished: boolean,
            elapsed_ms: number
        }
    }
}

const POLLING_BC_SECONDS_INTERVAL = 20 * 1000

const businessConditionStatuses:BusinessConditionStatus[] = [
        {id: "PENDING", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.PENDING, variant: 'warning', shortDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.SHORT.PENDING, extendedDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.EXTENDED.PENDING },
        {id: "PROCESSING", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.PROCESSING, variant: 'primary', shortDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.SHORT.PROCESSING, extendedDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.EXTENDED.PROCESSING },
        {id: "CONFIRMED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.CONFIRMED, variant: 'success', shortDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.SHORT.CONFIRMED, extendedDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.EXTENDED.CONFIRMED },
        {id: "TO_BE_DEPRECATED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.TO_BE_DEPRECATED, variant: 'muted', shortDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.SHORT.TO_BE_DEPRECATED, extendedDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.EXTENDED.TO_BE_DEPRECATED },
        {id: "DEPRECATED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.DEPRECATED, variant: 'muted', bgVariant: 'secondary', shortDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.SHORT.DEPRECATED, extendedDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.EXTENDED.DEPRECATED },
        {id: "TO_BE_DISABLED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.TO_BE_DISABLED, variant: 'muted', shortDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.SHORT.TO_BE_DISABLED, extendedDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.EXTENDED.TO_BE_DISABLED },
        {id: "DISABLED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.DISABLED, variant: 'muted', shortDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.SHORT.DISABLED, extendedDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.EXTENDED.DISABLED },
        {id: "TO_BE_CANCELED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.TO_BE_CANCELED, variant: 'danger', shortDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.SHORT.TO_BE_CANCELED, extendedDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.EXTENDED.TO_BE_CANCELED },
        {id: "CANCELED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.CANCELED, variant: 'danger', shortDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.SHORT.CANCELED, extendedDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.EXTENDED.CANCELED },
        {id: "TO_BE_ENABLED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.TO_BE_ENABLED, variant: 'success', shortDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.SHORT.TO_BE_ENABLED, extendedDescription: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.EXTENDED.TO_BE_ENABLED },
]

const businessConditionShopAssociationStatuses: BusinessConditionShopAssociationStatus[] = [
    {id: "PENDING", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.PENDING, variant: 'warning' },
    {id: "CONFIRMED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.CONFIRMED, variant: 'success' },
    {id: "TO_BE_CANCELED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.TO_BE_CANCELED, variant: 'danger' },
    {id: "CANCELED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.CANCELED, variant: 'danger' },
]

const businessConditionCustomerAssociationStatuses: BusinessConditionCustomerAssociationStatus[] = [
    {id: "PENDING", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.PENDING, variant: 'warning'},
    {id: "CONFIRMED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.CONFIRMED, variant: 'success' },
    {id: "TO_BE_CANCELED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.TO_BE_CANCELED, variant: 'danger' },
    {id: "CANCELED", label: Localization.PAGE_BUSINESS_CONDITIONS.STATUS.LABEL.CANCELED, variant: 'danger' },
]


const getBusinessConditionStatus = (id:BusinessConditionStatusEnum)=>{
    return businessConditionStatuses.find(bcs=>bcs.id === id)
}

const getBusinessConditionShopAssociationStatus = (id: ShopBusinessConditionReferenceStatusEnum)=>{
    return businessConditionShopAssociationStatuses.find(bcs=>bcs.id === id)
}

const getBusinessConditionCustomerAssociationStatus = (id: CustomerBusinessConditionReferenceStatusEnum)=>{
    return businessConditionCustomerAssociationStatuses.find(bcs=>bcs.id === id)
}

export { POLLING_BC_SECONDS_INTERVAL, businessConditionStatuses, getBusinessConditionStatus, getBusinessConditionShopAssociationStatus, getBusinessConditionCustomerAssociationStatus }