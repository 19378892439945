import { getQueue } from "@api/Queues"
import PageHeader from "@components/Page/PageHeader"
import { QueueEntryObject } from "cs.node.utils/csapi/provisioning"
import Localization from "@localization/Index"
import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { EntitySingleResponseQueueEntryObject, SingleMetadata } from 'cs.node.utils/csapi/provisioning'
import QueueLoading from "./QueueLoading"
import QueueError from "./QueueError"
import QueueNotFound from "./QueueNotFound"
import { findQueueItemByName, QueueItem, queues_list } from "@utils/QueueUtils"
import { ErrorBoxSearch } from "@components/InfoBoxSearch/InfoBoxSearch"
import QueueEmptyBox from "./QueueEmptyBox"
import { Container, Tabs, Tab, Row } from "react-bootstrap"
import QueueDetailsInfo from "@components/Queue/QueueDetailsInfo"
import { AuthContext } from "@providers/AuthProvider"
import React from "react"
import { UserRoleEnum } from "@interfaces/User"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import CSSpan from "@components/HTMLElements/CSSpan"
import { copyToClipboard } from "@utils/StringUtils"
import QueueDetailDetails from "@components/Queue/QueueDetailDetails"
import { JSONTree } from "react-json-tree"

enum PageState {
    LOADING = "LOADING",
    IDLE = "IDLE",
    ERROR = "ERROR",
    NOT_FOUND = "NOT_FOUND",
    DATA_LOADING_ERROR ="DATA_LOADING_ERROR",
    NO_DATA= "NO_DATA"
}

const QueueDetails = () =>{
    const { id, selected_queue_item_Id } = useParams()
    const [pageState, setPageState] = useState(PageState.LOADING)
    const [entry, setEntry] = useState<QueueEntryObject[]>([])
    const [queueInfo, setQueueInfo] = useState<QueueItem | undefined>()
    const [details, setDetails]= useState<QueueEntryObject>()
    const [metadata, setMetadata]= useState<SingleMetadata>()
    const { search } = useLocation()
    const [currentTab, setCurrentTab] = useState<string>(new URLSearchParams(search).get("t") ?? "0")
    const auth = React.useContext(AuthContext)
    const { userData } = auth

    const breadcrumbs = [
        {text: Localization.MENU.IL_TUO_CLOUDSTORE, link: '/'},
        {text: Localization.MENU.QUEUES , link: '/queues'},
        {text: queueInfo?.label??'', link: `/queues/${id}`},
        {text: `${selected_queue_item_Id}`}
    ]

    useEffect(()=>{
        setPageState(PageState.IDLE)
        setQueueInfo(findQueueItemByName(id??''))
        loadQueueDetails()
    },[])

    const loadQueueDetails= async() =>{
        try { 
            if(!id) {
                setPageState(PageState.ERROR)
                return
            }

            const queueDetails : EntitySingleResponseQueueEntryObject = await getQueue({
                queueId: id,
                entryId: selected_queue_item_Id??''
            })

            if(!queueDetails ) {
                setPageState(PageState.NOT_FOUND)
                return
            }
            setDetails(queueDetails.content)
            setMetadata(queueDetails._metadata)
            setPageState(PageState.IDLE)

        } catch(e) {
            console.log(e)
            setPageState(PageState.ERROR)
        }
    }

    const handleCopy = () => {
        const text = JSON.stringify(details, null, 2) 
        copyToClipboard({ text })
      }
    
    const isQueueLoading = pageState === PageState.LOADING
    const isQueueError = pageState === PageState.ERROR
    const isQueueNotFound = pageState === PageState.NOT_FOUND
    const isQueueAvailable = !isQueueLoading && !isQueueError && !isQueueNotFound
    const isLoadingError = pageState === PageState.DATA_LOADING_ERROR
    const isNoData = pageState === PageState.NO_DATA

    const detailsJSON = details ? JSON.stringify(details, null, 2) : ''
    const configDetails: QueueItem =  queues_list.filter(queue => queue.name === id)[0]

    return <>
            <PageHeader title={Localization.MENU.QUEUES} breadcrumbs={breadcrumbs} />
            <div>
                {isQueueLoading && <QueueLoading />}
                {isQueueError && <QueueError />}
                {isQueueNotFound && <QueueNotFound />}
                {isLoadingError && <ErrorBoxSearch />}
                {(!entry || isNoData) && <QueueEmptyBox />}
                {isQueueAvailable && entry && 
                
                <Container fluid className="mt-4" >
                <Tabs
                    id="order-tabs"
                    activeKey={currentTab}
                    onSelect={(k) => setCurrentTab(k ?? "0")}
                    className="cs-tabs mt-3"
                    defaultActiveKey="0"
                    >
                    <Tab eventKey="0" title={Localization.PAGE_QUEUES.DETAILS.TAB_INFO}>
                        {details && metadata && <QueueDetailsInfo details={details} metadata={metadata} configDetails={configDetails}></QueueDetailsInfo>}
                    </Tab>
                    { userData.role === UserRoleEnum.SUPPORT &&
                    <Tab eventKey="1" title={Localization.PAGE_QUEUES.DETAILS.TAB_PAYLOAD} mountOnEnter>
                        <div className="row mt-field d-flex align-items-stretch">
                            <div className="col-md-24 d-flex flex-column" >
                            <SectionGroup className='flex-grow-1' title={Localization.PAGE_QUEUES.DETAILS.TAB_PAYLOAD} 
                                toolbar={ <CSSpan role="button" onClick={handleCopy}><i className="bi-copy font-2x text-muted" /></CSSpan>}> 
                                <JSONTree data={details} 
                                    hideRoot
                                    theme={{
                                        tree: ({ style }) => ({
                                            style: { ...style, backgroundColor: undefined, padding: '5px' }, // removing default background color from styles
                                            className:  'myTreeClassName'
                                        })
                                    }}
                                    shouldExpandNodeInitially={()=>true}
                                    labelRenderer={([key]) => <span>{key}</span>}
                                    valueRenderer={(raw) => <span>{raw as JSX.Element}</span>}
                                />
                            </SectionGroup>
                            </div>
                        </div> 
                    </Tab>
                    }
                    { userData.role === UserRoleEnum.SUPPORT && details?.report &&
                    <Tab eventKey="2" title={Localization.PAGE_QUEUES.DETAILS.REPORT} mountOnEnter>
                        <div className="row mt-field d-flex align-items-stretch">
                            <div className="col-md-24 d-flex flex-column" >
                            <SectionGroup className='flex-grow-1' title={Localization.PAGE_QUEUES.DETAILS.REPORT} 
                                toolbar={ <CSSpan role="button" onClick={handleCopy}><i className="bi-copy font-2x text-muted" /></CSSpan>}> 
                                <JSONTree data={details?.report} 
                                    hideRoot
                                    theme={{
                                        tree: ({ style }) => ({
                                            style: { ...style, backgroundColor: undefined, padding: '5px' }, // removing default background color from styles
                                            className:  'myTreeClassName'
                                        })
                                    }}
                                    shouldExpandNodeInitially={()=>true}
                                    labelRenderer={([key]) => <span>{key}</span>}
                                    valueRenderer={(raw) => <span>{raw as JSX.Element}</span>}
                                />
                            </SectionGroup>
                            </div>
                        </div> 
                    </Tab>
                    }
                    {details && configDetails.custon_details_detail &&
                        <Tab eventKey="3" title={Localization.PAGE_QUEUES.DETAILS.TAB_DETAIL} mountOnEnter>
                            <QueueDetailDetails  details={details} configDetails={configDetails}></QueueDetailDetails>
                        </Tab>
                        }
                </Tabs>
                
            </Container>
                }
            </div>
        </>
}

export default QueueDetails