import styles from'./css/FlagsIcon.module.scss'
import React from 'react'
import { LanguageId, FlagsIconProps } from '@interfaces/Icons'
import Localization from '@localization/Index'

const FlagsIcon: React.FC<FlagsIconProps> = ({ flagId, size = 18, round = false, onClick, title=true, disabled=false, role=undefined, borderColor=undefined, hover=false}) => {
    if (!flagId) return <></>
    const inlineStyles: React.CSSProperties = {}
    const outlineStyles: React.CSSProperties = {}
    if(round) {
      inlineStyles.borderRadius = '50%'
      inlineStyles.overflow = "hidden"
      outlineStyles.borderRadius = '50%'
      outlineStyles.overflow = "hidden"
    }

    let innerSizeModificator = 0
    if(borderColor) {
      outlineStyles.border = `2px solid ${borderColor}`
      innerSizeModificator = 10
    }

    const upperCaseCountryId = flagId.toUpperCase() as keyof typeof LanguageId 
    const titleLabel = title ? Localization.LANGUAGES[upperCaseCountryId] : ''
  
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ ...outlineStyles, width: `${size}px`, height: `${size}px` }}>
        <div
          role={role}
          className={`fi fi-${flagId}${round ? '-r' : ''} ${disabled ?styles['disabled-flags']:""} ${(hover && !disabled)?styles['hover-flags']:""}`}
          style={{ ...inlineStyles, width: `${size-innerSizeModificator}px`, height: `${size-innerSizeModificator}px` }}
          onClick={onClick}
          title={titleLabel}
        >
        </div>
      </div>
    )
  }
  
  export default FlagsIcon
