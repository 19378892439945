import { getOrders } from "@api/Orders"
import AdvancedFilters from "@components/AdvancedFilters/AdvancedFilters"
import CSInput from "@components/Form/CSInput"
import CSDiv from "@components/HTMLElements/CSDiv"
import CSSpan from "@components/HTMLElements/CSSpan"
import { ErrorBoxSearch } from "@components/InfoBoxSearch/InfoBoxSearch"
import OrdersList from "@components/OrdersList/OrdersList"
import OrdersListItem from "@components/OrdersList/OrdersListItem"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import { Customer, ListMetadata, Order } from "cs.node.utils/csapi/provisioning"
import useDebounce from "@hooks/UseDebounce"
import Localization from "@localization/Index"
import { FiltersContext, FiltersStatus } from "@providers/FiltersProvider"
import { CurrentFilters, FilterType, FilterTypeDefinition, getFilterShops, searchSkuOptions } from "@utils/AdvancedFiltersUtils"
import { orderStatuses } from "@utils/OrderUtils"
import { PageStates } from "@utils/PageStateUtils"
import { addDays } from "date-fns"
import { useContext, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useNavigate } from "react-router-dom"
import { FormErrors } from "cs.node.utils/FormUtils"
import CSSwitch from "@components/Form/CSSwitch"

type Props = {
    customer: Customer
    isEditable: boolean
    formErrors: FormErrors
    pageState: PageStates
    onChange: (updatedCustomer: Customer)=>unknown
}

let abortController = new AbortController()

const availableFilters:FilterTypeDefinition[] = [
    {
        id: "shops",
        type: FilterType.SELECT_MULTI,
        label: Localization.ADVANCED_FILTERS.SHOP,
        getFilterOptions: getFilterShops,
        renderProps: {
            maxSelections: 10,
            client: true
        }
    },
    {
        id: "sku",
        type: FilterType.SELECT_MULTI,
        label: Localization.ADVANCED_FILTERS.LABELS.ARTICOLI,
        renderProps: {
            server: true,
            maxSelections: 10,
            loadOptions: (term: string) =>{
                abortController.abort()
                abortController = new AbortController()
                return searchSkuOptions(abortController, term, "SKU")
            }
        }
    },
    {
        id: "date",
        type: FilterType.DATEPICKER,
        label: Localization.ADVANCED_FILTERS.DATA,
        defaultValue: [addDays(new Date(), -7), new Date()],
    },
    {
        id: "status",
        type: FilterType.SELECT_MULTI,
        label: Localization.ADVANCED_FILTERS.IN_STATO,
        getFilterOptions: async()=>{
            return [
                ...orderStatuses.map(orderStatus=>{return {value: orderStatus.status, label: orderStatus.status}})
            ]
        },
        renderProps: {
            client: true,
            maxSelections: 5
        }
    }
]

export const isOrdersChanged = (prev: Customer, curr: Customer) =>{
        return prev.features?.pending_orders_check_config?.tolerance_hours !== curr.features?.pending_orders_check_config?.tolerance_hours
}

export const isOrdersError = (formErrors: FormErrors) =>{
    return ["tolerance"].find(key=>Object.keys(formErrors).includes(key))
}

const CustomerOrders = (props: Props)=>{
    const { customer, isEditable, formErrors, pageState, onChange } = props
    const [componentState, setComponentState] = useState(PageStates.DATA_LOADING)
    const [searchTerm,setSearchTerm] = useState<string>("")
    const [orders, setOrders] = useState<Order[]>([])
    const [ordersMetadata, setOrdersMetadata] = useState<ListMetadata>()
    const searchTermDebounced = useDebounce<string>(searchTerm, 1000)
    const filtersContext = useContext(FiltersContext)
    const { setOpen, open, currentFilters, filtersStatus } = filtersContext
    const navigate = useNavigate()


    const applyFilters = async ()=>{
        if(filtersStatus === FiltersStatus.READY) {
            try {
                const currentFiltersWithCustomer: CurrentFilters = structuredClone(currentFilters ?? {})
                currentFiltersWithCustomer.push({
                    id: "customer",
                    value: {value: customer._id?.$oid!, label: ""}
                })
                const _orders = await getOrders(searchTerm, currentFiltersWithCustomer)
                setOrders(_orders.content ?? [])
                setOrdersMetadata(_orders._metadata)
                setComponentState(PageStates.IDLE)
            } catch(err) {
                console.log(err)
                setOrders([])
                setOrdersMetadata(undefined)
                setComponentState(PageStates.DATA_LOADING_ERROR)
            }
        }
    }

    

    useEffect(() => {
        if(filtersStatus === FiltersStatus.READY) {
            setComponentState(PageStates.DATA_LOADING)
            applyFilters()
        }
    }, [searchTermDebounced])

    const { total_items=0, page_index=0, total_pages=0} = ordersMetadata ?? {}
    

    return <>
        <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.ORDINI.CONFIGURAZIONE_CONTROLLO_ORDINI_PENDING}>
            <div className="row mt-field">
                <div className="col-md-24">
                    <CSSwitch
                        className="align-middle"
                        label={Localization.PAGE_SHOP.CONTROLLO_ORDINI_PENDING.STATO_DI_ATTIVAZIONE}
                        controlId="tolerance_enabled"
                        checked={customer.features?.pending_orders_check_config?.tolerance_hours !== undefined} 
                        onChange={() => {
                            const updatedCustomer = structuredClone(customer) as Customer
                            const currentTolerance = customer.features?.pending_orders_check_config?.tolerance_hours
                            if(currentTolerance === undefined) {
                                return onChange?.({...updatedCustomer, features: {...updatedCustomer.features, pending_orders_check_config: {...updatedCustomer.features?.pending_orders_check_config, tolerance_hours: 1}}})
                            } else {
                                return onChange?.({...updatedCustomer, features: {...updatedCustomer.features, pending_orders_check_config: undefined}})
                            }
                        }}
                        disabled={!isEditable}
                    />
                </div>
            </div>
            <div className="row mt-field">
                <div className="col-md-24">
                    <CSInput
                        controlId="tolerance"
                        type="number"
                        label={`${Localization.PAGE_SHOP.CONTROLLO_ORDINI_PENDING.TOLLERANZA} (1 - 200)`}
                        min={1}
                        max={200}
                        width="100px"
                        value={customer.features?.pending_orders_check_config?.tolerance_hours ?? ""}
                        disabled={!isEditable || customer.features?.pending_orders_check_config?.tolerance_hours === undefined}
                        onBlur={e=>{
                            const rawValue = e.target.value
                            let value = parseInt(rawValue)
                            if(isNaN(value)) {
                                value = 1
                            }
                            if(value < 1) {
                                value = 1
                            }

                            if(value > 200) {
                                value = 200
                            }

                            const updatedCustomer = structuredClone(customer) as Customer
                            return onChange?.({...updatedCustomer, features: {...updatedCustomer.features, pending_orders_check_config: {...updatedCustomer.features?.pending_orders_check_config, tolerance_hours: value}}})
                        }}
                        onChange={(e)=>{
                            const updatedCustomer = structuredClone(customer) as Customer
                            return onChange?.({...updatedCustomer, features: {...updatedCustomer.features, pending_orders_check_config: {...updatedCustomer.features?.pending_orders_check_config, tolerance_hours: e.target.value}}})
                        }}
                    />
                </div>
            </div>
        </SectionGroup>
        <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.ORDINI.TITLE} toolbar={<div className="d-flex align-items-center">
                <CSInput controlId="orders_search" type="text" icon="bi-search" disabled={filtersStatus !== FiltersStatus.READY} placeholder={Localization.CERCA} value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                <CSSpan role="button" className="ms-2 d-flex align-items-center" onClick={()=>{setOpen(prev=>!prev)}} tooltip={Localization.ADVANCED_FILTERS.FILTRO_AVANZATO}><i className={`bi-sliders font-2x text-${open?'primary':'muted'}`} /></CSSpan>
            </div>}>
            <AdvancedFilters 
                name="customer_orders"
                availableFilters={availableFilters}
                onApply={()=>{
                    setComponentState(PageStates.DATA_LOADING)
                    return applyFilters()
                }}
            ></AdvancedFilters>
            {componentState === PageStates.DATA_LOADING_ERROR && <ErrorBoxSearch />}
            {componentState === PageStates.DATA_LOADING && <SectionGroup><OrdersList loading /></SectionGroup>} 
            {componentState === PageStates.IDLE && 
            <SectionGroup title={Localization.RISULTATI.replace("__COUNT__", total_items.toString())}>
                <CSDiv id="CustomerOrdersContainer" className="overflow-auto" fillVertical offsetVertical={100}>
                    <InfiniteScroll
                        scrollableTarget="CustomerOrdersContainer"
                        dataLength={orders.length}
                        hasMore={page_index+1<total_pages}
                        next={async ()=>{
                            try {
                                const _orders = await getOrders(searchTerm, currentFilters!, page_index+1)
                                setOrdersMetadata(_orders._metadata)
                                setOrders(prev=>{
                                    const addedOrders = _orders.content ?? []
                                    return [...prev, ...addedOrders]
                                })
                            } catch(err) {
                                setOrdersMetadata(undefined)
                                setOrders([])
                                setComponentState(PageStates.DATA_LOADING_ERROR)
                            }
                        }}
                        loader={<OrdersListItem loading />}
                    >
                        <OrdersList orders={orders} onSelectItem={(id:string)=>{
                            return navigate(`/orders/${id}`)
                        }} onContextMenu={(_:React.MouseEvent, __:string)=>{
                            
                        }}/> 
                    </InfiniteScroll>
                </CSDiv>
            </SectionGroup>
            }
        </SectionGroup>
        </>
}

export default CustomerOrders