import CSSpan from "@components/HTMLElements/CSSpan"
import Localization from "@localization/Index"
import { useState } from "react"

type Props = {
    name: string,
    url: string,
    canDelete?: boolean,
    onDelete?: Function,
    onSelectForPrint: (qty:number) => any
}

const OrderDocument = (props: Props) =>{
    const { name, url, onDelete, canDelete=false, onSelectForPrint } = props 

    const [countSelected, setCountSelected] = useState(0)

    const changeQuantity = (quantity:number) =>{
        const qty = Math.max(0, countSelected+quantity)
        onSelectForPrint(qty)
        setCountSelected(qty)
        return
    }

    return <div className="d-flex justify-content-start align-items-center mt-3">
        {canDelete && <CSSpan role="button" className="ms-2 d-flex align-items-center" onClick={()=>{onDelete?.()}} tooltip={Localization.PAGE_ORDER.ELIMINA_DOCUMENTO}>
            <i className="bi-trash font-1-5x text-danger" />
        </CSSpan>}
        <CSSpan badgecount={countSelected} role="button" className="ms-2 d-flex align-items-center" onContextMenu={(e)=>{e.preventDefault(); changeQuantity(-1)}} onClick={()=>{changeQuantity(1)}} tooltip={Localization.PAGE_ORDER.SELEZIONA_PER_LA_STAMPA}>
            <div className="d-flex align-items-start justify-content-center">
                <i className="bi-printer font-1-5x text-success" />
            </div>
        </CSSpan>
        <a href={url}  target='_blank' rel='noopener noreferrer' className="cs-link ms-3">{name}</a>
    </div>
}

export default OrderDocument