import { getEnvironment, getSession } from "@api/Auth"
import { UserData } from "@interfaces/User"
import React from "react"

interface AuthContextType {
    userData: UserData
    environment: string | undefined
    setUserData: ()=>Promise<void> 
    setEnvironment: ()=>Promise<void>
}

const AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
    const [userData, _setUserData] = React.useState<any>(null);
    const [environment, _setEnvironment] = React.useState<string|undefined>(undefined);

    const setUserData = async()=>{
        try {
            const response = await getSession()
            console.log("authProvider->setUserData", response)
            _setUserData(response)
            return
        } catch(err) {
            _setUserData(undefined)
        }
    }

    const setEnvironment = async()=>{
        const response = await getEnvironment()
        _setEnvironment(response)
        return
    }

    const value = { userData, setUserData, environment, setEnvironment }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export {AuthProvider, AuthContext}