import { Order, Shop, Shipment } from "cs.node.utils/csapi/provisioning"
import Localization from "@localization/Index"
import ShopIcon from "@components/ShopIcon/ShopIcon"
import { getOrderDocumentProperties, getOrderStatus, OrderDocumentTypes } from "@utils/OrderUtils"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import ListItem from "@components/ListItem/ListItem"
import ListItemElement from "@components/ListItem/ListItemElement"
import { LinkContainer } from "react-router-bootstrap"
import { Modal, Spinner } from "react-bootstrap"
import { useEffect, useState } from "react"
import { PageStates } from "@utils/PageStateUtils"
import { getShipmentsByOrderTypeAndId } from "@api/Shipments"
import ShipmentPreview from "@components/ShipmentsList/ShipmentPreview"
import { closeWaiting, showError, showWaiting } from "@utils/NotificationsUtils"
import { printMergedDocuments } from "@api/Orders"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import OrderDocument from "@components/OrderDocument/OrderDocument"


type OrdersListItemsProps = {
    loading?: boolean,
    order?: Order,
    shop?: Shop,
    onClick?: Function,
    onContextMenu?: Function
}

const OrdersListItem = (props: OrdersListItemsProps) =>{
    const { order, loading=false, shop, onClick, onContextMenu } = props
    const [componentState, setComponentState] = useState<PageStates>(PageStates.INIT)
    const [shipments, setShipments] = useState<Shipment[]>()
    const [selectedShipmentId, setSelectedShipmentId] = useState<string>()
    const [selectedDocuments, setSelectedDocuments] = useState<{url:string, qty:number}[]>([])
    const [openPreview, setOpenPreview] = useState(false)
    const [openPrintModal, setOpenPrintModal] = useState(false)

    const init = async()=>{
        if(order?.order_status !== "SHIPPED") {
            return setComponentState(PageStates.IDLE)
        }
        const response = await getShipmentsByOrderTypeAndId("SALES_ORDER", order._id?.$oid!, 0, 100, undefined)
        setShipments(response.content ?? [])
        return setComponentState(PageStates.IDLE)
    }

    const printSelectedDocuments = async() =>{
        if(!order || selectedDocuments?.length === 0) return false

        try {
            setOpenPrintModal(false)
            showWaiting(Localization.ATTENDERE)
            const response = await printMergedDocuments(selectedDocuments)

            // create file link in browser's memory
            const href = URL.createObjectURL(response.data)

            // create "a" HTML element with href to file & click
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', `order_${order?.shop_order_id}_documents.pdf`)
            document.body.appendChild(link)
            link.click()

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
            closeWaiting()
        } catch(e) {
            closeWaiting()
            console.error(e)
            showError(Localization.ERRORE_GENERICO)
        }

    }

    const setSelectedForPrint = (url: string, qty: number) =>{
        setSelectedDocuments(prev=>{
            if(qty <=0) {
                return prev.filter(d=>d.url !== url)
            }
            const document = prev.find(d=>d.url === url)
            if(document) {
                document.qty = qty
                return prev
            }

            return [...prev, {url, qty}] 

        })
    }

    useEffect(()=>{
        init()
    }, [order?._id?.$oid])

    const status = getOrderStatus(order?.order_status)
    const statusLabel = order?.order_status?`${order.order_status.substring(0, 1).toUpperCase()}${order.order_status.substring(1).toLowerCase()}`:"N.D."
    const orderDate = (!!order?.order_dt?.$date)?format(parseISO(order?.order_dt?.$date), 'dd/MM/yyyy'):"N.D."
    const orderTime = (!!order?.order_dt?.$date)?format(parseISO(order?.order_dt?.$date), 'HH:mm'):undefined
    const documentNumbers = Object.keys(order?.documents ?? {}).reduce((prev:number, curr: string)=>{
        const currentDocumentTypeLength = order?.documents?.[curr]?.length ?? 0
        const updated = prev + currentDocumentTypeLength
        return updated
    }, 0)

    const hasBecommerceShipment = !!order?.additional_info?.shipping_method
    const becommerceShipmentName = order?.additional_info?.shipping_method as string | undefined
    const hasNormalShipments = (shipments !== undefined && shipments.length > 0)
    const hasShipments =  hasNormalShipments || hasBecommerceShipment

    const selectedShipment = shipments?.find(shipment=>shipment._id?.$oid === selectedShipmentId)

    return <>
        <ListItem skeleton={loading} onClick={()=>{onClick?.()}} onContextMenu={(e)=>{return onContextMenu?.(e,order?._id?.$oid)}}>
                    <ListItemElement colspan={7} label={shop?.name ?? ""} icon={
                        <span className="d-flex align-items-center justify-content-center bg-secondary p-2" style={{borderRadius: '50%', width: '50px', height: '50px'}}>
                            <ShopIcon id={shop?.cs_shop_id?.$oid ?? ""} size={34} />
                        </span>
                    } value={<span className="fw-bold">{order?.shop_order_id ?? ""}</span>} /> 
                    <ListItemElement colspan={3} value={
                        <span className={`badge fw-600 bg-${status.color}-light text-${status.color}`}>{statusLabel}</span>
                    } />
                    <ListItemElement colspan={3} label={orderTime} value={orderDate} />
                    <ListItemElement colspan={2} icon={`bi-boxes text-muted me-3`} value={order?.tot_qty?.toString() ?? "N.D."} />
                    <ListItemElement colspan={3} position="end" value={`${order?.tot_amount?.amount?.toFixed(2) ?? "N.D."} ${order?.tot_amount?.currency ?? ""}`} />
                    <ListItemElement colspan={6} position="end" value={<div className="d-flex flex-column align-items-end justify-content-start me-2">
                        <div className={`d-flex align-items-center justify-content-end mb-1 ${documentNumbers > 0?"cs-link":""}`} onClick={(e)=>{
                            e.stopPropagation()
                            setSelectedDocuments([])
                            if(documentNumbers > 0) {
                                setOpenPrintModal(true)
                            }
                        }}>
                            <span className={`${documentNumbers>0?"fw-bold":"fw-normal text-muted"} me-2`}>{documentNumbers>0?`${documentNumbers} ${Localization.PAGE_ORDER.DOCUMENTI}`: Localization.PAGE_ORDERS.NESSUN_DOCUMENTO_PRESENTE}</span>
                            <i className={`bi-file-earmark-arrow-down font-1-5x`} />
                        </div>
                        
                        
                        {order?.buyer_identifier && <LinkContainer to={`/customers/${order.buyer_identifier}`} style={{textDecoration: 'none'}} onClick={(e)=>{
                                e.stopPropagation()
                            }}>
                                <a href={`/customers/${order.buyer_identifier}`}>
                                    <div className="d-flex align-items-center justify-content-end mb-1 cs-link">
                                        <span className="fw-bold me-2">{order.buyer_name ?? "N.D."}</span>
                                        <i className={`bi-person-circle font-1-5x`} />
                                    </div>
                                </a>
                        </LinkContainer>}
                        {!order?.buyer_identifier && <>
                            <div className="d-flex align-items-center justify-content-end mb-1">
                                <span className="fw-normal text-muted me-2">{Localization.PAGE_ORDERS.NESSUN_CUSTOMER_PRESENTE}</span>
                                <i className={`bi-person-circle font-1-5x`} />
                            </div>
                        </>}

                        {componentState === PageStates.INIT && <>
                            <div className="d-flex align-items-center justify-content-end mb-1">
                                <Spinner animation="border" variant="primary" style={{width: '10px', height: '10px'}} className="me-2" />
                                <i className={`bi-truck font-1-5x`} />
                            </div>
                        </>}
                        {componentState === PageStates.IDLE && <>
                                {!hasShipments && <>
                                    <div className="d-flex align-items-center justify-content-end mb-1">
                                        <span className="fw-normal text-muted me-2">{Localization.PAGE_ORDERS.NESSUNA_SPEDIZIONE_PRESENTE}</span>
                                        <i className={`bi-truck font-1-5x`} />
                                    </div>
                                </>
                                }
                                {hasBecommerceShipment && !hasNormalShipments && <>
                                    <div className="d-flex align-items-center justify-content-end mb-1">
                                        <span className="fw-bold me-2">{becommerceShipmentName}</span>
                                        <i className={`bi-truck font-1-5x`} />
                                    </div>
                                </>}
                                {!hasBecommerceShipment && hasNormalShipments && shipments?.map((shipment,index)=>{
                                    return <div key={`order_list_shipment_${index}`} className="d-flex align-items-center justify-content-end mb-1 cs-link" onClick={(e)=>{
                                        e.stopPropagation()
                                        setSelectedShipmentId(shipment._id?.$oid)
                                        setOpenPreview(true)
                                    }}>
                                            <span className="fw-bold me-2">{`${shipment.carrier ?? "N.D."}${shipment.tracking_number?` (${shipment.tracking_number})`:""}`}</span>
                                            <i className={`bi-truck font-1-5x`} />
                                    </div>
                                })}
                        </>}
                    </div>} />
                </ListItem>
                {selectedShipment && openPreview && <ShipmentPreview shipment={selectedShipment} onClose={()=>{ setOpenPreview(false)} } />}
                {openPrintModal && order && documentNumbers > 0 &&
                    <Modal size="lg" show={openPrintModal} onHide={()=>setOpenPrintModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{Localization.PAGE_ORDER.DOCUMENTI}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        {Object.keys(order.documents ?? {}).map((orderDocumentType:string)=>{
                            const orderDocumentTypeProperties = getOrderDocumentProperties(orderDocumentType as OrderDocumentTypes)
                            const { id, text, icon } = orderDocumentTypeProperties
                            return <SectionGroup key={`section_${id}`} title={text}>
                                        {(order.documents ?? {})[orderDocumentType as OrderDocumentTypes]?.map((url:string, index:number)=>{
                                            return <OrderDocument name={`${text} n°${index+1}`} key={`order_list_document_${text}_${index}`} onSelectForPrint={(qty:number)=>{setSelectedForPrint(url, qty)}} url={url} canDelete={false} />
                                        })}
                                    </SectionGroup>
                        })}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <button type="button" onClick={()=>{setOpenPrintModal(false)}} className='m-1 mx-2 btn btn-light'>{Localization.ANNULLA}</button>
                                <button type="button" onClick={()=>{printSelectedDocuments()}} className='m-1 mx-2 btn btn-primary' disabled={selectedDocuments.length === 0}>{Localization.PAGE_ORDER.STAMPA_DOCUMENTI_SELEZIONATI}</button>
                            </div>
                        </Modal.Footer>
                    </Modal>

                }
    </>
}

export default OrdersListItem