import { getNetSuiteInfo } from "@api/Modules"
import PageHeader from "@components/Page/PageHeader"
import { EntitySingleResponseModuleResponseModuleNetSuite, Customer, ModuleIdEnum, EntitySingleResponseModuleNetSuite } from "cs.node.utils/csapi/provisioning"
import Localization from "@localization/Index"
import { AuthContext } from "@providers/AuthProvider"
import { PageStates } from "@utils/PageStateUtils"
import { useContext, useEffect, useState } from "react"
import { Container, Spinner, Tab, Tabs } from "react-bootstrap"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { getCustomer, updateCustomer } from "@api/Customers"
import CustomerHeader from "./CustomerHeader"
import CustomerGeneralInfo, { isGeneralInfoChanged, isGeneralInfoError } from "./CustomerGeneralInfo"
import { checkForm, FormErrors } from "@utils/FormUtils"
import CustomerBillingInfo, { isBillingInfoChanged, isBillingInfoError } from "./CustomerBillingInfo"
import CustomerShippingInfos, { areShippingInfosChanged, isShippingInfoError } from "./CustomerShippingInfos"
import CustomerBusinessConditions, { isBusinessConditionsChanged, isBusinessConditionsError } from "./CustomerBusinessConditions"
import { FiltersProvider } from "@providers/FiltersProvider"
import CustomerOrders, { isOrdersChanged, isOrdersError } from "./CustomerOrders"
import CustomerBeCommerce, { isBeCommerceChanged, isbeCommerceError } from "./CustomerBeCommerce"
import CustomerAccount from "./CustomerAccount"
import { showError, showSuccess, showWaiting } from "@utils/NotificationsUtils"

const breadcrumbs = [
    { text: Localization.MENU.IL_TUO_CLOUDSTORE, link: '/' },
    { text: Localization.MENU.CUSTOMERS, link: '/Customers' },
    { text: Localization.PAGE_CUSTOMERS.CUSTOMER_DETAILS },   
]

const CustomerPage = ()=>{
    const { id } = useParams()
    const { search } = useLocation()
    const [pageState, setPageState] = useState<PageStates>(PageStates.INIT)
    const [netsuiteInfo, setNetsuiteInfo] = useState<EntitySingleResponseModuleNetSuite>()
    const [customer, setCustomer] = useState<Customer>()
    const [editCustomer, setEditCustomer] = useState<Customer>()
    const [currentTab, setCurrentTab] = useState<string>(new URLSearchParams(search).get("t") ?? "0")
    const [formErrors, setFormErrors] = useState<FormErrors>({})
    
    const auth = useContext(AuthContext)
    const isNetSuiteEnabled = auth.userData?.modules?.find(module=>module._id === ModuleIdEnum.NetSuite && module.enabled)
    const isBecommerceEnabled = auth.userData?.modules?.find(module=>module._id === ModuleIdEnum.BeCommerce && module.enabled)
    const isInboundCustomersEnabled = netsuiteInfo?.content?.processes?.customer_inbound?.enabled?? false
    const navigate = useNavigate()

    const init = async()=>{
        setPageState(PageStates.INIT)
        if(!id) {
            // customer not found, set idle, we will show not found alert
            return setPageState(PageStates.IDLE)
        
        }
        try{
            // get the customer
            const customerResponse = await getCustomer(id)
            if(!customerResponse?.content) {
                // customer not found, set idle, we will show not found alert
                return setPageState(PageStates.IDLE)
            }
    
            setCustomer(customerResponse.content)
            setEditCustomer(structuredClone(customerResponse.content))
    
            // get netsuite / IC info
            if(isNetSuiteEnabled) {
                const _netsuiteInfo = await getNetSuiteInfo()
                setNetsuiteInfo(_netsuiteInfo)
            }
    
            setPageState(PageStates.IDLE)
        } catch(err) {
            console.error(err)
            setPageState(PageStates.INIT_ERROR)
        }
    }

    const saveCustomer = async ()=> {
        setPageState(PageStates.IDLE)
        setFormErrors({})

        if(!id || !editCustomer) {
            // guard check
            return false
        }
        const { first_name, last_name, company_name, default_lang, contacts={}, billing_info={} } = editCustomer
        const { email, mobile } = contacts
        const { address={} } = billing_info
        const { country_code, address_lines, city, postal_code, province, province_code } = address

        if(isEditable) { // becommerce field don't have to be checked as they are not required
            const checkAliasFunction = ()=>{
                if(company_name || (first_name && last_name)) {
                    return undefined
                }
        
                return Localization.PAGE_CUSTOMERS.ERRORE_NOMINATIVO
            }

            const response = checkForm([
                {id: "first_name", value: first_name?.trim(), customCheckFunction: checkAliasFunction},
                {id: "last_name", value: last_name?.trim(), customCheckFunction: checkAliasFunction},
                {id: "company_name", value: company_name?.trim(), customCheckFunction: checkAliasFunction},
                {id: "default_lang", value: default_lang?.trim(), required: true},
                {id: "email", value: email?.trim(), required: true, regex: "^[\\w.-]+@[\\w.-]+\\.[A-Za-z]{2,}$"},
                {id: "mobile", value: mobile?.trim(), required: true, boundaries: {min: 0, max: 16}, regex: {expression: "^\\+[1-9]\\d{1,14}$", message: Localization.PAGE_CUSTOMERS.ERRORE_TELEFONO}},
                {id: "country_code", value: country_code?.trim(), required: true},
                {id: "address_1", value: address_lines?.[0]?.trim(), required: true},
                {id: "city", value: city?.trim(), required: true},
                {id: "postal_code", value: postal_code?.trim(), required: true},
                {id: "province", value: province?.trim(), required: true},
                {id: "province_code", value: province_code?.trim(), required: true},

            ])
            
            if(Object.keys(response).length>0) {
                setFormErrors(response)
                return setPageState(PageStates.FORM_ERROR)
            }
        }

        // call update customer
        showWaiting(Localization.ATTENDERE)

        try{
            await updateCustomer(id, editCustomer)
            showSuccess(Localization.OPERAZIONE_AVVENUTA_CON_SUCCESSO)
            return init()
        } catch(err) {
            console.log(err)
            showError(Localization.ERRORE_GENERICO, err)
        }
    }

    useEffect(()=>{
        init()
    },[id])

    const pageReady = [PageStates.IDLE, PageStates.FORM_ERROR].includes(pageState)

    const isEditable = !isInboundCustomersEnabled
    const generalInfoChanged = (pageReady && !!customer && !!editCustomer && isGeneralInfoChanged(customer, editCustomer))
    const billingInfoChanged = (pageReady && !!customer && !!editCustomer && isBillingInfoChanged(customer.billing_info ?? {}, editCustomer.billing_info ?? {}))
    const shippingInfosChanged = (pageReady && !!customer && !!editCustomer && areShippingInfosChanged(customer.shipping_infos ?? [], editCustomer.shipping_infos ?? []))
    const businessConditionsChanged = (pageReady && !!customer && !!editCustomer && isBusinessConditionsChanged(customer, editCustomer))
    const becommerceChanged = (pageReady && !!customer && !!editCustomer && isBecommerceEnabled && isBeCommerceChanged(customer, editCustomer))
    const ordersChanged = (pageReady && !!customer && !!editCustomer && isOrdersChanged(customer, editCustomer))

    const isCustomerChanged = generalInfoChanged || billingInfoChanged || shippingInfosChanged || businessConditionsChanged || becommerceChanged || ordersChanged

    return <>
        <PageHeader title={`${Localization.PAGE_CUSTOMERS.CUSTOMER_DETAILS}`} breadcrumbs={breadcrumbs} side={
            <div className="d-flex align-items-center">
                <button type="button" className="btn btn-secondary ms-2" onClick={()=>navigate("/customers")}>{Localization.ANNULLA}</button>
                <button type="button" className="btn btn-primary ms-2" disabled={!isCustomerChanged || !isEditable} onClick={()=>saveCustomer()}>{Localization.SALVA}</button>
            </div>
        } />
        <Container fluid>
            {pageState === PageStates.INIT && 
            <div className="d-flex align-items-center justify-content-center w-100 mt-field">
                <Spinner variant="primary" style={{width: '3rem', height: '3rem'}} animation="border" />
            </div>}
            {pageState === PageStates.IDLE && !editCustomer &&
            <div className="d-flex align-items-center flex-column justify-content-center w-100 mt-field">
                <i className="bi-exclamation-triangle text-warning font-5x mb-3"></i>
                <div className="fs-5 fw-bold">{Localization.PAGE_CUSTOMERS.NOT_FOUND}</div>
            </div>}
            {pageState === PageStates.INIT_ERROR && <div className="d-flex align-items-center flex-column justify-content-center w-100 mt-field">
                <i className="bi-x text-danger font-5x mb-3"></i>
                <div className="fs-5 fw-bold">{Localization.ERRORE_GENERICO}</div>
            </div>}
            {pageReady && customer && editCustomer && 
            <>
                <CustomerHeader customer={customer} isInboundCustomersEnabled={isInboundCustomersEnabled} />
                <Tabs
                    id="customer-tabs"
                    activeKey={currentTab}
                    onSelect={(k) => setCurrentTab(k ?? "0")}
                    className="cs-tabs mt-3"
                >
                    <Tab eventKey="0" title={<div className="d-flex align-items-center justify-content-start">
                        <span className={isGeneralInfoError(formErrors)?"text-danger":""}>{Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.TITLE}</span>
                        {generalInfoChanged && <span className={isGeneralInfoError(formErrors)?"text-danger ms-1":"ms-1"}>*</span>}
                        </div>} mountOnEnter>
                        <CustomerGeneralInfo customer={editCustomer} isEditable={isEditable} onChange={(updatedCustomer)=>{setEditCustomer(updatedCustomer)}} pageState={pageState} formErrors={formErrors} />
                    </Tab>
                    <Tab eventKey="1" title={<div className="d-flex align-items-center justify-content-start">
                        <span className={isBillingInfoError(formErrors)?"text-danger":""}>{Localization.PAGE_CUSTOMERS.TABS.FATTURAZIONE.TITLE}</span>
                        {billingInfoChanged && <span className={isBillingInfoError(formErrors)?"text-danger ms-1":"ms-1"}>*</span>}
                        </div>} mountOnEnter>
                        <CustomerBillingInfo customer={editCustomer} isEditable={isEditable} onChange={(updatedCustomer)=>{setEditCustomer(updatedCustomer)}} pageState={pageState} formErrors={formErrors} />
                    </Tab>
                    <Tab eventKey="2" title={<div className="d-flex align-items-center justify-content-start">
                        <span className={isShippingInfoError(formErrors)?"text-danger":""}>{Localization.PAGE_CUSTOMERS.TABS.SPEDIZIONE.TITLE}</span>
                        {shippingInfosChanged && <span className={isShippingInfoError(formErrors)?"text-danger ms-1":"ms-1"}>*</span>}
                        </div>} mountOnEnter>
                        <CustomerShippingInfos customer={editCustomer} isEditable={isEditable} onChange={(updatedCustomer)=>{setEditCustomer(updatedCustomer)}} pageState={pageState} formErrors={formErrors} />
                    </Tab>
                    <Tab eventKey="3" title={<div className="d-flex align-items-center justify-content-start">
                        <span className={isBusinessConditionsError(formErrors)?"text-danger":""}>{Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.TITLE}</span>
                        {businessConditionsChanged && <span className={isBusinessConditionsError(formErrors)?"text-danger ms-1":"ms-1"}>*</span>}
                        </div>} mountOnEnter>
                        <CustomerBusinessConditions customer={editCustomer} isEditable={isEditable} onChange={(updatedCustomer)=>{setEditCustomer(updatedCustomer)}} pageState={pageState} formErrors={formErrors} />
                    </Tab>
                    <Tab eventKey="4" title={<div className="d-flex align-items-center justify-content-start">
                        <span className={isOrdersError(formErrors)?"text-danger":""}>{Localization.PAGE_CUSTOMERS.TABS.ORDINI.TITLE}</span>
                        {ordersChanged && <span className={isOrdersError(formErrors)?"text-danger ms-1":"ms-1"}>*</span>}
                        </div>} mountOnEnter>
                        <FiltersProvider key={"customer_orders"}>
                            <CustomerOrders customer={editCustomer} isEditable={isEditable} onChange={(updatedCustomer)=>{setEditCustomer(updatedCustomer)}} pageState={pageState} formErrors={formErrors} />
                        </FiltersProvider>
                    </Tab>
                    {isBecommerceEnabled &&
                    <Tab eventKey="5" title={<div className="d-flex align-items-center justify-content-start">
                        <span className={isbeCommerceError(formErrors)?"text-danger":""}>{Localization.PAGE_CUSTOMERS.TABS.BECOMMERCE.TITLE}</span>
                        {becommerceChanged && <span className={isbeCommerceError(formErrors)?"text-danger ms-1":"ms-1"}>*</span>}
                        </div>} mountOnEnter>
                        <CustomerBeCommerce customer={editCustomer} onChange={(updatedCustomer)=>{setEditCustomer(updatedCustomer)}} pageState={pageState} formErrors={formErrors} />
                    </Tab>
                    }
                    <Tab eventKey="6" title={Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.TITLE} mountOnEnter>
                        <CustomerAccount customer={editCustomer} onChange={()=>init()} />
                    </Tab>
                </Tabs>
            </>
            
            }
        </Container>
    </>
}

export default CustomerPage