import axios, { AxiosResponse } from "axios"
import { checkResponseStatus } from "@api/Utils"
import { EntityListResponsePackageType, EntityListResponseShop } from 'cs.node.utils/csapi/provisioning'
import { CloudStoreShopIdEnum } from "@utils/ShopsUtils"
import { BindingPoint } from "@pages/ShopsWarehousesBindings/ShopsWarehousesBindings"
import { UpdateWhsAndSyncResponse } from "../../../src/interfaces/Shops"

export type SubmitChannelShopResponse = {
    name: string,
    marketplace: string,
    success: boolean,
    error?: unknown
}

export const getShops = async ():Promise<EntityListResponseShop> =>{
    try {
        const response:AxiosResponse<EntityListResponseShop,any> = await axios.get('api/shops')
        return response.data
    } catch(error:any) {
        checkResponseStatus(error)
        throw error
    }
}

export const getShop = async(csShopId: CloudStoreShopIdEnum, shopId: string):Promise<unknown> =>{
    try {
        const response:AxiosResponse<unknown,any> = await axios.get(`api/shops/${csShopId}/${shopId}`)
        return response.data
    } catch(error:any) {
        checkResponseStatus(error)
        throw error
    }
}

export const getChannelShops = async(csShopId: CloudStoreShopIdEnum):Promise<unknown[]> =>{
    try {
        const response:AxiosResponse<unknown[],any> = await axios.get(`api/shops/channel/${csShopId}`)
        return response.data
    } catch(error:any) {
        checkResponseStatus(error)
        throw error
    }
}

export const getShopPackageTypes = async(id: string):Promise<EntityListResponsePackageType> =>{
    try {
        const response:AxiosResponse<EntityListResponsePackageType,any> = await axios.get(`api/shops/package-types/${id}`)
        return response.data
    } catch(error:any) {
        checkResponseStatus(error)
        throw error
    }
}



export const setShopEnabled = async(shopId: string, enabled: boolean) =>{
    try {
        const response = await axios.patch(`api/shops/${shopId}/enabled`, {enabled})
        return response.data
    } catch(error:any) {
        throw error
    }
}

export const createShop = async(shop: unknown)=>{
    try {
        const response = await axios.post(`api/shops/create`, {shop})
        return response.data
    } catch(error: unknown) {
        throw error
    }
}

export const updateShop = async(shop: unknown)=>{
    try {
        const response = await axios.put(`api/shops/update`, {shop})
        return response.data
    } catch(error: unknown) {
        throw error
    }
}

export const submitChannelShops = async(cs_shop_id: CloudStoreShopIdEnum, shops: unknown[])=>{
    try {
        const response = await axios.post(`api/shops/channel`, {cs_shop_id, shops})
        return response.data
    } catch(error: unknown) {
        throw error
    }
}

export const updateWhsAndSync = async(bindingPoints: BindingPoint[], sync: boolean) =>{
    try {
        const response:UpdateWhsAndSyncResponse = await axios.post(`api/shops/update-whs-and-sync`, {bindingPoints, sync})
        return response.data
    } catch(error: unknown) {
        throw error
    }
}

export const renewShopToken = async(shopId: string, csShopId: string, tokenId: string)=>{
    try {
        const response = await axios.post(`api/shops/${shopId}/renew-token/`, {tokenId, csShopId})
        return response.data
    } catch(error: unknown) {
        throw error
    }
}

export const getShopItemAvailableQuantity = async(shopId: string, sku: string)=>{
    try {
        const response:AxiosResponse<{quantity: number},any> = await axios.get(`api/shops/${shopId}/sku-available-quantity/${encodeURIComponent(sku)}`)
        return response.data
    } catch(error:any) {
        checkResponseStatus(error)
        throw error
    }
}