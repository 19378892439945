import { getOrderStatus } from '@utils/OrderUtils'
import styles from './css/ShipmentOrderRow.module.scss'
import { ShipmentOrder } from "@interfaces/ShipmentOrder"
import Localization from '@localization/Index'
import { getShopsFromIds } from '@utils/ShopsUtils'
import ShopIcon from '@components/ShopIcon/ShopIcon'
import styles2 from '../ShipmentsList/css/ShipmentPreview.module.scss'
import { useNavigate } from 'react-router-dom'
import { OrderOrderStatusEnum } from 'cs.node.utils/csapi/provisioning'

type Props = {
    loading?: boolean,
    item?: ShipmentOrder
}

const ShipmentOrderRow = (props:Props)=>{
    const { loading, item } = props
    const navigate = useNavigate()
    const status = getOrderStatus(item?.order_status as OrderOrderStatusEnum)
    if(loading) return (
        <div className="m-2 p-2 border d-flex flex-column loading-skeleton">
            <div className={styles.skeletonItem} style={{width:"200px"}}>&nbsp;</div>
            <div className={styles.skeletonItemSub} style={{width:"300px"}}>&nbsp;</div>
        </div>
    )

    const { id ,order_id, amount, currency ,order_status, shop_id, shop_order, tot_qty} = item!
    const shops =shop_id ? getShopsFromIds([shop_id]) : undefined;

    return (
        <div className="m-2 p-3 border d-flex">
            <div className="col-10 d-flex justify-content-start align-items-center">
                <div className="me-2"><ShopIcon  id={shops?.[0]?.cs_shop_id?.$oid ?? ""} size={35} /></div>
                <div>
                    <div>{`${Localization.PAGE_ORDERS.ORDINE}`}</div>
                    
                    <strong className={`smallest ${styles2.link}`} onClick={()=>{navigate(`/orders/${order_id}`)} } >{shop_order}</strong>
                </div>
            </div>
            <div className="col-4 d-flex justify-content-start align-items-center">
                <i className={`${status.icon} font-3x text-${status.color} me-2`} />
                <div>
                    <div>{`${Localization.PAGE_ORDERS.STATO_ORDINE}`}</div>
                    <strong className="smallest">{order_status}</strong>
                </div>
            </div>
            <div className="col-4 d-flex justify-content-start align-items-center">
                <i className={`bi-boxes font-2x me-2`} />
                <div>
                    <div>{`${Localization.PAGE_ORDERS.NUMERO_ARTICOLI}`}</div>
                    <strong className="smallest">{tot_qty}</strong>
                </div>
            </div>
            <div className="col-4 d-flex justify-content-start align-items-center">
                <i className={`bi-cash-stack font-2x me-2`} />
                <div>
                    <div>{`${Localization.PAGE_ORDERS.CONTROVALORE}`}</div>
                    <strong className="smallest">{`${amount} ${currency}`}</strong>
                </div>
            </div>  
        </div>
    )
}

export default ShipmentOrderRow