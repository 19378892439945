import CSReactSelect, { CSOption } from "@components/Form/CSReactSelect"
import CSReactTreeSelect, { TreeNode } from "@components/Form/CSReactTreeSelect"
import Localization from "@localization/Index"
import { getCategoriesForTree, getLabelsFromCategories } from "@utils/CategoriesUtils"
import { getShopsFromIds, getUserShops } from "@utils/ShopsUtils"
import { getItemsMetadata, getItems } from "@api/Items"
import CSDatePicker from '@components/Form/CSDatePicker'
import React from "react"
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import CSSelectInput, {CSSelectValue} from "@components/Form/CSSelectInput"
import CSInput from "@components/Form/CSInput"
import { getUserWarehouses } from "./WarehousesUtils"
import { endOfDay, startOfDay } from "date-fns"
import { getOrders} from "@api/Orders"
import { EntitySingleResponseLogServiceMetadata } from "cs.node.utils/csapi/provisioning"
import { getLogsMetadata } from "@api/Logs"
import { getCustomers } from "@api/Customers"
import { sort } from "./ArrayUtils"




// default max values to be rendered explicit in the filter description
enum DEFAULT_MAX_VALUES_EXPLICIT {
    OTHER = 3,
    SHOPS = 3,
    BRANDS = 3,
    CATEGORIES = 3,
    SEASONS = 4,
    SKU = 2,
    WAREHOSES = 2
}

// types of filters that can be rendered
enum FilterType {
    SELECT = "SELECT",
    SELECT_MULTI = "SELECT_MULTI",
    INPUT = "INPUT",
    DATEPICKER = "DATEPICKER",
    TREENODE = "TREENODE",
    SELECTINPUT = "SELECTINPUT",
}

// the preset filters that are shown as options in the widget
export interface PresetAdvancedFilters {
    id: string,
    label: string,
    filters?: CurrentFilters
}

// filter of type select
export type SelectFilter = {
    defaultValue?: CSOption,
    options: CSOption[],
    client?: boolean,
    server?: boolean,
}

// filter of type select multi
export type SelectMultiFilter = {
    defaultValue?: CSOption[],
    options: CSOption[],
    client?: boolean,
    server?: boolean,
}

// filter of type input
export type InputFilter = {
    defaultValue?: string,
}

// filter of type datepicker
export type DatePickerFilter = {
    allowedDateRange?: [Date | null, Date | null]
    defaultValue?: [Date | null, Date | null]
}

// filter of type treenode
export type TreeNodeFilter = {
    defaultValue?: string[],
}

// filter of type selectinput
export type SelectInputFilter = {
    value?: CSSelectValue
    defaultValue?: CSSelectValue
}

// the object that contains a key / value pair corresponding to filter id and filter options
export type FilterOptions = {id: string, type: FilterType, value:GroupFiltersValueType}

// the definition for a generic filter of a given type
export type FilterTypeDefinition = {
    id: string,
    label?: string,
    type: FilterType,
    getFilterOptions?: ()=>Promise<GroupFiltersValueType>
    getFilterTypeDescription?: (availableFilter: FilterTypeDefinition, currentFilter?:CurrentFiltersValueType)=>JSX.Element|undefined
    getIsEmpty?: (currentFilter?:CurrentFiltersValueType)=>boolean
    mandatory?: boolean
    clearable?: boolean
    renderProps?: RenderProps
} & (SelectFilter | SelectMultiFilter | InputFilter | DatePickerFilter | TreeNodeFilter | SelectInputFilter)

export type GroupFiltersValueType = CSOption | CSOption[] | TreeNode[] | [Date | null, Date | null] | CSSelectValue | string
export type CurrentFiltersValueType = CSOption | CSOption[] | string[] | [Date | null, Date | null] | CSSelectValue | string
export type CurrentFilter = {id: string, value?: CurrentFiltersValueType}
export type CurrentFilters = CurrentFilter[]

// this function returns the empty filters values for the available filters that are in the current page
const getEmptyFilters = (availableFilters?:FilterTypeDefinition[]) =>{
    const emptyFilters:CurrentFilters = []

    availableFilters?.forEach(availableFilter=>{
        const { id, type, defaultValue } = availableFilter
        switch(type) {
            case FilterType.SELECT:
                emptyFilters.push({id, value:undefined})
                break
            case FilterType.SELECT_MULTI:
                emptyFilters.push({id, value:undefined})
                break
            case FilterType.INPUT:
                emptyFilters.push({id, value:""})
                break
            case FilterType.TREENODE:
                emptyFilters.push({id, value:[]})
                break;
            case FilterType.SELECTINPUT:
                const selectedElement = (defaultValue as CSOption[])?.[0]
                emptyFilters.push({id, value:{selectValue: selectedElement?.value, formControlValue: undefined}})
                break
            case FilterType.DATEPICKER:
                emptyFilters.push({id, value:[null, null]})
                break
            default:
                break
        }
    })

    return emptyFilters
}

// this function returns the default filters values for the available filters that are in the current page
const getDefaultFilters = (availableFilters?:FilterTypeDefinition[]) =>{
    const defaultFilters:CurrentFilters = []

    availableFilters?.forEach(availableFilter=>{
        const { id, type, defaultValue } = availableFilter
        switch(type) {
            case FilterType.SELECT:
                defaultFilters.push({id, value:defaultValue ?? undefined})
                break
            case FilterType.SELECT_MULTI:
                defaultFilters.push({id, value:defaultValue ?? undefined})
                break
            case FilterType.INPUT:
                defaultFilters.push({id, value:defaultValue ?? ""})
                break
            case FilterType.TREENODE:
                defaultFilters.push({id, value:defaultValue ?? []})
                break;
            case FilterType.SELECTINPUT:
                const selectedElement = (defaultValue as CSOption[])?.[0]
                defaultFilters.push({id, value:{selectValue: selectedElement?.value, formControlValue: undefined}})
                break
            case FilterType.DATEPICKER:
                defaultFilters.push({id, value:defaultValue ?? [null, null]})
                break
            default:
                break
        }
    })

    return defaultFilters
}

// TODO: relocate in other places based on the argument of the page?
const getFilterShops = async ():Promise<GroupFiltersValueType>=>{
    const userShops = await getUserShops()

    const csOptions = userShops.map(shop=>{
        return {
            value: shop._id?.$oid!,
            label: shop.name!
        }
    })

    return sort(csOptions, "label", "ASC") as GroupFiltersValueType
}

const getFilterCategories = async(currentLanguage:string):Promise<GroupFiltersValueType>=>{
    const categories = await getCategoriesForTree(currentLanguage)
    return categories
}

const getFilterOrderTypes = async():Promise<GroupFiltersValueType>=>{
    const orderTypes:CSOption[] = [
        { value: 'SALES_ORDER', label: Localization.SALES_ORDER },
        { value: 'TRANSFER_ORDER', label: Localization.TRANSFER_ORDER }
    ]
    return orderTypes
}

const getFilterCustomers = async(term:string) =>{
    if(term && term.length >= 3){
        const customersResponse  = await getCustomers({searchTerm:term})
        const customers = (customersResponse.content ??[])
        const customerOptions : CSOption[] = customers.map(customer =>{
            return{
                value: customer?._id?.$oid??'',
                label: `${customer?.first_name} ${customer?.last_name} ( ${customer?.security?.username ??'n.d.'})`
                }
        })
        return customerOptions
    }
    return []
}

const getFilterBrands = async():Promise<GroupFiltersValueType>=>{
    const brandsResponse = await getItemsMetadata("brand")
    const brands = (brandsResponse.content ?? []) as unknown[] as string[]
    return brands.map(brand=>{
        return {
            value: brand,
            label: brand
        }
    })
}

const getFilterSeasons = async():Promise<GroupFiltersValueType>=>{
    const seasonsResponse = await getItemsMetadata("season")
    const seasons = (seasonsResponse.content ?? []) as unknown[] as string[]
    return seasons.map(season=>{
        return {
            value: season,
            label: season
        }
    })
}

const getFilterQuantity = async():Promise<GroupFiltersValueType>=>{
    return [
        {value: "TUTTI", label: Localization.TUTTI},
        {value: "SI", label: Localization.SI},
        {value: "NO", label: Localization.NO},
    ]
}

const getFilterWarehouses = async ():Promise<GroupFiltersValueType>=>{
    const warehouses = await getUserWarehouses()
    return warehouses.map(wh=>{
        return {
            value: wh._id?.$oid!,
            label: wh.name!
        }
    })
}

const searchSkuOptions = async(abortController: AbortController, searchTerm:string, valueType: "ID" | "SKU" = "ID")=> {
    const response = await getItems(abortController, 0,250, "last_info_update[DESC]", searchTerm)
    const results:CSOption[] = response.content?.map(item=>{return {value: valueType === "ID"?item.item_id?.$oid!: item.sku!, label: item.sku!}}) ?? []
    return results
}

const searchOrdesByShopOrdId = async(term:string)=>{
    const response = await getOrders(term, [],0,20)
    let results :CSOption[] = []
    if(response?.content)
        results = response.content?.map(item=>{return {value: item?._id?.$oid??'', label: item?._id?.$oid??''}})
    return results
}

const getLogsComponent = async (): Promise<CSOption[]> => {

    const metadata: EntitySingleResponseLogServiceMetadata | undefined = await getLogsMetadata()

    if (metadata?.content?.components) {
        const componentOptions: Set<string> = metadata?.content?.components
        const componentOptionsArray: GroupFiltersValueType = Array.from(componentOptions).map(item => ({
            value: item,
            label: item
        }))
        return componentOptionsArray
    } else { return [] }
}

const getLogsGroups = async (): Promise<CSOption[]> => {
    const metadata: EntitySingleResponseLogServiceMetadata | undefined = await getLogsMetadata();

    if (metadata?.content?.groups) {
        const groups = metadata.content.groups;
        const groupOptionsArray: CSOption[] = []

        Object.keys(groups).forEach(component => {
            groups[component].forEach(group => {
                groupOptionsArray.push({
                    value: group,
                    label: group, 
                })
            })
        })
        return groupOptionsArray;
    } else {
        return []
    }
};

// filter of type select
export type SelectRender = {
    value?: CSOption
    defaultValue?: CSOption,
    client?: boolean,
    server?: boolean,
    onChange?: (options:CurrentFiltersValueType)=>unknown
    loadOptions?: Function
}

// filter of type select multi
export type SelectMultiRender = {
    value?: CSOption[]
    defaultValue?: CSOption[],
    maxSelections?: number,
    client?: boolean,
    server?: boolean,
    onChange?: (options:CurrentFiltersValueType)=>unknown
    loadOptions?: Function
}

// filter of type input
export type InputRender = {
    value?: string,
    defaultValue?: string
    inputType?: string
    onChange?: (options:CurrentFiltersValueType)=>unknown
}

// filter of type datepicker
export type DatePickerRender = {
    allowedDateRange?: [Date | null, Date | null],
    value?: [Date | null, Date | null]
    defaultValue?: [Date | null, Date | null]
    onChange?: (options:CurrentFiltersValueType)=>unknown
}

// filter of type treenode
export type TreeNodeRender = {
    textSelected: string,
    textUnselected: string,
    defaultValue?: string[]
    value?: string[]
    onChange?: (options:CurrentFiltersValueType)=>unknown
}

// filter of type selectinput
export type SelectInputRender = {
    value?: CSSelectValue
    defaultValue?: CSSelectValue
    onChange?: (options:CurrentFiltersValueType)=>unknown
}
// the props needed to render the actual filter
export type RenderProps =  SelectRender | SelectMultiRender | InputRender | DatePickerRender | TreeNodeRender | SelectInputRender
export type RenderFilterProps = {id: string, type: FilterType, label: string, mandatory?: boolean, clearable?: boolean, options: GroupFiltersValueType, onChange: (options:CurrentFiltersValueType)=>unknown} & RenderProps

// this function renders the filter based on the filter type and the props defined in the page, or defaults if present
const RenderFilter = (props: RenderFilterProps) =>{
    const { id, type, label, options=[], mandatory=false, clearable=true, onChange } = props
    switch(type) {
        case FilterType.SELECT:
            const { client, value, defaultValue, loadOptions } = props as SelectRender
            if(client) {
                return <CSReactSelect
                    controlId={id}
                    label={label}
                    client
                    value={value}
                    defaultValue={defaultValue}
                    options={options as CSOption[]}
                    onChange={onChange}
                    mandatory={mandatory}
                    isClearable={clearable}
                />
            }
            return <CSReactSelect
                        controlId={id}
                        label={label}
                        server
                        value={value}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        loadOptions={loadOptions ?? function(){}}
                        mandatory={mandatory}
                        isClearable={clearable}
                    />
        case FilterType.SELECT_MULTI:
            const { client:mclient, maxSelections, value:mvalue, defaultValue:mdefaultValue, loadOptions:mloadOptions } = props as SelectMultiRender
            console.log({label, mvalue})
            const isOptionDisabled = ()=>(maxSelections!==undefined && mvalue!==undefined && mvalue.length >= maxSelections)
            if(mclient) {
                return <CSReactSelect
                    controlId={id}
                    label={label}
                    client
                    isOptionDisabled={isOptionDisabled}
                    value={mvalue}
                    defaultValue={mdefaultValue}
                    options={options as CSOption[]}
                    onChange={onChange}
                    multi
                    mandatory={mandatory}
                    isClearable={clearable}
                />
            }
            return <CSReactSelect
                        controlId={id}
                        label={label}
                        server
                        isOptionDisabled={isOptionDisabled}
                        value={mvalue}
                        defaultValue={mdefaultValue}
                        onChange={onChange}
                        multi
                        loadOptions={mloadOptions ?? function(){}}
                        mandatory={mandatory}
                        isClearable={clearable}
                    />
        case FilterType.INPUT:
            const { value: inputRenderValue, inputType="text"} = props as InputRender
            return <CSInput
                        className="me-3"
                        controlId={id}
                        label={label}
                        type={inputType}
                        value={inputRenderValue}
                        onChange={(e)=>{onChange?.(e.target.value)}}
                        mandatory={mandatory}
                    />
        case FilterType.DATEPICKER:
            const { value: datepickerRenderValue=[null, null], allowedDateRange=[null,null] } = props as DatePickerRender
            const startDay = datepickerRenderValue[0]!==null?startOfDay(datepickerRenderValue[0]):null
            const endDay = datepickerRenderValue[1]!==null?endOfDay(datepickerRenderValue[1]):null
            const minStartDay = allowedDateRange[0]!==null?startOfDay(allowedDateRange[0]):null
            const maxEndDay = allowedDateRange[1]!==null?endOfDay(allowedDateRange[1]):null
            return <CSDatePicker
                        controlId={id}
                        dateRange={[startDay, endDay]}
                        allowedDateRange={[minStartDay, maxEndDay]}
                        setDateRange={(range)=>{
                            const fromNormalized = range[0]!==null?startOfDay(range[0]):null
                            const toNormalized = range[1]!==null?endOfDay(range[1]):null
                            return(onChange([fromNormalized, toNormalized]))
                        }}
                        label={(label) as string}
                        mandatory={mandatory}
                        isClearable={clearable}
                    />
        case FilterType.TREENODE:
            const { value:treenodeRenderValue, textSelected: treenodeRenderTextSelected=Localization.ADVANCED_FILTERS.LABELS.ELEMENTI_SELEZIONATI, textUnselected: treenodeRenderTextUnselected=Localization.ADVANCED_FILTERS.LABELS.SELEZIONA_ELEMENTI  } = props as TreeNodeRender
            return <CSReactTreeSelect 
                        controlId={id}
                        data={options as TreeNode[]} 
                        label={label} 
                        text={(treenodeRenderValue?.length===0?treenodeRenderTextUnselected:`${treenodeRenderTextSelected}: ${treenodeRenderValue?.length}`)} 
                        checked={treenodeRenderValue}
                        onCheck={onChange}
                        mandatory={mandatory}
                    />
        case FilterType.SELECTINPUT:
            const { value: selectInputRenderValue } = props as SelectInputRender
            return <CSSelectInput 
                        className="col-sm-24 col-md-18 mt-2 my-2"
                        controlId={id}
                        label={label} 
                        selectOptions={options as CSOption[]} 
                        value={selectInputRenderValue}
                        onChange={onChange}
                        mandatory={mandatory}
                    />
        default:
            return <></>
        
    }
}

const getEmptyFiltersDescription = () => {
    return <>{Localization.ADVANCED_FILTERS.NESSUN_FILTRO_APPLICATO}</>
}

const getErrorFiltersDescription = () => {
    return <>{Localization.ADVANCED_FILTERS.ERRORE_CARICAMENTO_FILTRI}</>
}

//TODO: remove and add to the filter definition in the page
const getFiltersShopDescription = (shops:string[]):JSX.Element =>{
    const {length } = shops
    if(length === 0) return <></>
    const shopExplicit = shops.slice(0, DEFAULT_MAX_VALUES_EXPLICIT.SHOPS)
    const showAndMore = shops.length > DEFAULT_MAX_VALUES_EXPLICIT.SHOPS
    const moreCount = shops.length - DEFAULT_MAX_VALUES_EXPLICIT.SHOPS
    const shopNames = getShopsFromIds(shopExplicit).map(shop => shop.name ?? "N.D.")
    return <>
    {Localization.ADVANCED_FILTERS.SHOP} <strong>{shopNames.join(', ')}</strong>{showAndMore ? <>{Localization.ADVANCED_FILTERS.E_ALTRI} <strong>{moreCount}</strong></>:<></>}
    </>
}

const getFiltersBrandDescription = (brands:string[]):JSX.Element =>{
    const {length } = brands
    if(length === 0) return <></>
    const brandExplicit = brands.slice(0, DEFAULT_MAX_VALUES_EXPLICIT.BRANDS)
    const showAndMore = brands.length > DEFAULT_MAX_VALUES_EXPLICIT.BRANDS
    const moreCount = brands.length - DEFAULT_MAX_VALUES_EXPLICIT.BRANDS
    return <>
    {Localization.ADVANCED_FILTERS.BRAND} <strong>{brandExplicit.join(', ')}</strong>{showAndMore ? <>{Localization.ADVANCED_FILTERS.E_ALTRI} <strong>{moreCount}</strong></>:<></>}
    </>
}

const getFiltersSeasonDescription = (seasons:string[]):JSX.Element =>{
    const {length } = seasons
    if(length === 0) return <></>
    const seasonExplicit = seasons.slice(0, DEFAULT_MAX_VALUES_EXPLICIT.SEASONS)
    const showAndMore = seasons.length > DEFAULT_MAX_VALUES_EXPLICIT.SEASONS
    const moreCount = seasons.length - DEFAULT_MAX_VALUES_EXPLICIT.SEASONS
    return <>
    {Localization.ADVANCED_FILTERS.SEASON} <strong>{seasonExplicit.join(', ')}</strong>{showAndMore ? <>{Localization.ADVANCED_FILTERS.E_ALTRE} <strong>{moreCount}</strong></>:<></>}
    </>
}

const getFiltersQuantityDescription = (_:FilterTypeDefinition, quantity:CSOption) =>{
    if(!quantity) return undefined
    const { value } = quantity
    if(value==="TUTTI") return undefined
    if(value ==="SI") return <>{Localization.ADVANCED_FILTERS.QUANTITA_SI}</>
    if(value ==="NO") return <>{Localization.ADVANCED_FILTERS.QUANTITA_NO}</>
    return undefined
}

const getFiltersCategoryDescription = (_:FilterTypeDefinition, categories?: string[]) =>{
    if(!categories || categories.length===0) return undefined
    const categoriesExplicit = categories.slice(0, DEFAULT_MAX_VALUES_EXPLICIT.CATEGORIES)
    const showAndMore = categories.length > DEFAULT_MAX_VALUES_EXPLICIT.CATEGORIES
    const moreCount = categories.length - DEFAULT_MAX_VALUES_EXPLICIT.CATEGORIES

    const categoriesLabel = getLabelsFromCategories(Localization.__LANGUAGE, categoriesExplicit)
    return <>
    {Localization.ADVANCED_FILTERS.CATEGORIES} <strong>{categoriesLabel.join(', ')}</strong>{showAndMore ? <>{Localization.ADVANCED_FILTERS.E_ALTRE} <strong>{moreCount}</strong></>:<></>}
    </>
}

const getFiltersSkuDescription = (skus:string[]):JSX.Element =>{
    const {length } = skus
    if(length === 0) return <></>
    const skusExplicit = skus.slice(0, DEFAULT_MAX_VALUES_EXPLICIT.SKU)
    const showAndMore = skus.length > DEFAULT_MAX_VALUES_EXPLICIT.SKU
    const moreCount = skus.length - DEFAULT_MAX_VALUES_EXPLICIT.SKU
    return <>
    {Localization.ADVANCED_FILTERS.SKU} <strong>{skusExplicit.join(', ')}</strong>{showAndMore ? <>{Localization.ADVANCED_FILTERS.E_ALTRI} <strong>{moreCount}</strong></>:<></>}
    </>
}

const getFiltersOrderTypeDescription = (values: CSSelectValue) =>{
    const orderType = values.selectValue
    const orderID = values.formControlValue
    if (orderType === undefined ) return <></>
    return <>
            {Localization.ADVANCED_FILTERS.LABELS.ORDER_TYPE} <strong> {orderType ==='SALES_ORDER' ? Localization.SALES_ORDER : Localization.TRANSFER_ORDER} </strong>
            {orderID && orderID!== ''? <> {Localization.ADVANCED_FILTERS.LABELS.ORDER} <strong>{orderID}</strong></>: <></>}
            </>
}

const getDefaultFilterDescription = (availableFilter: FilterTypeDefinition, currentFilters?: CurrentFiltersValueType) =>{
    if(!currentFilters) return undefined
    const { id, type, label } = availableFilter
    switch(type) {
        case FilterType.SELECT:
            const filtersSelect = currentFilters as CSOption
            if(!filtersSelect) return undefined
            return <>{label ?? id}: <strong>{filtersSelect.label}</strong></>
        case FilterType.SELECT_MULTI:
            const filtersSelectMulti = currentFilters as CSOption[]
            if(filtersSelectMulti.length === 0) return undefined
            const selectMultiExplicit = filtersSelectMulti.slice(0, DEFAULT_MAX_VALUES_EXPLICIT.OTHER)
            const selectMultiShowAndMore = filtersSelectMulti.length > DEFAULT_MAX_VALUES_EXPLICIT.OTHER
            const selectMultiMoreCount = filtersSelectMulti.length - DEFAULT_MAX_VALUES_EXPLICIT.OTHER
            return <>{label ?? id}: <strong>{selectMultiExplicit.map(se=>se.label).join(', ')}</strong>{selectMultiShowAndMore ? <>{Localization.ADVANCED_FILTERS.E_ALTRI} <strong>{selectMultiMoreCount}</strong></>:<></>}</>
        case FilterType.DATEPICKER:
            const filtersDatepicker = currentFilters as [Date|null, Date|null]
            const from = filtersDatepicker[0]
            const to = filtersDatepicker[1]
            if(from===null || to===null) return undefined
            const { IL_GIORNO, DATA, DATA_A, DATA_DA } = Localization.ADVANCED_FILTERS
            const parsedFrom = format(from!, 'dd/MM/yyyy')
            if(isSameDay(from!, to!)) {
                return <>{label ?? DATA} {IL_GIORNO} <strong>{parsedFrom}</strong></>
            }
        
            const parsedTo = format(to!, 'dd/MM/yyyy')
            return <>{label ?? DATA} {DATA_DA} <strong>{parsedFrom}</strong> {DATA_A} <strong>{parsedTo}</strong></>
        case FilterType.INPUT:
            const filtersInput = currentFilters as string
            return <>{label ?? id}: <strong>{filtersInput}</strong></>
        case FilterType.TREENODE:
            const filtersTreenode = currentFilters as string[]
            if(filtersTreenode.length === 0) return undefined
            const treenodeExplicit = filtersTreenode.slice(0, DEFAULT_MAX_VALUES_EXPLICIT.OTHER)
            const treenodeShowAndMore = filtersTreenode.length > DEFAULT_MAX_VALUES_EXPLICIT.OTHER
            const treenodeMoreCount = filtersTreenode.length - DEFAULT_MAX_VALUES_EXPLICIT.OTHER
            return <>{label ?? id}: <strong>{treenodeExplicit.join(', ')}</strong>{treenodeShowAndMore ? <>{Localization.ADVANCED_FILTERS.E_ALTRI} <strong>{treenodeMoreCount}</strong></>:<></>}</>
        case FilterType.SELECTINPUT:
            const filtersSelectInput = currentFilters as CSSelectValue
            const { formControlValue, selectValue  } = filtersSelectInput
            if(!formControlValue || !selectValue) { return undefined }
            return <>{label ?? id}: <strong>{selectValue} - {formControlValue}</strong></>
        default:
            return <></>
    }
}

const renderFiltersDescription = (currentFilters:CurrentFilters|undefined, availableFilters: Array<FilterTypeDefinition>, filtersError:boolean)=>{
    if(!currentFilters) return getEmptyFiltersDescription()
    if(filtersError) return getErrorFiltersDescription()
    
    const descriptions:JSX.Element[] = []

    availableFilters.forEach(availableFilter => {
        const { id, getFilterTypeDescription=getDefaultFilterDescription } = availableFilter
        const currentFilter = currentFilters.find(filter=>filter.id === id)
        const description = getFilterTypeDescription(availableFilter, currentFilter?.value)
        if(description) {descriptions.push(description)}
    })

    if(descriptions.length===0) {
        return getEmptyFiltersDescription()
    }
    return <>
    {descriptions.map((description, index)=>{
        return <React.Fragment key={`filter_description_${index}`}>{description}{index<descriptions.length-1?' / ':'.'}</React.Fragment>
    })}
    </>
}

// default function to define if a current filter of the given type is empty
const getDefaultIsEmpty = (type:FilterType, value?: CurrentFiltersValueType):boolean =>{
    switch(type){
        case FilterType.SELECT:
            const selectValue = value as CSOption[]
            return (!selectValue || selectValue.length === 0)
        case FilterType.SELECT_MULTI:
            const selectMultiValue = value as CSOption[]
            return (!selectMultiValue || selectMultiValue.length ===0)
        case FilterType.INPUT:
            return !value
        case FilterType.DATEPICKER:
            if(!value) return true
            const dateValue = (value) as [Date|null, Date|null]
            return (!dateValue[0] || !dateValue[1])
        case FilterType.TREENODE:
            const treeValue = value as string[]
            return (!treeValue || treeValue.length === 0)
        case FilterType.SELECTINPUT:
            const siValue = value as CSSelectValue
            return (!siValue || (!siValue.formControlValue || !siValue.selectValue))
        default:
            return true
    }
}

// single filter empty check
const isEmptyFilter = (currentFilter: CurrentFilter|undefined, availableFilter: FilterTypeDefinition):boolean =>{
    if(!currentFilter) return true

    const { type, getIsEmpty } = availableFilter
    return getIsEmpty?getIsEmpty(currentFilter?.value):getDefaultIsEmpty(type, currentFilter?.value)
}

// add default function, set to optional th getIsEmpty in the filter definition
const areEmptyFilters = (currentFilters: CurrentFilters|undefined, availableFilters: Array<FilterTypeDefinition>):boolean => {
    if(!currentFilters) return true

    return availableFilters.every(availableFilter => {
        const currentFilter = currentFilters.find(filter=>filter.id === availableFilter.id)
        return isEmptyFilter(currentFilter, availableFilter)
        // const { id, type, getIsEmpty } = availableFilter
        // const result = getIsEmpty?getIsEmpty(currentFilter?.value):getDefaultIsEmpty(type, currentFilter?.value)
        // return result
    })

}

export {
    renderFiltersDescription,
    getErrorFiltersDescription, 
    getFilterShops,
    getFilterOrderTypes,
    getFilterBrands,
    getFilterCategories,
    getFilterQuantity,
    getFilterSeasons,
    getFilterWarehouses,
    searchSkuOptions,
    searchOrdesByShopOrdId,
    RenderFilter, 
    getLogsComponent,
    getLogsGroups,
    FilterType, 
    getEmptyFilters, 
    getFiltersBrandDescription, 
    getFiltersQuantityDescription, 
    getFiltersCategoryDescription, 
    getFilterCustomers,
    getEmptyFiltersDescription, 
    getFiltersSeasonDescription, 
    getFiltersShopDescription,
    getFiltersSkuDescription,
    getFiltersOrderTypeDescription,
    getDefaultFilters,
    areEmptyFilters,
    isEmptyFilter
}