import CSDiv from "@components/HTMLElements/CSDiv"
import Overlay from "@components/Overlay/Overlay"
import {Order, Shipment, ShipmentOrder, ShipmentStatus } from 'cs.node.utils/csapi/provisioning';
import Localization from "@localization/Index"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styles from './css/ShipmentPreview.module.scss'
import {getNearestShipmentStatus, getShipmentStatus } from "@utils/ShipmentUtils"
import { format, parseISO } from "date-fns"
import { copyToClipboard } from "@utils/StringUtils"
import { getOrder } from "@api/Orders"
import { ShipmentOrderInfo} from "@interfaces/ShipmentOrder"
import ShipmentOrderRow from '@components/ShipmentOrderRow/ShipmentOrderRow';
import SectionGroup from "@components/SectionGroup/SectionGroup";
import SectionGroupInlineItem from "@components/SectionGroup/SectionGroupInlineItem";
import CSInput from "@components/Form/CSInput";




type ShipmentPreviewProps = {
    shipment: Shipment,
    onClose: Function
}

const ShipmentPreview = (props:ShipmentPreviewProps) => {
    const { shipment, onClose } = props
    const [searchOrdersShipment, setSearchOrdersShipment] = useState("")
    const [ordersShipment, setShipmentOrder] = useState<ShipmentOrderInfo[] | undefined>()
    const [loadingOrdersShipment, serLoadingOrdersShipment] = useState(true)
    const navigate = useNavigate()

    const status = getNearestShipmentStatus(shipment?.status_history as ShipmentStatus[]) 
    const statusInfo = getShipmentStatus(status?.status)
    const { city, province, province_code, postal_code, country_code, address_lines } = shipment?.address  ?? {}
    
    const firstLineAddress = `${ address_lines ? address_lines[0] : "N.D." || ""}`
    const secondLineAddress = `${postal_code || ""} ${city || ""} ${!!province?`(${province})`:''} ${country_code || ""}`

    const filteredShipmentOrderRow = ordersShipment?.filter(orderShipment=>{
        return (orderShipment.order_status?.toLowerCase().includes(searchOrdersShipment) ||
                orderShipment.currency?.toLowerCase().includes(searchOrdersShipment)
        )
    })

    const loadOrders = async ()=> {
        console.log("LOADING ORDER SHIPMENT")
        
            const orders = shipment.orders ? await getOrdersByIds(shipment.orders) : []
            const _ordersShipment: ShipmentOrderInfo[] = [];

            for (const order of orders) {
                const ord = order?.content as Order
                if(ord){
                    _ordersShipment.push({
                        id: ord._id?.$oid,
                        order_id: ord._id?.$oid!,
                        shop_id: ord.shop_id?.$oid,
                        shop_order: ord.shop_order_id,
                        tot_qty: ord.tot_qty || 0,
                        amount: ord.tot_amount?.amount || 0,
                        currency: ord.tot_amount?.currency || '',
                        order_status: ord.order_status || undefined,
                    });
                }
            }
            setShipmentOrder(_ordersShipment);
            serLoadingOrdersShipment(false)

    }

    useEffect(()=>{
        loadOrders()
    },[shipment])


    // Funzione di utilità per ottenere gli ordini dato un array di order IDs
    async function getOrdersByIds(orderIds : ShipmentOrder[]) {
        const orderPromises = orderIds.map((orderId) => getOrder(orderId.order_id?.$oid as string));
        return await Promise.all(orderPromises);
    }
    
    return (
        <Overlay onClose={()=>{onClose()}}>
            <div className="w-100 p-2">
                <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex justify-content-left align-items-center">
                        <div>
                        <h3>{Localization.PAGE_SHIPMENTS.SHIPMENT} <span onClick={()=>{copyToClipboard({text: shipment._id?.$oid??""})}}  className={styles.copy}><strong>{shipment._id?.$oid}</strong></span></h3>
                            <div className="d-flex justify-content-left align-items-center">
                                <i className={`${statusInfo.icon} font-1-5x text-${statusInfo.color} me-2`} />
                                <strong className={`text-${statusInfo.color} me-4`}>{status?.status ?? "N.D."}</strong>
                                <i className={`bi-calendar3 font-1-5x text-muted me-2`} />
                                <span className="small me-4">
                                    {(status?.date?.$date)?format(parseISO(status?.date?.$date), 'dd/MM/yyyy HH:mm'):"N.D."}
                                </span>
                                <i className={`bi-cash-stack font-1-5x text-muted me-2`} />
                                <span className="small me-4">{`${shipment?.cost?.amount ?? "N.D."} ${shipment?.cost?.currency ?? ""}`}</span>
                                
                            </div>
                        </div>
                    </div>
                    <div className="p-1" role="button" onClick={()=>{onClose()}}>
                        <i className={`bi-x font-3x`} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <SectionGroup className="me-2" title={Localization.PAGE_SHIPMENTS.PREVIEW.RECIPIENT_INFO} backgroundIcon="bi-person-circle">
                            <SectionGroupInlineItem label={Localization.PAGE_SHIPMENTS.PREVIEW.BUYER} value={`${shipment.first_name || "N.D."} ${shipment.last_name || "N.D."}`} icon="bi-person-circle" />
                            <SectionGroupInlineItem label={Localization.PAGE_SHIPMENTS.PREVIEW.COMPANY} value={shipment.company_name || "N.D."} icon="bi-building" />
                            <SectionGroupInlineItem label={Localization.CONTACTS.PHONE} value={`${shipment.contacts?.phone|| "N.D."}`} icon="bi-telephone" />
                            <SectionGroupInlineItem label={Localization.CONTACTS.MOBILE} value={`${shipment.contacts?.mobile|| "N.D."}`} icon="bi-phone" />
                            <SectionGroupInlineItem label={Localization.CONTACTS.EMAIL} value={<span className={styles.copy} onClick={()=>{copyToClipboard({text: shipment.contacts?.email??""})}}>{shipment.contacts?.email|| "N.D."}</span>} icon="bi-envelope" />
                        </SectionGroup>

                    </div>
                    <div className="col-md-12">
                        <SectionGroup className="me-2" title={Localization.PAGE_SHIPMENTS.PREVIEW.SHIPMENT_INFO} backgroundIcon="bi-truck">
                            <SectionGroupInlineItem label={Localization.PAGE_SHIPMENTS.PREVIEW.ADDRESS} value={!!shipment?.address? <div className="d-flex flex-column justify-content-end align-items-end">
                                    <div className="text-end">{firstLineAddress}</div>
                                    <div className="text-end"> {secondLineAddress}</div>
                                </div>:<>
                                    <div className="text-end">{"N.D."}</div>
                            </>} icon="bi-person-circle" />
                            <SectionGroupInlineItem label={Localization.PAGE_SHIPMENTS.TABLE.CARRIER} value={shipment.carrier || "N.D."} icon="bi-truck" />
                            <SectionGroupInlineItem label={Localization.PAGE_SHIPMENTS.TABLE.TRACKING_NUMBER} value={<span className={styles.copy} onClick={()=>{copyToClipboard({text:shipment?.tracking_number ??""})}} >{shipment?.tracking_number || "N.D."}</span>} icon="bi-upc" />
                            <SectionGroupInlineItem label={Localization.PAGE_SHIPMENTS.TABLE.TRACKING_URL} value={<span className={styles.link} onClick={()=>{
                                if(shipment?.tracking_url) {
                                    window.open(shipment.tracking_url, '_new')
                                }
                            }}>{shipment?.tracking_url || "N.D."}</span>} icon="bi-upc" />
                        </SectionGroup>
                    </div>
                </div>
                <SectionGroup title={Localization.PAGE_SHIPMENTS.ORDINI} toolbar={<div className="d-flex justify-content-end align-items-center p-2">
                            <CSInput controlId="tree_search" type="text" icon="bi-search" disabled={loadingOrdersShipment} placeholder={Localization.CERCA} value={searchOrdersShipment} onChange={(e)=>setSearchOrdersShipment(e.target.value)} />
                        </div>}>
                    {(ordersShipment ??[])?.length===0 && !loadingOrdersShipment && 
                        <p>{Localization.PAGE_SHIPMENTS.NESSUN_ORDINE_PRESENTE}</p>
                    }
                    {(ordersShipment ??[])?.length>0 && <>
                        
                        <CSDiv className="p-1" fillVertical offsetVertical={180} style={{overflowY: "auto", minHeight:'110px'}}>
                        {loadingOrdersShipment && <>
                                    <ShipmentOrderRow loading />
                                    <ShipmentOrderRow loading />
                                    <ShipmentOrderRow loading />
                                </>}
                                {!loadingOrdersShipment && filteredShipmentOrderRow && filteredShipmentOrderRow.length>0 && filteredShipmentOrderRow?.map(item=>{
                                    return <ShipmentOrderRow key={`order-${item.id}`} item={item} />
                                })}
                        </CSDiv>
                        </>
                    }
                </SectionGroup>
            </div>
        </Overlay>
    )
}

export default ShipmentPreview