import axios, { AxiosResponse } from "axios"
import { checkResponseStatus } from "@api/Utils"
import { CurrentFilters } from "@utils/AdvancedFiltersUtils";
import { CSSelectValue } from "@components/Form/CSSelectInput";
import { EntityListResponseShipment } from "cs.node.utils/csapi/provisioning";



export const getShipments = async (searchTerm: string, filters:CurrentFilters, pageIndex: number=0): Promise<EntityListResponseShipment> => {
    try {
      const date = filters.find(filter=>filter.id==="date")
      const orderType = filters.find(filter=>filter.id==="orderType")
      const filterDate = (date?.value?? [null, null]) as [Date|null, Date|null] 
      const dateFrom = filterDate[0]
      const dateTo = filterDate[1]
      const {selectValue, formControlValue } = (orderType?.value ?? {selectValue: '', formControlValue: ''}) as CSSelectValue
    
      const params = {
        shopOrderId: formControlValue,
        orderType: selectValue,
        pageIndex, 
        pageSize: 20,
        dateFrom: dateFrom?.toISOString(),
        dateTo: dateTo?.toISOString()
      }

      console.log(params)

      const response:AxiosResponse<EntityListResponseShipment,any> = await axios.get('api/shipments', {
        params
      })
      return response.data
    } catch (error: any) {
      checkResponseStatus(error)
      throw error;
    }
  }

//TODO: da completare
export const setShipments = async(shipmentId: string) =>{
    try {
        const response = await axios.patch(`api/shipments/${shipmentId}/`)
        return response.data
    } catch(error:any) {
        throw error
    }
}

export const getShipmentsByOrderTypeAndId = async(orderType: "SALES_ORDER" | "TRANSFER_ORDER", orderId: string, pageIndex?: number, pageSize?: number, sort?: string) =>{
  try{
    const params = { orderType, orderId, pageIndex, pageSize, sort }
    const response:AxiosResponse<EntityListResponseShipment,any> = await axios.get('api/shipments/listByOrderTypeAndId', {
      params
    })

    return response.data
  } catch (error: any) {
    checkResponseStatus(error)
    throw error;
  }
}