import { CSSProperties } from "react"
import Form from "react-bootstrap/esm/Form"
import styles from './css/CSSwitch.module.scss'

type Props = {
    controlId: string
    inline?: boolean
    checked: boolean
    disabled?: boolean
    readOnly?: boolean
    onChange?: (e:any)=>void
    label: string | JSX.Element,
    className?: string,
    style?: CSSProperties,
    cySelector?: string
}

const CSSwitch = (props:Props) =>{

    const { className, style, cySelector, controlId, inline, disabled, checked, onChange, label } = props
    return (
        <Form.Group className={className} style={style} data-cy={cySelector}>
            <Form.Check 
                id={controlId}
                inline={inline}
                disabled={disabled}
                type="switch"
                label={label}
                className={styles.switch}
                checked={checked}
                onChange={(e)=>{onChange?.(e)}}
            />
        </Form.Group>
    )
}

export default CSSwitch