import { Variant } from "@interfaces/Variant"
import { PropertyAnalysis } from "cs.node.utils/ItemUtils"

const en = {
    __LANGUAGE: "en",
    ACCEDI: "Sign in",
    LOGIN: "Login",
    RICORDAMI: "Remember me",
    CREDENZIALI_NON_RICONOSCIUTE: "Unrecognized credentials",
    CERCA: "Search",
    CAMBIA_PASSWORD: "Change password",
    SOTTOUTENTI: "Subusers",
    BILLING: "Billing",
    MODULI: "Forms",
    LOGOUT: "Logout",
    COPIA: "Copy",
    COPIATO: "Copied!",
    OK: "Ok",
    MODIFICA: "Edit",
    ELIMINA: "Delete",
    VISUALIZZA: "View",
    ORDINA: "Order",
    AGGIUNGI: "Add",
    CARICA: "Upload",
    ANNULLA: "Cancel",
    INDIETRO: "Back",
    CHIUDI: "Close",
    RIFIUTA: "Reject",
    RIPRISTINA: "Restore",
    RIGENERA: "Regenerate",
    NESSUNO: "No one",
    SELEZIONA_TUTTI: "Select all",
    DESELEZIONA_TUTTI: "Deselect all",
    CONFERMA: "Confirm",
    ATTENDERE: "Please wait",
    ATTENZIONE: "Warning",
    ERRORE_GENERICO: "An error occurred, please try again.",
    DETTAGLI_ERRORE: "Error details",
    SELEZIONA_UN_VALORE: "Select a value",
    SELEZIONA_MODALITA_CARICAMENTO: "Select the upload mode:",
    VIA_FILE: "Via file",
    VIA_URL: "Via URL",
    SELEZIONA_FILE_DA_CARICARE: "Select the file to upload",
    URL_DA_CARICARE: "URL to upload",
    SELEZIONA_UN_FILE: "Select a file",
    SI: "Yes",
    NO: "No",
    TUTTI: "All",
    SALES_ORDER: "Sales order",
    TRANSFER_ORDER: "Transfer order",
    SALVA: "Save",
    SCARICA: "Download",
    E_MAIL: "Email",
    ELIMINAZIONE_IN_CORSO: "Deletion in progress...",
    SALVATAGGIO_IN_CORSO: "Saving in progress...",
    OPERAZIONE_AVVENUTA_CON_SUCCESSO: "Operation completed successfully",
    COPIA_VALORE: "Copy value",
    COPIA_ID: "Copy ID",
    PROCEDI: "Proceed",
    PROSEGUI: "Continue",
    CAMBIA: "Change",
    CREA: "Create",
    ISTRUZIONI: "Instructions",
    DETTAGLI: "Details",
    MOSTRA: "Show",
    RISULTATI: "__COUNT__ results",
    HOME: "Home",
    INIZIA: "Start",
    VERIFICA: "Verify",
    SELEZIONA_FILE: "Select file",
    CLICCA_PER_COPIARE: "Click to copy",
    SALE_CHANNEL: "Sales Channel",
    SALES_CHANNEL: "Sales Channels",
    BADGE_SHOP_PRIMARY: "Primary Shop",
    MARKUP: "Markup",
    DISCOUNT: "Discount",
    TAX_RATE: "Tax Rate",
    COUNTRY: "Country",
    CONTACTS: {
        EMAIL: "Email",
        PHONE: "Phone",
        MOBILE: "Mobile"
    },
    MENU: {
        IL_TUO_CLOUDSTORE: "Your CloudStore",
        CATALOGO: "Catalog",
        ORDINI: "Orders",
        SPEDIZIONI: "Shipping",
        CLIENTI: "Customers",
        SHOPS: "Shops",
        ASSOCIAZIONE_MAGAZZINI: "Warehouse Bindings",
        MAGAZZINI: "Warehouses",
        LISTA: "List",
        LISTA_ARTICOLI: "Item list",
        SHIPMENTS: "Shipments",
        LISTING: "Listing",
        ASSOCIAZIONE_ATTRIBUTI: "Attribute binding",
        AGGIORNAMENTO_DISPONIBILITA: "Availability update",
        WHS_MOVEMENTS: "Warehouse movements",
        CATEGORY: "Categories",
        RETURNS: "Returns",
        GESTIONE_PREZZI: "Price Management",
        CONDIZIONI_COMMERCIALI: "Business Conditions",
        MODIFICA_CONDIZIONE_COMMERCIALE: "Edit business condition",
        LOGS: "Logs",
        PACKAGING: "Packaging",
        STAMPA_ETICHETTE: "Labels printing",
        LOGS_SETTINGS: "Log configuration",
        RISERVE: "Reservations",
        QUEUES: "Queues",
        REGOLE_ESCLUSIONE_LISTING: "Listing Exclusion rules",
        REGOLE_ESCLUSIONE: "Exclusion rules",
        MODIFICA_REGOLA_ESCLUSIONE_LISTING: "Edit listing exclusion rule",
        CUSTOMERS: "Customers",
        MODULI: "Modules"
    },
    PAGE_ACCESS_DENIED: {
        TITLE: "Access Denied",
        SUBTITLE: "You cannot access the requested resource.",
        BACK_TO_HOME: "Back to home"
    },
    PAGE_MAINTENANCE: {
        TITLE: "Maintenance in progress",
        SUBTITLE: "We are performing maintenance operations, but we will be back online soon.",
    },
    PAGE_BUSINESS_CONDITIONS: {
        NUOVA_CONDIZIONE_COMMERCIALE: "New business condition",
        AVVIA_ELABORAZIONE: "Start processing",
        TEST_CONDIZIONI_COMMERCIALI: "Run a test of business conditions",
        TUTTE_LE_CONDIZIONI_COMMERCIALI_APPLICATE: "All business conditions are applied.",
        DA_ELABORARE: "There are __ELEMENTS__ to process.",
        ASSOCIAZIONI_CON_SHOPS: "Associations with shops",
        ASSOCIAZIONI_CON_CUSTOMER: "Associations with customers",
        CONDIZIONI: "Conditions",
        CONDIZIONI_ATTIVE: "Active conditions",
        CONDIZIONI_DA_ELABORARE: "Conditions to process",
        CONDIZIONI_DISABILITATE: "Disabled conditions",
        CONDIZIONI_CANCELLATE: "Canceled conditions",
        CONDIZIONI_IN_ELABORAZIONE: "Conditions in processing",
        TAB_0: "Active / to process / disabled conditions",
        CONFERMA_DISABILITA: "Confirm the deactivation of condition __NAME__?",
        CONFERMA_CANCELLAZIONE: "Confirm the cancellation of condition __NAME__?",
        CONFERMA_ABILITA: "Confirm the enabling of condition __NAME__?",
        CONFERMA_ANNULLAMENTO: "Confirm the cancellation of the ongoing operation for condition __NAME__?",
        CONFERMA_AVVIO_ELABORAZIONE: "Confirm the start of processing business conditions?",
        DATI_GENERALI: "General data",
        NOME: "Name",
        TAX_RATE: "Tax Rate",
        CONDIZIONE_DI_DEFAULT: "Default condition",
        ASSOCIAZIONE_SHOP: "Shop association",
        ASSOCIAZIONE_CUSTOMER: "Customer association",
        ECCEZIONI: "Exceptions",
        ASSOCIAZIONE_SHOP_INFO: "In this section, you can associate this business condition with one or more shops.",
        AGGIUNGI_ASSOCIAZIONE: "Add association",
        NUOVA_ASSOCIAZIONE: "New association",
        CONFERMA_ASSOCIAZIONE_SHOP: "Confirm the association of this business condition with the selected shops?",
        CONFERMA_CANCELLAZIONE_ASSOCIAZIONE_SHOP: "Confirm the cancellation of the selected association?",
        ASSOCIAZIONE_CUSTOMER_INFO: "In this section, you can associate this business condition with one or more customers.",
        CONFERMA_ASSOCIAZIONE_CUSTOMER: "Confirm the association of this business condition with the selected customers?",
        CONFERMA_CANCELLAZIONE_ASSOCIAZIONE_CUSTOMER: "Confirm the cancellation of the selected association?",
        SELEZIONA_UN_CUSTOMER: "Select a customer",
        ECCEZIONI_INFO: "In this section, you can define exceptions to the default condition defined in the header.",
        NUOVA_ECCEZIONE: "New exception",
        MODIFICA_ECCEZIONE: "Edit exception",
        SCARICA_TEMPLATE: "Download empty Excel template",
        SCARICA_ECCEZIONI: "Download Excel of configured exceptions",
        CARICA_ECCEZIONI: "Upload Excel of exceptions for this business condition",
        SELEZIONA_ALMENO_UN_ATTRIBUTO: "Select at least one attribute to apply the condition",
        CONFERMA_SALVATAGGIO_CONDIZIONE: "Confirm the saving of the changes made?",
        LOGS: "Activity Logs",
        TABLE: {
            NOME: "Name",
            VARIAZIONE: "Variation",
            MARKUP: "Markup",
            DISCOUNT: "Discount",
            NUMERO_ECCEZIONI: "No. of Exceptions",
            STATO: "Status",
            VAI_A_DETTAGLIO: "Go to details",
            DISABILITA: "Disable",
            CANCELLA: "Delete",
            RIATTIVA: "Reactivate",
            ANNULLA_OPERAZIONE: "Cancel operation"
        },
        STATUS: {
            LABEL: {
                PENDING: "To be processed",
                PROCESSING: "In processing",
                CONFIRMED: "Confirmed",
                TO_BE_DEPRECATED: "To be deprecated",
                DEPRECATED: "Deprecated",
                TO_BE_DISABLED: "To be disabled",
                DISABLED: "Disabled",
                TO_BE_CANCELED: "To be canceled",
                CANCELED: "Canceled",
                TO_BE_ENABLED: "To be enabled"
            },
            SHORT: {
                PENDING: "This condition is waiting for confirmation and activation.",
                PROCESSING: "This condition is currently being processed.",
                CONFIRMED: "This condition is confirmed and currently active.",
                TO_BE_DEPRECATED: "This condition is scheduled to be deprecated in the next processing.",
                DEPRECATED: "This condition is deprecated and no longer active.",
                TO_BE_DISABLED: "This condition is scheduled to be disabled in the next processing.",
                DISABLED: "This condition is currently disabled and not applied.",
                TO_BE_CANCELED: "This condition is scheduled to be canceled in the next processing.",
                CANCELED: "This condition is canceled.",
                TO_BE_ENABLED: "This condition is scheduled to be enabled in the next processing."
            },
            EXTENDED: {
                PENDING: "This condition is waiting for confirmation and activation.",
                PROCESSING: "This condition is currently being processed.",
                CONFIRMED: "This condition is confirmed and currently active.",
                TO_BE_DEPRECATED: "This condition is scheduled to be deprecated in the next processing.",
                DEPRECATED: "This condition is deprecated and no longer active.",
                TO_BE_DISABLED: "This condition is scheduled to be disabled in the next processing.",
                DISABLED: "This condition is currently disabled and not applied.",
                TO_BE_CANCELED: "This condition is scheduled to be canceled in the next processing.",
                CANCELED: "This condition is canceled.",
                TO_BE_ENABLED: "This condition is scheduled to be enabled in the next processing."
            }
        },
        EXCEPTIONS: {
            SEASON: "Season",
            BRAND: "Brand",
            CATEGORY: "Category",
            SKU_PARENT: "SKU Parent",
            PARTNER_CODE: "Partner",
            VARIAZIONE: "Variation",
            AZIONI: "Actions"
        },
        PROCESSING: {
            ELABORAZIONE_IN_CORSO: "Processing in progress...",
            PREPARAZIONE: "Preparation...",
            ELABORAZIONE_ASSOCIAZIONI_CUSTOMERS: "Processing customer associations...",
            ELABORAZIONE_ASSOCIAZIONI_SHOPS: "Processing shop associations...",
            AGGIORNAMENTO_STATI_RELAZIONI: "Updating states and relations...",
            APPLICAZIONE_CONDIZIONI_CREAZIONE_LISTING: "Applying conditions and creating listings..."
        },
        UPLOAD_RESULTS: {
            RISULTATO_CARICAMENTO: "Upload result",
            NO_ROWS_ACCEPTED: "Since no row was processed correctly, no changes were made to the current exceptions.",
            SOME_ROWS_ACCEPTED: "You can view the result in the exceptions table on this page; some Excel rows may have been grouped for easier management and clarity. If you're satisfied with the result, simply save the business condition using the button in the top right!",
            EXCEPTIONS_CREATED: "Exceptions created:",
            ROWS_ERROR: "Rows with errors (discarded):",
            ROWS_WARNING: "Rows with warnings:",
            RIGA: "Row"
        }
    },
    PAGE_LISTING_EXCLUSION_RULES: {
        STATUS: {
            LABEL: {
                PENDING: "Da elaborare",
                PROCESSING: "In elaborazione",
                CONFIRMED: "Confermata",
                TO_BE_DEPRECATED: "Da rendere obsoleta",
                DEPRECATED: "Obsoleta",
                TO_BE_DISABLED: "Da disabilitare",
                DISABLED: "Disabilitata",
                TO_BE_CANCELED: "Da cancellare",
                CANCELED: "Cancellata",
                TO_BE_ENABLED: "Da abilitare",
                    },
            SHORT: {
                PENDING: "Questa regola è in attesa di essere confermata e attivata.",
                PROCESSING: "Questa regola è attualmente in fase di elaborazione.",
                CONFIRMED: "Questa regola è confermata e attualmente in vigore.",
                TO_BE_DEPRECATED: "Questa regola è stata impostata per essere resa obsoleta durante la prossima elaborazione.",
                DEPRECATED: "Questa regola è obsoleta e non è più in vigore.",
                TO_BE_DISABLED: "Questa regola è stata impostata per essere disabilitata durante la prossima elaborazione.",
                DISABLED: "Questa regola è attualmente disabilitata e non viene applicata.",
                TO_BE_CANCELED: "Questa regola è stata impostata per essere cancellata durante la prossima elaborazione.",
                CANCELED: "Questa regola è cancellata.",
                TO_BE_ENABLED: "Questa regola è stata impostata per essere abilitata durante la prossima elaborazione.",
            },
            EXTENDED: {
                PENDING: "LONG DESCRIPTION PENDING",
                PROCESSING: "LONG DESCRIPTION PROCESSING",
                CONFIRMED: "LONG DESCRIPTION CONFIRMED",
                TO_BE_DEPRECATED: "LONG DESCRIPTION TO_BE_DEPRECATED",
                DEPRECATED: "LONG DESCRIPTION DEPRECATED",
                TO_BE_DISABLED: "LONG DESCRIPTION TO_BE_DISABLED",
                DISABLED: "LONG DESCRIPTION DISABLED",
                TO_BE_CANCELED: "LONG DESCRIPTION TO_BE_CANCELED",
                CANCELED: "LONG DESCRIPTION CANCELED",
                TO_BE_ENABLED: "LONG DESCRIPTION TO_BE_ENABLED",
            }
        },
        PROCESSING: {
            ELABORAZIONE_IN_CORSO: "Elaborazione in corso...",
            PREPARAZIONE: "Preparazione delle regole in corso...",
            ELABORAZIONE: "Elaborazione delle regole in corso...",
            APPLICAZIONE: "Applicazione delle regole in corso...",
            FINALIZZAZIONE: "Impostazione stati finali e creazione dei listing in corso..."
        },
        TABLE: {
            NOME: "Nome regola",
            DATA_CREAZIONE: "Data creazione",
            DATA_MODIFICA: "Data modifica",
            STATO: "Stato",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            DISABILITA: "Disabilita",
            CANCELLA: "Cancella",
            RIATTIVA: "Riattiva",
            ANNULLA_OPERAZIONE: "Annulla operazione",
        },
        TUTTE_LE_REGOLE_APPLICATE: "Tutte le regole sono applicate.",
        DA_ELABORARE: "Ci sono __COUNT__ regole da elaborare.",
        REL_NON_COMPLETA: "Questa regola non verrà applicata in quanto non sono state inserite le condizioni o non è stata specificata la lista degli shop esclusi",
        CONFERMA_AVVIO_ELABORAZIONE: "Confermi l'avvio dell'elaborazione delle regole?",
        NUOVA_REGOLA: "Nuova regola",
        AVVIA_ELABORAZIONE: "Avvia elaborazione delle regole",
        TEST_REGOLE: "Esegui un test sulle regole",
        REGOLE_ATTIVE: "Regole attive",
        REGOLE_DA_ELABORARE: "Regole da elaborare",
        REGOLE_DISABILITATE: "Regole disabilitate",
        REGOLE_CANCELLATE: "Regole cancellate",
        REGOLE_IN_ELABORAZIONE: "Regole in elaborazione",
        TAB_0: "Regole attive / da elaborare / disabilitate",
        CONFERMA_DISABILITA: "Confermi la disattivazione della regola __NAME__?",
        CONFERMA_CANCELLAZIONE: "Confermi la cancellazione della regola __NAME__?",
        CONFERMA_ABILITA: "Confermi l'abilitazione della regola __NAME__?",
        CONFERMA_ANNULLAMENTO: "Confermi l'annullamento dell'operazione in corso per la regola __NAME__?",
        NOME: "Nome",
        CONFERMA_SALVATAGGIO_REGOLA: "Confermi il salvataggio delle modifiche effettuate alla regola?",
        DATI_GENERALI: "Dati generali",
        CONDIZIONI: "Condizioni",
        SHOP_ESCLUSI: "Shop esclusi",
        STORICO: "Storico",
        HISTORY: {
            NESSUN_ELEMENTO_DISPONIBILE: "Nessun elemento disponibile.",
            CREATE_GUI: "La regola è stata creata sull'applicazione CloudStore.",
            CREATE_ENGINE: "La regola è stata creata dal motore di regole CloudStore durante il passaggio:",
            CREATE_API: "La regola è stata creata dalle API CloudStore.",
            UPDATED_GUI: "La regola è stata modificata sull'applicazione CloudStore.",
            UPDATED_ENGINE: "La regola è stata modificata dal motore di regole CloudStore durante il passaggio:",
            UPDATED_API: "La regola è stata modificata dalle API CloudStore.",
            STEPS: {
                _0: "sconosciuto",
                _1: "preparazione delle regole",
                _2: "elaborazione delle regole",
                _3: "applicazione delle regole",
                _4: "impostazione stati finali e creazione dei listing",
            }
        },
        EXCLUDED_SHOPS_INFO: "In questa sezione puoi scegliere a quali shop applicare l'esclusione dai listing definita nella regola che stai modificando.",
        SELEZIONA_SHOP_ESCLUSI: "Seleziona gli shop da escludere",
        NUOVA_CONDIZIONE: "Nuova condizione",
        MODIFICA_CONDIZIONE: "Modifica condizione",
        SCARICA_TEMPLATE: "Scarica template Excel vuoto",
        SCARICA_CONDIZIONI: "Scarica Excel delle condizioni configurate",
        CARICA_CONDIZIONI: "Carica Excel delle condizioni per questa regola",
        CONDIZIONI_INFO: "In questa sezione puoi definire le condizioni affinché la regola di esclusione risulti valida.",
        CONDITIONS: {
            SEASON: "Season",
            BRAND: "Brand",
            CATEGORY: "Category",
            SKU_PARENT: "SKU Parent",
            OPERATORE: "Operatore",
            VALORE: "Valore",
            CONFERMA_AGGIUNGI_A_CONDIZIONE: "Confermi l'aggiunta dei valori richiesti alla condizione?",
            CONFERMA_AGGIUNGI_A_CONDIZIONE_HELP: "L'aggiunta non verrà salvata in automatico! Potrai sempre decidere di modificare le condizioni prima del salvataggio.",
            VALORE_PRESENTE_IN_CLOUDSTORE: "Questo valore è già presente in qualcuno degli articoli del tuo inventario.",
            VALORE_NON_PRESENTE_IN_CLOUDSTORE: "Questo valore non è ancora censito in nessuno degli articoli del tuo inventario.",
            NESSUN_VALORE_SELEZIONATO: "Nessun valore selezionato.",
            CATEGORIE_SELEZIONATE: "Ci sono __COUNT__ categorie selezionate. Per visualizzarle, utilizzare il selettore qui sopra.",
        },
        UPLOAD_RESULTS: {
            RISULTATO_CARICAMENTO: "Risultato del caricamento",
            NO_ROWS_ACCEPTED: "Poichè nessuna condizione caricata è risultata valida, le condizioni attuali rimangono immutate.",
            SOME_ROWS_ACCEPTED: "Puoi visualizzare il risultato del caricamento nei relativi box di questa pagina.",
        }
    },
    PAGE_MODULES: {
        STATUS:{
            LABEL:"Status",
            ACTIVE:"Enabled",
            INACTIVE:"Not enabled",
            NOT_APPLICABLE: "Not applicable",
            PENDING: "Pending",
            VALIDATING: "Validating",
            CONFIRMED: "Confirmed",
            AUTH_ERROR: "Authentication error",
            ERROR: "Error",
        },
        TITLES: {
            BUSINESS_CONDITIONS: "Business Conditions",
            B2B: "B2B",
            POS: "POS",
            ERP: "ERP",
            FINANCE: "Finance",
            FxFF: "Fullfilment by Farfetch",
            WAREHOUSES: "Warehouses",
            NETSUITE: "NetSuite integration",
            MARKETPLACE_SAVAIT: "Marketplace Savait",
            DETAILS_TITLE:"Module details"
        },
        TITLES_DETAILS: {
            BUSINESSCONDITIONS:"Business Conditions Module",
            B2B: "B2B Module",
            POS: "POS module",
            ERP: "ERP Module",
            FINANCE: "Finance module",
            FXFF: "Fullfilment Module by Farfetch",
            WAREHOUSES: "Warehouses Module",
            NETSUITE: "NetSuite Integration Module",
            MARKETPLACESAVAIT: "Marketplace Savait Module",
            MARKETPLACE_SAVAIT: "Marketplace Savait Module",
        },
        DESCRIPTIONS: {
            BUSINESS_CONDITIONS: "This module fully manages the pricing information of an item, both at the level of price lists and commercial conditions. It is possible to manage different price lists to be associated with Shops to define selling prices and discounted prices of each item, as well as to define commercial conditions reserved for both Shops (whether they represent retailers or not) and Customers who will purchase through the CloudStore B2B application.",
            B2B: "This application allows you to sell products from your catalog to B2B customers through the typical features of a sales channel, such as customer record management and order flow management. Compatible with PCs and tablets. Order creation: The module enables you to create orders from the catalog by adding items to the cart. The 'Orders from Showroom' feature is particularly interesting as it allows you to create an order during a guided visit in your showroom with an interface specifically optimized for mobile devices. Pre-orders: Give your customers the opportunity to create pre-orders which you can integrate and confirm, specifying discounts and quantities. Item card: The item card is designed to group variations of an item by specifying the variation attribute, thus facilitating the consultation of available quantities for each variation. Quick order: With one click, all available quantities of all catalog variations of an item will be added to the order. Customer records: Independently manage your customers' records by giving them access to the portal via username and password.",
            POS: "It is an application dedicated to Retail management that allows integrating a physical store into the CloudStore ecosystem through simple functionalities such as creating an order and managing customer records. It also allows monitoring of inventory levels in other stores/warehouses, thus providing the end customer with a complete experience.",
            ERP: "The ERP module is designed to provide specific functionalities dedicated to those who integrate their ERP with CloudStore through CloudStore APIs. It implements logic related to quantity updates, facilitates the retrieval of information such as warehouse movements related to orders, and prepares dedicated queues for communicating specific information (such as orders) in order to define a more precise flow and a more comprehensive relationship between the ERP and CloudStore.",
            FINANCE: "This module estimates the tax components on the prices indicated in orders lacking such information during the order receipt phase from shops. The estimate is made based on certain parameters defined by the user, particularly tax percentages and tax classes. Through the tax percentages, tax rates to be applied under certain conditions related to the geographical location of the order's shipping address are defined. Through the tax classes, tax classes to which the tax percentages are associated are defined. It is necessary to specify for each product in inventory the tax class to apply, in order to allow the calculation of the estimate.",
            FXFF: "The integration allows the management of Farfetch channels for which the Fulfillment by Farfetch (FxFF) service is active. During the creation of a Farfetch-type channel, it is possible to determine whether the FxFF service is active and in that case, it is necessary to specify the reference warehouse.",
            WAREHOUSES: "The warehouses module offers various functionalities and areas that the user can use to manage inventory and publication of articles on shops in a broader and customized manner. Warehouse records: List of warehouses used for which it is also possible to specify a client code and priority. Strategy: Various strategies are available that allow CloudStore to decide (or not) the picking warehouse for the goods following the arrival of an order. Advanced features: It will be possible to enable/disable warehouses on individual shops and/or enable/disable the warehouses on individual articles. In this way, it will be possible to customize the actual availability that you want to publish on a specific Shop.",
            NETSUITE: "This integration, optionally activatable in your CloudStore account, enables communication of CloudStore with your Oracle NetSuite account. The integration implements inbound and outbound data processes between the two accounts to synchronize customers, items, orders, billing data, shipping data, and warehouse movements. Synchronization in both directions is supported for various entities, depending on which of the two systems is the master of that specific information. Communication occurs through activity queues, processed by the CloudStore engine and consultable and verifiable through the user interface, through which the operator can reprocess unsuccessful communications. CloudStore interacts with NetSuite through its APIs, operating where possible directly with the CRUD of the involved entities, or obtaining information through saved searches. For the proper functioning of the integration and to manage a richer informational set than the base present in NetSuite, the integration uses Custom Field, Custom Record, and Custom List that must be configured in NetSuite (the configuration of these elements in the NetSuite account is entrusted to the Oracle technological partner of the Customer). The integration is highly customizable by the CloudStore Team through a rich set of configuration parameters and through custom code. To optimize performance, the processes of the integration are typically executed in parallel.",
            MARKETPLACE_SAVAIT: "This module enables a CloudStore account to the typical functionalities of a Marketplace such as managing the catalog from multiple suppliers and routing orders to various partners."
        },
        DESCRIPTIONS_LONG: {
            BUSINESSCONDITIONS: "This module fully manages the pricing information of an item, both at the level of price lists and commercial conditions. It is possible to manage different price lists to be associated with Shops to define selling prices and discounted prices of each item, as well as to define commercial conditions reserved for both Shops (whether they represent retailers or not) and Customers who will purchase through the CloudStore B2B application.",
            B2B: "This application allows you to sell products from your catalog to B2B customers through the typical features of a sales channel, such as customer record management and order flow management. Compatible with PCs and tablets. Order creation: The module enables you to create orders from the catalog by adding items to the cart. The 'Orders from Showroom' feature is particularly interesting as it allows you to create an order during a guided visit in your showroom with an interface specifically optimized for mobile devices. Pre-orders: Give your customers the opportunity to create pre-orders which you can integrate and confirm, specifying discounts and quantities. Item card: The item card is designed to group variations of an item by specifying the variation attribute, thus facilitating the consultation of available quantities for each variation. Quick order: With one click, all available quantities of all catalog variations of an item will be added to the order. Customer records: Independently manage your customers' records by giving them access to the portal via username and password.",
            POS: "It is an application dedicated to Retail management that allows integrating a physical store into the CloudStore ecosystem through simple functionalities such as creating an order and managing customer records. It also allows monitoring of inventory levels in other stores/warehouses, thus providing the end customer with a complete experience.",
            ERP: "The ERP module is designed to provide specific functionalities dedicated to those who integrate their ERP with CloudStore through CloudStore APIs. It implements logic related to quantity updates, facilitates the retrieval of information such as warehouse movements related to orders, and prepares dedicated queues for communicating specific information (such as orders) in order to define a more precise flow and a more comprehensive relationship between the ERP and CloudStore.",
            FINANCE: "This module estimates the tax components on the prices indicated in orders lacking such information during the order receipt phase from shops. The estimate is made based on certain parameters defined by the user, particularly tax percentages and tax classes. Through the tax percentages, tax rates to be applied under certain conditions related to the geographical location of the order's shipping address are defined. Through the tax classes, tax classes to which the tax percentages are associated are defined. It is necessary to specify for each product in inventory the tax class to apply, in order to allow the calculation of the estimate.",
            FXFF: "The integration allows the management of Farfetch channels for which the Fulfillment by Farfetch (FxFF) service is active. During the creation of a Farfetch-type channel, it is possible to determine whether the FxFF service is active and in that case, it is necessary to specify the reference warehouse.",
            WAREHOUSES: "The warehouses module offers various functionalities and areas that the user can use to manage inventory and publication of articles on shops in a broader and customized manner. Warehouse records: List of warehouses used for which it is also possible to specify a client code and priority. Strategy: Various strategies are available that allow CloudStore to decide (or not) the picking warehouse for the goods following the arrival of an order. Advanced features: It will be possible to enable/disable warehouses on individual shops and/or enable/disable the warehouses on individual articles. In this way, it will be possible to customize the actual availability that you want to publish on a specific Shop.",
            NETSUITE: "This integration, optionally activatable in your CloudStore account, enables communication of CloudStore with your Oracle NetSuite account. The integration implements inbound and outbound data processes between the two accounts to synchronize customers, items, orders, billing data, shipping data, and warehouse movements. Synchronization in both directions is supported for various entities, depending on which of the two systems is the master of that specific information. Communication occurs through activity queues, processed by the CloudStore engine and consultable and verifiable through the user interface, through which the operator can reprocess unsuccessful communications. CloudStore interacts with NetSuite through its APIs, operating where possible directly with the CRUD of the involved entities, or obtaining information through saved searches. For the proper functioning of the integration and to manage a richer informational set than the base present in NetSuite, the integration uses Custom Field, Custom Record, and Custom List that must be configured in NetSuite (the configuration of these elements in the NetSuite account is entrusted to the Oracle technological partner of the Customer). The integration is highly customizable by the CloudStore Team through a rich set of configuration parameters and through custom code. To optimize performance, the processes of the integration are typically executed in parallel.",
            MARKETPLACESAVAIT: "This module enables a CloudStore account to the typical functionalities of a Marketplace such as managing the catalog from multiple suppliers and routing orders to various partners."
        },
        B2B: {
            SHOP_PRIMARIO: "Primary shop",
            SHOP_SECONDARI: "Secondary shops number",
        },
        POS: {
            NUMERO_SHOP: "Shop number",
            SHOP_ASSOCIATIONS:"Shop associations"
        },
        WAREHOUSES: {
            ALGO_DESC:{
                NO_OP:"when an order arrives, CloudStore does not perform any operation to choose the goods picking warehouse. The quantities of the warehouses will therefore remain unchanged. Only the quantity of the bucket is updated.",
                WHS_PRIORITY:"when an order arrives, CloudStore will choose the picking warehouse for each item present in the order itself based on the warehouse priorities set by the user. The warehouse with the lowest priority value will be chosen. EX: warehouses with priority 1 is more priority than the warehouse with priority 2. The quantity of the bucket is also updated.",
                TEMP_COMMIT:"when an order arrives, CloudStore adds the items and quantities present in the order to the TEMPORARY COMMIT virtual warehouse. The TEMPORARY COMMIT is taken into consideration in the calculation of the quantities to send to the channel. The quantity of the bucket is also updated .",
            },
            SELECT_ALGO: "Select Algorithm for warehouses module",
            ALGORITMO: "Algorithm",
            NONE :"NONE",
            NO_OP:"No operation",
            WHS_PRIORITY:"Warehouse Priority",
            TEMP_COMMIT:"Support warehouse",
            SHOP_DRIVEN:"SHOP DRIVEN",
        },
        NETSUITE: {
            STATO: "Status",
        },
        ACTIVATE_MODULE:"Module activation",
        UPDATE_DATE:"Update date",
        ENABLE_DISABLED:"Enable / disabled module",
        GENERAL_CONFIG: "General configurations",
        UPDATE_CONFIRM:"You want to continue with the update of the __MODULE__ module",
        ERROR_LOADING:"An error occurred while loading module, please try again or contact CloudStore support",
    },
    PAGE_PACKAGING: {
        SELEZIONA_UN_ORDINE: "Select an order",
        SELEZIONA_UN_MAGAZZINO: "Select a warehouse...",
        SELEZIONA_UN_COMPUTER: "Select a printing computer...",
        SELEZIONA_UNA_STAMPANTE_ZEBRA: "Select a Zebra printer...",
        SELEZIONA_UNA_STAMPANTE_STANDARD: "Select a standard printer...",
        SELEZIONA_UNA_STAMPANTE_DI_SERVIZIO: "Select a service printer...",
        SELEZIONA_UN_MAGAZZINO_INFO: "To start, select a warehouse from the options in the top right.",
        LEGGI_UN_ARTICOLO: "Scan the barcode of an item to identify an order to process.",
        ARTICOLO_NON_IN_ORDINE: "The scanned item does not belong to this order.",
        ARTICOLO_COMPLETATO: "The scanned item has already been fully confirmed in this order.",
        ARTICOLO_IN_NESSUN_ORDINE: "No orders ready for packing were found containing this item.",
        ARTICOLO_IN_ORDINE_PACKAGED: "The item belongs to an order that has already been confirmed but not printed. Do you want to go to the order details page to proceed with printing?",
        ARTICOLO: "Item",
        MAGAZZINO: "Warehouse",
        SCATOLA: "Box",
        SELEZIONA_UNA_SCATOLA_ORDINE: "Select a box for the order",
        SELEZIONA_UNA_SCATOLA_RIGHE: "Select a box for each line of the order",
        QUANTITA_CONFERMATA: "Confirmed quantity",
        QUANTITA_RICHIESTA: "Requested quantity",
        SELEZIONA_UNA_SCATOLA: "Select a box...",
        SELEZIONA_SCATOLE: "Select boxes",
        CONFERMA_SCATOLA: "Confirm box",
        CHIUDI_LAVORAZIONE: "Close processing",
        CHIUDI_ORDINE: "Close order",
        STAMPA_TUTTI: "Print all",
        STAMPA_DI_SERVIZIO: "Service print",
        RICHIESTA_CONFERMA_NO_SCATOLE: "You have not yet confirmed the box selection for this order",
        RICHIESTA_CONFERMA: "Before closing the processing of this order, check that you have printed the necessary documents.",
        PRINT_ERRORS: {
            TITLE: "The following errors occurred during printing:",
            GENERIC: "Generic",
            DOCUMENT_NOT_PDF: "The requested document is not a PDF",
            ORDER_DOCUMENTS_MISSING: "The document is missing from the order",
            URL_NOT_FOUND: "Document URL not found",
            UNAUTHORIZED: "Unauthorized access to the document",
            CETTIRE_NO_ORDER: "Cettire order not confirmed and therefore unavailable for printing",
            NO_ORDER_ITEM_ID: "Order item ID not present",
            CONFIRM_ORDER_ITEM: "An error occurred while confirming the Cettire order line.",
            UNKNOWN_ERROR: "Unknown error",
            SERVICE: "Service print"
        },
        ZEBRA_ERRORS: {
            OK: "The printer is functioning correctly.",
            PAPER_OUT: "Paper missing or depleted",
            RIBBON_OUT: "Ribbon missing or depleted",
            MEDIA_DOOR_OPEN: "Door open",
            CUTTER_FAULT: "Cutter error",
            PRINTHEAD_OVERHEATING: "Printhead overheating",
            MOTOR_OVERHEATING: "Motor overheating",
            PRINTHEAD_FAULT: "Printhead error",
            INCORRECT_PRINTHEAD: "Incorrect printhead",
            PRINTER_PAUSED: "Printer paused",
            ERRORE_SCONOSCIUTO: "Unknown error. Check that the Zebra printer is powered on and properly connected."
        },
        CETTIRE: {
            ALMENO_UNA_RIGA_DA_CONFERMARE: "To print, at least one item must be confirmed for the selected warehouse.",
            NON_TUTTI_CONFERMATI_PER_WH: "Not all items are fully confirmed for the selected warehouse. Do you still want to proceed with printing?"
        }
    },
    PAGE_RESERVATIONS: {
        AGGIUNGI_RISERVA: "Add reservation",
        ELIMINA_RISERVE: "Delete reservations",
        SCARICA_EXCEL: "Download Excel",
        ARTICOLI: "__COUNT__ items",
        SELEZIONARE_SHOP_SKU_PARENT: "Select shop and parent SKU",
        TABLE: {
            ARTICOLO: "Item",
            PREZZO: "Price",
            MAGAZZINO: "Warehouse",
            SHOPS: "Shops",
            QTY: "Qty",
            DATA_CREAZIONE: "Creation date",
            DATA_MODIFICA: "Modification date",
            QTY_RENDER: "__QTY__ of __WH_QTY__",
            SHOP_TOOLTIP: "Click to go to the shop page __SHOP_NAME__, alt+click to filter reservations for this shop",
            WH_TOOLTIP: "Press alt+click to filter reservations for this warehouse"
        },
        SHOP_SKU_PARENT_VALIDI: "The selected shops and parent SKU are valid.",
        VALIDAZIONE_IN_CORSO: "Validation in progress...",
        NEW_RESERVATION_CHECK_ERRORS: {
            SHOP_NON_IN_BUCKETS: "The following shops do not belong to any of the buckets available to the user:",
            ARTICOLO_NON_TROVATO: "Item not found.",
            SKU_CON_BUCKET_MANCANTI: "The following SKUs are not available in some of the buckets used by the selected shops:",
            SKU_CON_SHOP_ESCLUSI: "The following SKUs have at least one of the selected shops among the excluded listings:",
            WHS_NON_DISPONIBILI: "No warehouses are available for the selected shops."
        },
        NEW_RESERVATION_RESULTS: {
            QUANTITA: "Requested quantity",
            ESEGUITI: "Executed reservation operations",
            SCARTATI: "Discarded reservation operations",
            IN_ERRORE: "Reservation operations in error"
        },
        SELEZIONA_MASSIMA_QUANTITA_DISPONIBILE: "Reserve the maximum available quantity in the warehouse",
        ATTIVI_SE_SHOP_SKU_PARENT_VALIDATI: "Activated once the shops and parent SKU have been selected and validated for the new reservation.",
        SELEZIONARE_MAGAZZINO_E_QUANTITA: "Select a warehouse and define the quantity to reserve.",
        SELEZIONARE_MAGAZZINO_MAX_QTY: "Select a warehouse; the maximum available quantity in it will be reserved."
    },
    PAGE_SHOPS: {
        FILTRA_SUCCESSO: "Show confirmed shops",
        FILTRA_ERRORE: "Show shops with generic error",
        FILTRA_ERRORE_AUTENTICAZIONE: "Show shops with authentication error",
        FILTRA_DISABILITATI: "Show disabled shops",
        FILTRA_IN_ELABORAZIONE: "Show shops in processing",
        NUOVO_SHOP: "New Shop",
        TIPO_SHOP: "Shop type",
        NOME_SHOP: "Shop name",
        TABLE: {
            NOME: "Name",
            TIPO: "Type",
            STATO: "Status",
            ABILITATO: "Enabled",
            DISABILITATO: "Disabled",
            CODICE: "Code",
            VAI_A_DETTAGLIO: "Go to details",
            COPIA_ID: "Copy ID",
            COPIA_MERCHANT_ID: "Copy Merchant ID",
            COPIA_TOKEN: "Copy Token",
            COPIA_BEARER: "Copy Bearer Token",
            ATTIVA: "Activate shop",
            DISATTIVA: "Deactivate shop",
            CONFERMA_DISATTIVAZIONE_SHOP: "Confirm deactivation of shop __SHOP__?",
            CONFERMA_ATTIVAZIONE_SHOP: "Confirm activation of shop __SHOP__?",
            SHOP_ATTIVATO: "Shop __SHOP__ successfully activated.",
            SHOP_DISATTIVATO: "Shop __SHOP__ successfully deactivated.",
            MIINTO_API: "Miinto API",
            COPIA_URL: "Copy URL",
            COPIA_USERNAME: "Copy username",
            COPIA_PASSWORD: "Copy password",
            COPIA_X_API_KEY: "Copy x_api_key"
        }
    },
    PAGE_SHOP: {
        MODIFICA_SHOP: "Edit shop",
        TIPO_SHOP: "Shop type",
        NOME: "Name",
        CUSTOMER_CODE: "Customer code",
        CONFIGURAZIONI_GENERALI: "General configurations",
        CONFIGURAZIONI_SPECIFICHE: "Specific configurations",
        ALTRE_CONFIGURAZIONI: "Other configurations",
        ENDPOINT_PER_SCARICO_CATALOGO: "Catalog download endpoint",
        CREDENZIALI: "Credentials",
        LISTINO: "Pricebook",
        LISTINI: "Pricebooks",
        LISTINO_PIENO: "Full pricebook",
        LISTINO_SCONTATO: "Discounted pricebook",
        ACCESS_KEY: "Access key",
        SECRET_KEY: "Secret key",
        BRAND_LABEL: "Brand label",
        SELLER_LABEL: "Seller label",
        SHIPPING_POINT: "Shipping point",
        GENERALE: "General",
        DEFAULT_CURRENCY: "Default currency",
        LISTING: "Listing",
        FLUSSI: "Streams",
        CONFIGURAZIONE_FLUSSI_E_LISTING: "Streams and listing configuration",
        STRATEGIA_MAGAZZINI: "Warehouse strategy",
        SOVRASCRIVI_CONFIGURAZIONE_ACCOUNT: "Override account configuration",
        STRATEGIA: "Strategy",
        CONFERMA_CREAZIONE: "Confirm shop creation?",
        CONFERMA_MODIFICA: "Confirm shop modifications?",
        CREAZIONE_AVVENUTA_CON_SUCCESSO: "Shop __NAME__ successfully created",
        MODIFICA_AVVENUTA_CON_SUCCESSO: "Shop __NAME__ successfully modified",
        TOKEN: "Token",
        ORDER_PRICE: "Order price",
        CREDENZIALI_SHOP_API: "Shop API Credentials",
        CONTROLLO_PREZZI_RICEZIONE_ORDINE: "Price check in order inbound",
        INBOUND_ORDER_PRICE_CHECK: "Check price during order inbound",
        INBOUND_ORDER_PRICE_CHECK_TOLERANCE_PERC: "Tolerance in price control",
        API_CREDENTIALS: "API Credentials",
        SFTP_CREDENTIALS: "SFTP Credentials",
        OTHER_CONFIGURATIONS: "Other Configurations",
        LANGUAGES: "Languages to be published on the shop",
        CAMPI_TAG: "Tag fields",
        PUBLISH_ONLY_ITEMS_WITH_QUANTITY: "Publish only items with quantity",
        OPZIONI_PER_VARIAZIONI: {
            TITLE: "Variations options",
            OPZIONI: "Options",
            DESCRIZIONI_IN_LINGUA: "Translated descriptions for options",
            NESSUNA_OPZIONE_SELEZIONATA: "No option selected",
            NESSUNA_LINGUA_SELEZIONATA: "No language selected",
        },
        CONTROLLO_ORDINI_PENDING: {
            TITLE: "Pending Orders Check",
            STATO_DI_ATTIVAZIONE: "Activation Status",
            TOLLERANZA: "Tolerance (in hours)",
            INVIO_EMAIL_CLIENTE: "Send Email to Customer",
            CONTROLLO_DISABILITATO_SE_INBOUND_ORDER_NON_ATTIVO: "Pending orders check cannot be enabled if order inbound is disabled."
        },
        _24S: {
            USERNAME: "Username",
            PASSWORD: "Password",
            LANGUAGES: "Select one or more languages"
        },
        CHANNEL: {
            ERRORE_SALVATAGGIO: "An error occurred while saving the following shops: __SHOPLIST__"
        },
        MIINTO: {
            IDENTIFIER: "Identifier",
            SECRET: "Secret",
            LANGUAGES: "Languages",
            PRICEBOOKS: "Price lists",
            TOKEN: "Token",
            URL: "URL",
            USERNAME: "Username",
            PASSWORD: "Password"
        },
        AMAZON: {
            AMAZON_EUROPE: "Amazon Europe",
            MARKETPLACE: "Marketplace",
            CLIENT_ID: "Client ID",
            MERCHANT_ID: "Merchant ID",
            CLIENT_SECRET: "Client Secret",
            REFRESH_TOKEN: "Refresh token",
            CONFERMA_RIMOZIONE_SHOP: "Confirm removal of marketplace __MARKETPLACE__?",
            SELEZIONARE_ALMENO_UN_MARKETPLACE: "Select at least one marketplace"
        },
        WINKELSTRAAT: {
            X_API_KEY: "x_api_key",
            TOKEN_CHANNABLE: "Token Channable",
            PROJECT_ID_CHANNABLE: "Project ID Channable",
            SKU_PREFIX: "SKU Prefix",
            WEBHOOKS_TITLE: "Webhooks",
            WEBHOOKS: {
                SHIPMENT: "Shipment",
                SHIPMENT_TRACK: "Shipment track",
                RMA_ITEMS: "RMA items"
            },
            URL: "URL",
            USERNAME: "Username",
            PASSWORD: "Password"
        },
        ITALIST: {
            PARTIAL_ORDER: "Enable partial order",
            API_KEY: "API key",
            STORE_KEY: "Store key"
        },
        CETTIRE: {
            USERNAME: "Username",
            PASSWORD: "Password",
            USER_ID: "User Id"
        },
        MIRAKL: {
            API_KEY: "API key",
            SHOP_ID: "Shop Id"
        },
        FARFETCH: {
            STORE_ID: "Store Id",
            SAFE_KEY: "Secret Key",
            GOT_TO_SHOP: "Shop details",
            MERCHANT_CODE: "Merchant Code",
            MERCHANT_CODE_EXIST: "Value already exists in another Farfetch",
            VPI_PORT_ERROR: "Port must be less than or equal to 65535",
            URL: "URL",
            USERNAME: "Username",
            PASSWORD: "Password",
            REST: {
                REST_API: "REST API",
                CONFIGURA_REST_API: "Configure REST API",
                CLIENT_ID: "Client ID",
                CLIENT_SECRET: "Client Secret",
                USERNAME: "Username",
                PASSWORD: "Password",
                PARTNER_ID: "Partner ID",
                USER_AGENT: "User agent",
                MERCHANT_UUID: "Merchant UUID"
            },
            VPI: {
                TITLE: "Vendor Provider Imagery",
                ENABLED: "Enable Vendor Provider Imagery",
                HOSTNAME: "Host name",
                PORT: "Port",
                USENAME: "Username",
                PASSWORD: "Password"
            },
            FXFF: {
                TITLE: "Fulfillment",
                ENABLED: "Enabled Fulfillment by Farfetch",
                LOGISTIC_CENTER: "Logistic Center",
                LOGISTIC_CENTER_IT: "Italy",
                LOGISTIC_CENTER_HK: "Hong Kong",
                LOGISTIC_CENTER_US: "United States"
            },
            SANDBOX: "Sandbox",
            B2B: "B2B",
            ROLE: "Role",
            TABLE_SEC: {
                TITLE: "List of connected secondary shops",
                NOME: "Shop name",
                CODICE: "Code",
                COPY_ID: "Copy Shop ID",
                SHOP_ID_COPY: "Shop id of __FF__ copy!",
                GOT_TO_SHOP: "Shop details"
            },
            PRIMARY_SHOP: "Primary Shop",
            BUSINESS_MODEL: "Business Model",
            DEFAULT: "Default",
            BID_ASK: "Bid / Ask",
            QUESTO_SHOP_PRIMARIO: "This is a primary shop.",
            QUESTO_SHOP_SECONDARIO: "This is a secondary shop.",
        },
        SHOPIFY: {
            DISCLAIMER_LINGUA: ()=><div><strong>Warning!</strong> The list of languages to be published on the shop, if populated, must necessarily include the default language for this shop on Shopify.</div>,
            CONFIGURAZIONE_METAFIELD_PRODOTTO: "Metafield - Product configuration",
            CAMPO_CLOUDSTORE: "CloudStore field",
            METAFIELD_SHOPIFY: "Shopify metafield",
            TIPO: "Type",
            NESSUNA_CONFIGURAZIONE: "No association defined.",
            NOTIFICA_CLIENTE_SPEDIZIONE_ORDINE: "Notify user when order is shipped",
            SHOP_NAME: "shop_name",
            TOKEN: "token",
        },
    },
    PAGE_WAREHOUSES: {
        NUOVO_MAGAZZINO: "New Warehouse",
        FILTRA_SYSTEM: "Show system-defined warehouses",
        FILTRA_USER: "Show user-defined warehouses",
        TABLE: {
            NOME: "Name",
            STATO: "Status",
            PRIORITA: "Priority",
            CODICE: "Code",
            DEFINITO_DA: "Defined by",
            VAI_A_DETTAGLIO: "Go to detail",
            COPIA_ID: "Copy ID",
            ELIMINA: "Delete Warehouse",
            CONFERMA_RIMOZIONE_MAGAZZINO: "Do you confirm the deletion of the warehouse __WAREHOUSE__?",
            RIMOZIONE_MAGAZZINO_SUCCESS: "The warehouse __WAREHOUSE__ has been successfully deleted",
            ADDRESS_LINE: "Address",
            CITY: "City",
            PROVINCE: "Province",
            PROVINCE_CODE: "Province Code",
            POSTAL_CODE: "Postal Code",
            COUNTRY_CODE: "Country Code",
            MODIFY: "Modify Warehouse",
        },
        OPERATION_SUCCESS: "Warehouse saved successfully",
        OPERATION_ERROR: "Error saving warehouse"
    },
    PAGE_CUSTOMERS:{
        CUSTOMER_DETAILS: "Customer Details",
        COPIA_ID: "Copy ID",
        NEW_CUSTOMER: "New Customer",
        CUSTOMER: "Customer",
        RETURN: "Return",
        LOADING: "loading...",
        NOT_FOUND: "Customer not found",
        ON_ERROR: "on error",
        CREATION_DATE: "Creation Date",
        ORDINI_ULTIMO_ANNO: "Last year orders",
        ORDERS_MADE: "Orders placed in the period",
        UPDATED_DATE: "Updated Date",
        ORDER_DATE: "Order date",
        SHIPPING_ADDRESSES: "Shipping addresses",
        ADD_NEW_ADDRESS: "Add new address",
        MODIFY_ADDRESS: "Modify shipping address",
        SHIPPING_ADDRESSES_DEFAULT: "Default address",
        NETSUITE_INBOUND_TOOLTIP: "The NetSuite inbound customer is active, so most properties of this user cannot be modified.",
        ERRORE_NOMINATIVO: "Company name or first and last name must be present",
        ERRORE_TELEFONO: "Incorrect format (e.g., correct: +39123456789)",
        USA_COME_SPEDIZIONE: "Also use as shipping address",
        COPIA_DA_FATTURAZIONE: "Copy from billing",
        VAI_A_SCHEDA_CLIENTE: "Go to customer record",
        RESTA_NELLA_PAGINA: "Stay on the page",
        TABS:{
            INFORMAZIONI_GENERALI: {
                TITLE: "General Information",
                ANAGRAFICA: "Personal Information",
                CONTATTI: "Contacts",
                NOME: "First Name",
                COGNOME: "Last Name",
                RAGIONE_SOCIALE: "Company Name",
                CODICE_CLIENTE: "Customer Code",
                LINGUA: "Language",
                EMAIL: "Email",
                TELEFONO: "Phone",
                CELLULARE: "Mobile",
                PEC: "Certified Email"
            },
            FATTURAZIONE: {
                TITLE: "Billing",
                CODICI: "Codes",
                INDIRIZZO: "Address",
                PARTITA_IVA: "VAT Number",
                CODICE_FISCALE: "Tax Code",
                SDI: "SDI",
                NAZIONE: "Country",
                INDIRIZZO_1: "Address 1",
                INDIRIZZO_2: "Address 2",
                CITTA: "City",
                PROVINCIA: "Province",
                SIGLA_PROVINCIA: "Province Code",
                CODICE_POSTALE: "Postal Code"
            },
            SPEDIZIONE: {
                TITLE: "Shipping",
                INDIRIZZI_DI_SPEDIZIONE: "Shipping Addresses",
                NESSUN_INDIRIZZO_PRESENTE: "No shipping addresses are defined for this customer at the moment.",
                INFORMAZIONI_GENERALI: "General Information",
                INDIRIZZO: "Address",
                CONTATTI: "Contacts",
                AGGIUNGI_INDIRIZZO: "Add Address",
                MODIFICA_INDIRIZZO: "Edit Address",
                ALIAS: "Alias",
                NOME: "First Name",
                COGNOME: "Last Name",
                RAGIONE_SOCIALE: "Company Name",
                NAZIONE: "Country",
                INDIRIZZO_1: "Address 1",
                INDIRIZZO_2: "Address 2",
                CITTA: "City",
                PROVINCIA: "Province",
                SIGLA_PROVINCIA: "Province Code",
                CODICE_POSTALE: "Postal Code",
                EMAIL: "Email",
                TELEFONO: "Phone",
                CELLULARE: "Mobile",
            },
            CONDIZIONI_COMMERCIALI: {
                TITLE: "Commercial Conditions",
                GESTIONE_PREZZI: "Price Management Model",
                CONDIZIONE_COMMERCIALE_ATTIVA: "Active Commercial Condition",
                STORICO: "History of Associated Commercial Conditions",
                NESSUNA_CONDIZIONE_COMMERCIALE_ATTIVA: "No active commercial condition for this customer.",
                NESSUNO_STORICO: "No data available.",
                ULTIMA_MODIFICA: "Last Modification",
                STATO: "Status",
                NUMERO_ECCEZIONI: "Number of Exceptions",
                PLACEHOLDER_CONDIZIONE_COMMERCIALE: (date: string, variant?: Variant, label?: string) => <div className='d-flex align-items-center justify-content-start'>
                    <span className='me-2'>Association Date:</span>
                    <span className='fw-bold me-2'>{date}</span>
                    <span className='me-2'>Current Association Status:</span>
                    <span className={`fw-bold text-${variant}`}>{label}</span>
                </div>,
                GESTIONE_PREZZI_INFO: (variation: string) => <div>If this information is not specified, the default price management model for the account <span className='fw-bold'>({variation})</span> will be applied.</div>
            },
            ORDINI: {
                TITLE: "Orders",
                CONFIGURAZIONE_CONTROLLO_ORDINI_PENDING: "Check PENDING order configuration",
                TOLLERANZA: "Tolerance",
            },
            BECOMMERCE: {
                TITLE: "BeCommerce",
                METODI_DI_PAGAMENTO_ABILITATI: "Enabled Payment Methods",
                METODI_DI_SPEDIZIONE_ABILITATI: "Enabled Shipping Methods",
                BANK_TRANSFER: "Bank Transfer",
                PAYPAL: "PayPal",
                CREDIT_CARD: "Credit Card",
                CUSTOMER: "Customer Courier",
                DHL_EXPRESS: "DHL Express"
            },
            ACCOUNT: {
                TITLE: "Account",
                DESCRIPTION: () => <div>Actions performed in this section are applied <span className='fw-bold'>immediately</span>, without needing to save using the button in the top right.</div>,
                MODIFICA_UTENTE: "Edit Username",
                USERNAME: "Username",
                RESET_PASSWORD: "Reset Password",
                STATO_CLIENTE: "Customer Status",
                ATTIVA_CLIENTE: "Activate",
                DISATTIVA_CLIENTE: "Deactivate",
                CONFERMA_USERNAME: (username: string) => <><div>You are about to change the username of this customer to</div><div className='fw-bold my-2'>{username}</div><div>Are you sure you want to proceed?</div></>,
                USERNAME_GIA_IN_USO: "This username is already in use by another customer.",
                CONFERMA_ATTIVAZIONE: "Are you sure you want to activate this customer?",
                CONFERMA_DISATTIVAZIONE: "Are you sure you want to deactivate this customer?",
                INVIA_RICHIESTA: "Send Request",
                CONFERMA_RESET: "Are you sure you want to reset the password for this customer?"
            }
        },
        STATUS :{
            ACTIVE:"Active",
            INACTIVE: "Inactive",
        },
        TABLE:{
            USERNAME:" User Name",
            NOMINATIVO: "Nominee",
            CODE :"Username / Customer Code",
            COUNTRY:"Country",
            STATUS:"Status",
            EMAIL:"Email",
            EMAILPEC:"Email pec",
            PHONE:"Phone Number",
            CELL:"Cell Number",
            ANAGRAFICA_CLIENTE: "Customer Record",
            BILLING_ADDRESS: "Billing Address",
            SHIPPING_ADDRESS: "Shipping Address",
            CF: "Tax Code",
            SDI: "SDI Code",
            VAT: "VAT Code",
            ADDRESS_LINE: "Address",
            CITY: "City",
            VAI_A_DETTAGLIO: "Go to detail",
            PROVINCE: "Province",
            PROVINCE_CODE: "Province Code",
            POSTAL_CODE: "Postal Code",
            COUNTRY_CODE: "Country",
            SWITCH_DESC: "The billing address matches the shipping address"
        },
        CONTACT_NAME:"Contact name",
        OPERATION_SUCCESS: "Customer saved successfully",
        OPERATION_ERROR: "Error saving customer",
        ATLEAST_1_STATUS:"At least one status must be selected",
        RESET_PWD_SUCCESS:"Password reset successful",
        CHANGE_STATUS_SUCCESS:"Customer status change successful",
        CONFIRM_DELETE_ADDRESS:"Delete address?",
        ADD_CUSTOMER_ADDRESS:"Add customer address",
        CUSTOMER_ADDRESS_LIST:"Customer's shipping address list"
    },
    PAGE_WHS_MOVEMENTS: {
        FILTRO_AVANZATO: "Advanced Filter",
        NEW_MOVEMENTS: "New movements",
        SCARICA_MOVIMENTI:"Download template",
        CARICA_MOVIMENTI:"Update movement",
        CREATION_PROGRESS:"Movement creation in progress",
        CREATION_SUCCESS:"Movement creation in progress",
        CREATION_ERROR:"Error creating movement",
        TABLE: {
            DATE: "Date",
            ORDER: "Order",
            BRAND: "Brand",
            QUANTITY: "Quantity",
            TYPE: "Tipo",
            WAREHOUSE: " Warehouse",
            TAGS: "Tags",
            COPIA_ID: "Copy ID",
            COPIA_ID_ORDER: "Copy Order ID",
            TYPE_ABSOLUTE:"Absolute",
            TYPE_DIFFERENTIAL:"Differential",
            SKU: "SKU",
        },
        ADD_WHR_MOVEMENTS:{
            TITLE: "New warehouse movements",
            SKU: "SKU",
            SKU_PLACEHOLDER: "insert SKU",
            WAREHOUSE :"Warehouse",
            WAREHOISE_PLACEHOLDER: "Select Warehouse",
            TYPE_PLACEHOLDER: "Select Type",
        }, 
        DOWNLOAD_MODAL_TEXT: {
            TITLE:"Instructions",
            DESCRIPTION: "By clicking the button below, you will download the CSV template for bulk stock movement upload.",
            COLUMNS: "The file consists of the following columns:",
            SKU: "represents the item's SKU. Mandatory.",
            WH_ID: "CloudStore warehouse identifier. Optional.",
            WH_ID_INFO: "Ignored if WH_ID is provided.",
            WH_CODE:"warehouse customer code.",
            WH_CODE_2: "Ignored if WH_ID is entered.",
            QTY: "quantity moved. Can be negative or positive. Mandatory.",
            QTY_2: "If TYPE = 'A', then the value must be positive.",
            TYPE: "movement type: A = Absolute, D = Differential. Mandatory.",
            TAGS: "list of values separated by ','. Used to assign additional labels to the movement. Optional.",
            ALERT: "One of the two values between WHI_ID and WH_CODE must be provided.",
            OPTIONAL: "Optional",
            MANDATORY: "Mandatory",
            ATTENTION:"Attention"
        },
        UPLOAD_MODAL:{
            TITLE:"Upload new warehouses movements",
            UPLOAD_SUCCESS:"Upload success"
        }
    },
    PAGE_SHIPMENTS :{
        ORDINI: "Orders",
        NESSUN_ORDINE_PRESENTE: "No order found.",
        SHIPMENT :"Shipment",
        NUOVA_SPEDIZIONE: "New Shipment",
        FILTRO_AVANZATO: "Advanced Filter",
        VAI_A_DETTAGLIO: "Goto details",
        APRI_ANTEPRIMA: "Show preview",
        COPIA_ID: "Copy ID",
        FILTERS: {
            ORDER_TYPE: "Order type",
            ORFRT: "Order N°"
        },
        TABLE: {
            ORDER: "Order",
            CARRIER: "Carrier",
            STATUS: "Status",
            TRACKING_NUMBER: "N° Tracking",
            SHIPPING_DATE: "Shipping Date",
            DELIVERY_DATE: "Delivery Date",
            DELIVERY_METHOD: "Delivery Method",
            VAI_A_DETTAGLIO: "Go to detail",
            COPIA_ID: "Copy ID",
            ORDER_POPUP_TITLE: "Orders ID",
            TRACKING_URL: "Tracking URL",
        },
        PREVIEW: {
            RECIPIENT_INFO: "Recipient Information",
            NAME : "Name",
            SURNAME : "Surname",
            SHIPMENT_INFO: "Shipment Information",
            COMPANY: "Company",
            BUYER: "Buyer",
            ADDRESS: "Address",
        }
    },
    PAGE_CATALOG: {
        NUOVO_ARTICOLO: "New Item",
        FILTRO_AVANZATO: "Advanced Filter",
        INSERIRE_TRE_CARATTERI_PER_CERCARE: "Enter at least three characters to search",
        SKU: "SKU",
        BARCODE: "Barcode",
        TITOLO: "Title",
        BRAND: "Brand",
        CATEGORIA: "Category",
        DISPONIBILITA: "Availability",
        FILTERS: {
            BRAND: "Brand",
            CATEGORIE: "Categories",
            STAGIONI: "Seasons",
            CANALE_DI_VENDITA: "Sales Channel",
            QUANTITY: "Quantity",
            SELEZIONA_LE_CATEGORIE: "Select categories...",
            CATEGORIE_SELEZIONATE: "Selected categories"
        }
    },
    PAGE_ITEM: {
        DETTAGLIO_ARTICOLO: (sku_parent:string)=>`Details of item ${sku_parent}`,
        ARTICOLO_NON_TROVATO: (sku_parent:string, createFunction: Function)=><>
            <div className="fs-5">No item with code <span className="fw-bold">{sku_parent}</span> has been found.</div>
            <div className="fs-6 mt-2">Do you want to create one? <span className="cs-link" onClick={()=>createFunction()}>Click here!</span></div>
        </>,
        NUOVO_ARTICOLO: "Add a new item of this product",
        ARTICOLO_CORRENTE: "Current item",
        RIPARA_ERRORE: "Fix error",
        RIPARA_PROPRIETA: (label:string)=><div className="d-flex align-items-center justify-content-start"><span className="me-2">Fix property</span><span className="fw-bold">{label}</span></div>,
        SCEGLI_VALORE_VARIAZIONE: (label: string)=><span>Choose one of the following existing values and use it for variations that lack the property <span className="fw-bold">{label}</span>:</span>,
        VARIAZIONI_AGGIORNATE_SUCCESSO: (count: number)=>(count>1)?<span><span className="fw-bold">{count}</span> variations of this item have been updated successfully.</span>:<span><span className="fw-bold">One</span> variation of this item has been updated successfully.</span>,
        ERRORI_AGGIORNAMENTO: "The following errors have occurred",
        GESTISCI_TRADUZIONI: "Manage translations",
        TRADUCI_DA: (source:string) => <span>Automatically translate from: <span className="fw-bold">{source}</span></span>,
        TRADUZIONE_NON_DISPONIBILE: "Translation not available",
        SCELTA_TRADUZIONE_DESC: "Select the default language to display the item's language-specific properties.",
        AGGIUNGI_TRADUZIONE: "Add translation",
        TABS: {
            PANORAMICA: {
                TITLE: "Overview",
                DATA_ULTIMA_MODIFICA: "Last update date",
                CI_SONO_VARIAZIONI: (qty: number, onClick: Function)=>(qty===1)?<div>There is <span className="fw-bold cs-link" onClick={()=>onClick()}>one variation</span> of this item.</div>:<div>There are <span className="fw-bold cs-link" onClick={()=>onClick()}>{qty} variations</span> of this item.</div>,
            },
            DATI: {
                TITLE: "Data",
                GENERALI: "General",
                PERSONALIZZATI: "Custom",
            },
            IMMAGINI: {
                TITLE: "Images",
                COLLOCAMENTO: "Placement",
                POSIZIONE: "Position",
                SHOP_ESCLUSI: "Excluded shops",
                NESSUNO_SHOP_ESCLUSO: "No shop excluded",
                URL_MANCANTE: "The image URL is not available.",
                PRESENZA_IMMAGINE_VARIAZIONI_KO: (needed:number, actual: number)=><span>This image is available in <span className="fw-bold">{actual}</span> of <span className="fw-bold">{needed}</span> variations.</span>,
                SELEZIONA_IMMAGINE_PER_DETTAGLI: "Select an image on the left to view its details.",
                PERCORSO_IMMAGINE: "Image path",
                TUTTE_LE_VARIAZIONI: "Apply to all item variations.",
                VARIAZIONE: (sku: string)=>`Apply to variation ${sku}`,
                AGGIUNGI_IMMAGINE: "Add an image",
                ERRORE_VALORE_POS: "Position must be equals or greater than 1.",
                ERRORE_POS_DUPLICATA: "One of the selected variations already has an image in this position.",
                CONFERMA_ELIMINAZIONE: "Do you confirm the deletion of the selected image?",
            },
            AB: {
                TITLE: "Availability Buckets",
                AGGIUNGI_AB: "Add an Availability bucket",
                QUANTITA: "Quantity",
                PREZZI: "Prices",
                GENERALE: "General",
                SHOP_SPENTO: "This shop is offline",
                PREZZO: "Price",
            },
            VARIAZIONI: {
                TITLE: "Variations",
                SELEZIONA_VARIAZIONE: "Select a variation",
            },
            ARTICOLI_COLLEGATI: {
                TITLE: "Linked items",
                AGGIUNGI_COLLEGAMENTO: "Add a link to an item",
                AGGIUNGI_COLLEGAMENTO_INFO: "In this section, you can link the selected variation to one or more different items.",
                TIPO_COLLEGAMENTO: "Link type",
                GIFT: "Gift",
                RELATED: "Related",
                QUANTITA: "Quantity",
                SHOP_ESCLUSI: "Excluded shops",
                ARTICOLO_NON_TROVATO: "Variation not found",
                ERRORE_ARTICOLO_UGUALE: "It is not possible to link a variation of an item to itself.",
                ERRORE_ARTICOLO_GIA_COLLEGATO: "The selected item variation is already linked.",
                VARIAZIONE: "Variation",
                SCOLLEGA: "Unlink item",
                VAI_A_ARTICOLO: "Go to item",
                CONFERMA_SCOLLEGAMENTO: "Do you confirm the deletion of the link with the selected variation?",
            },
            MAGAZZINI: {
                TITLE: "Warehouses",
                MAGAZZINO: "Warehouse",
                DISPONIBILITA: "Availability",
                QUANTITA_RISERVATE: "Reserved quantities",
            },
            MOVIMENTI_MAGAZZINO: {
                TITLE: "Warehouse movements",
                TABLE: {
                    DATE: "Date",
                    QUANTITA: "Quantity",
                    TIPO: "Type",
                    MAGAZZINO: "Warehouse",
                    TAGS: "Tags",
                },
                ASSOLUTO: "Absolute",
                DIFFERENZIALE: "Differential",
            },
            AGGIORNAMENTI_DISPONIBILITA: {
                TITLE: "Availability adjustments",
            },
            LISTINI: {
                TITLE: "Pricebooks",
                NOME_LISTINO: "Pricebook name",
                VARIAZIONI: "variations",
                TUTTE_LE_VARIAZIONI: "All variations",
                PREZZO: "Price",
            },
            CONDIZIONI_COMMERCIALI: {
                TITLE: "Business conditions",
                NOME_CONDIZIONE_COMMERCIALE: "Business condition name",
                VARIAZIONI: "Variations",
                TUTTE_LE_VARIAZIONI: "All variations",
                PREZZO: "Price",
            },
            CODICI_SHOP: {
                TITLE: "Shop codes",
                TIPO_SHOP: "Shop type",
                NOME_SHOP: "Shop name",
                CODICE_SHOP: "Shop code",
                CODICE_ARTICOLO_SHOP: "Item code in the shop"
            }
        },
        ANALYSIS: {
            NESSUN_PROBLEMA_RILEVATO: "No issues detected.",
            ERRORS: {
                GENERIC: (entry: PropertyAnalysis)=><div><span className="fw-bold">{entry.field}</span> has an issue.</div>,
                TOTALLY_MISSING: (entry:PropertyAnalysis)=><div><span className="fw-bold">{entry.field}</span> missing in all the variations of the item.</div>,
                PARTIALLY_MISSING: (entry:PropertyAnalysis)=><div><span className="fw-bold">{entry.field}</span> missing in some of the variations of the item.</div>,
                PROPERTY_DIFFERENT: (entry:PropertyAnalysis)=><div><span className="fw-bold">{entry.field}</span> some variations of the item have different values from the main one: <span className="fw-bold">{((entry.additional_info?.less_frequent_values ?? []) as string[]).join(', ')}</span>.</div>,
                PROPERTY_NOT_DISTINCT: (entry:PropertyAnalysis)=><div><span className="fw-bold">{entry.field}:</span> some variations of the item share the following values: <span className="fw-bold">{((entry.additional_info?.more_frequent_values ?? []) as string[]).join(', ')}</span>.</div>,
                ABS_DIFFERENT: (entry:PropertyAnalysis)=><div><span className="fw-bold">Availability Buckets:</span> different among the variations of the item.</div>,
                ABS_PRICE_DIFFERENT: (entry:PropertyAnalysis)=><div><span className="fw-bold">Availability Buckets:</span> different price for the variation <span className="fw-bold">{entry.additional_info?.sku! as string} ({`${entry.additional_info?.price? `${entry.additional_info?.price} €`:`not defined`}`})</span></div>,
            }
        },
        FIELD_NAMES: {
            SKU: "SKU",
            SKU_PARENT: "SKU Parent",
            MNF_CODE: "MNF Code",
            SEASON: "Season",
            BRAND: "Brand",
            TITLE: "Title",
            DESC: "Description",
        }
    },
    PAGE_BINDINGS: {
        SELEZIONARE_UNO_SHOP: "Select a shop to get started",
        ATTRIBUTI_COMPLETAMENTE_ASSOCIATI: "Fully bound attributes",
        SELEZIONARE_UN_ATTRIBUTO: "Select an attribute from the list",
        NUOVO_ATTRIBUTO: "Add a new attribute",
        NUOVA_REGOLA: "Add a new rule",
        REGOLE_PER_ATTRIBUTO: "Rules for the attribute",
        FILTRA_ASSOCIAZIONI_ATTIVE: "View already active bindings",
        INDOVINA_ASSOCIAZIONI: "Auto-fill bindings by attempting to deduce the shop values based on CloudStore values",
        RIPRISTINA_ASSOCIAZIONI: "Undo all current changes and restore bindings to their initial state",
        AGGIUNGI_ASSOCIAZIONE: "Add a binding by entering a CloudStore value that isn't present",
        SE_SI_VERIFICANO_CONDIZIONI: "If the following conditions are met",
        LE_ASSOCIAZIONI_SONO_LE_SEGUENTI: "The bindings are as follows",
        REGOLA_DEFAULT_NESSUNA_CONDIZIONE: "This is the default rule. No conditions can be added, bindings will always be active if no other rules are valid.",
        NESSUNA_CONDIZIONE: "No condition defined. Bindings are always valid.",
        BINDINGS_TABLE: {
            VALORE_CS: "CloudStore Value",
            VALORE_SHOP: "Shop Value"
        },
        CONFERMA_GUESS_BINDINGS: "The system will attempt to automatically fill in the shop values that are not yet bound based on the corresponding CloudStore values. Do you want to proceed?",
        CONFERMA_RIPRISTINO_BINDINGS: "If you proceed, all unsaved changes to the bindings will be lost."
    },
    PAGE_ORDERS: {
        SCARICA_ORDINI: "Download Orders",
        ORDINE: "Order",
        STATO_ORDINE: "Order status",
        DATA: "Date",
        CONTROVALORE: "Total value",
        NUMERO_ARTICOLI: "Items quantity",
        APRI_ANTEPRIMA: "Show preview",
        VAI_A_DETTAGLIO: "Go to details",
        COPIA_ID: "Copy ID",
        COPIA_CODICE_ORDINE: "Copy order code",
        INFORMAZIONI_ACQUIRENTE: "Buyer info",
        SPEDIZIONE: "Shipping",
        FATTURAZIONE: "Billing",
        ORDINI_TROVATI: "__COUNT__ orders found",
        STATUS_CHANGE: "Change order status",
        NESSUN_DOCUMENTO_PRESENTE: "No document available",
        NESSUN_CUSTOMER_PRESENTE: "No customer available",
        NESSUNA_SPEDIZIONE_PRESENTE: "No shipment available",
    },
    PAGE_AVAILABILITY_ADJUSTMENTS:{
        FILTERS: {
            MANDATORY_FILTERS: "You need to select a shop and an item to perform a search.",
        },
        TABLE:{
            DATE : "Date",
            SHOP : "Shop",
            QUANTITY: "Quantity",
            REASON: "Reason",
            STATE:"State",
            MESSAGE: "Message",
            COMPLETED :"Completed",
            INCOMPLETED: "Incompleted",
            LISTING: "Listing",
            MOVEMENT: "Movement" 
        }
    },
    PAGE_ORDER: {
        ORDINE: "Order",
        DETTAGLIO_ORDINE: "Order details",
        ULTIMO_AGGIORNAMENTO: "Last update",
        NESSUN_PROBLEMA_RISCONTRATO: "This order has no problems.",
        PROBLEMI_RISCONTRATI: "There are some problems with this order.",
        IN_CARICAMENTO: "loading...",
        NON_TROVATO: "not found",
        IN_ERRORE: "in error",
        ORDINE_NON_TROVATO: "The order searched was not found.",
        ORDINE_IN_ERRORE: "There was an error in loading the order, please try again.",
        PANORAMICA: "Overview",
        ARTICOLI: "Items",
        INFORMAZIONI: "Informations",
        MOVIMENTAZIONI: "Movements",
        DOCUMENTI: "Documents",
        NETSUITE: "NetSuite",
        METADATA: "Metadata",
        DATA: "Date",
        SKU: "SKU",
        ARTICOLO: "Item",
        BRAND: "Brand",
        QUANTITA: "Quantity",
        MODIFICA_QUANTITA: "Change quantity",
        PREZZO_UNITARIO: "Unit Price",
        PREZZO_TOTALE: "Total Price",
        SUBTOTALE: "Subtotal",
        TOTALE_ORDINE: "Order Total",
        SPESE_DI_SPEDIZIONE: "Shipping Costs",
        SCONTO: "Discount",
        MARKUP: "Markup",
        TIPO: "Type",
        MAGAZZINO: "Warehouse",
        TAGS: "Tags",
        ASSOLUTO: "Absolute",
        DIFFERENZIALE: "Differential",
        MOVIMENTI_MAGAZZINO: "Warehouse Movements",
        COPIA_ID_MAGAZZINO: "Copy Warehouse ID",
        ID_MAGAZZINO_COPIATO: "Warehouse ID __WH__ copied!",
        UTENTE: "User",
        MODULO: "Module",
        SHOP: "Shop",
        AGGIORNAMENTI_DISPONIBILITA: "Availability Updates",
        STATO: "Status",
        MESSAGGIO: "Message",
        NUOVO_DOCUMENTO: "New Document",
        DOCUMENTI_DI_TIPO: "Documents of Type",
        ELIMINA_DOCUMENTO: "Delete Document",
        STAMPA_DOCUMENTI_SELEZIONATI: "Print Selected Documents",
        SELEZIONA_PER_LA_STAMPA: "Right-click to select, left-click to remove",
        TIPO_DOCUMENTO: "Document Type",
        NESSUN_DOCUMENTO_DISPONIBILE: "No document available for this order.",
        ELIMINA_DOCUMENTO_CONFERMA: "Are you sure you want to delete the document?",
        DOCUMENTO_ELIMINATO: "Document successfully deleted",
        CARICAMENTO_IN_CORSO: "Loading...",
        NOME: "Name",
        EMAIL: "Email",
        ID: "ID",
        CHANGE_ORDER_SATUS:"Enter the new order status",
        CANCEL:"Cancel",
        CHANGE_STATUS:"Change status",
        CHANGE_STATUS_CONFIRM_MESSAGE: (status:string)=><div>Do you want to proceed with the status change to <span className="fw-bold">{status}</span>?</div>,
        CHANGE_STATUS_SUCCESS:()=><><div>Request successfully sent.</div><div>It may take a few seconds for the status change operation to be completed, so we ask you to check the result shortly.</div></>,
        SHIPPING_INFO: {
            NOME_AZIENDA: "Company Name",
            NOME: "First Name",
            COGNOME: "Last Name",
            CONTATTO_1: "Contact 1",
            CONTATTO_2: "Contact 2",
            VIA: "Street / Square / Other",
            NUMERO: "No.",
            CITTA: "City",
            CAP: "Postal Code",
            PROVINCIA: "Province",
            CODICE_PROVINCIA: "Province code",
            NAZIONE: "Country"
        },
        BILLING_INFO: {
            NOME_AZIENDA: "Company Name",
            NOME: "First Name",
            COGNOME: "Last Name",
            PARTITA_IVA: "VAT Number",
            CODICE_FISCALE: "Tax Code",
            PIVA: "VAT",
            CF: "Tax Code",
            CONTATTO_1: "Contact 1",
            CONTATTO_2: "Contact 2",
            VIA: "Street / Square / Other",
            NUMERO: "No.",
            CITTA: "City",
            CAP: "Postal Code",
            PROVINCIA: "Province",
            CODICE_PROVINCIA: "Province code",
            NAZIONE: "Country",
            ID_FATTURA: "Invoice ID",
            NUMERO_FATTURA: "Invoice Number",
            DATA_FATTURA: "Invoice Date",
            TOTALE_FATTURA: "Invoice Total",
            URL: "Url",
            METODO_PAGAMENTO: "Payment Method"
        },
        TAB_NETSUITE: {
            ID_ELEMENTO: "Item ID",
            CODICE_ESTERNO: "External Code",
            DATA_CREAZIONE: "Creation Date",
            DATA_ULTIMO_AGGIORNAMENTO: "Last Update Date",
            STATO: "Status",
            NUMERO_ESECUZIONI: "Number of Executions",
            NOME_SHOP: "Shop Name",
            ESEGUI_ELEMENTO: "Execute Item",
            INVIA_ORDINE: "Send Order to NetSuite",
            INVIA_ORDINE_CONFERMA: "Are you sure you want to send the order to NetSuite?",
            INVIA_ORDINE_ESEGUITO: "The order has been queued for sending to NetSuite.",
            INVIA_ORDINE_NON_ESEGUITO_GIA_ESECUZIONE: "The order is already queued for sending to NetSuite."
        },
        TAB_METADATA: {
            NESSUN_METADATO_DISPONIBILE: "No metadata available.",
            FINANCE_MODULE_DATA: "Finance Module Data",
            TAXES_SOURCE: "Tax Source",
            CALCOLATE_DALLO_SHOP: "Calculated by the shop",
            CALCOLATE_DA_CLOUDSTORE: "Calculated by CloudStore",
            TASSAZIONE_SPESE_DI_SPEDIZIONE: "Shipping Charges Taxation",
            TASSAZIONE_ARTICOLI: "Item Taxation",
            OPZIONI_DI_PROCESSAMENTO: "Processing Options",
            ORDINE_PARZIALE: "Partial Order",
            MAGAZZINI_SELEZIONATI_PER_SCARICO: "Warehouses Selected for Unloading",
            CONTROLLO_PREZZI_INBOUND_ORDER: "Inbound Order Price Check",
            PRICE_WARNING: "Price Warning",
            UNIT_PRICE_WARNING: "Unit Price Tax Excluded Warning",
            TOTAL_PRICE_WARNING: "Total Price Tax Excluded Warning",
            RIUSCITO: "Successful",
            NON_RIUSCITO: "Unsuccessful",
            ACCETTATO_DA_CLOUDSTORE: "Accepted by CloudStore",
            NON_ACCETTATO_DA_CLOUDSTORE: "Not Accepted by CloudStore",
            CONTROLLO_DISPONIBILITA_EFFETTIVA_ARTICOLI: "Actual Item Availability Check",
            ESITO: "Outcome",
            MESSAGGIO_DI_ERRORE: "Error Message"
        },
        SALVATAGGIO_INFORMAZIONI_SPEDIZIONE_COMPLETATO: "Shipping information saved successfully!",
        SALVATAGGIO_INFORMAZIONI_FATTURAZIONE_COMPLETATO: "Billing information saved successfully!",
        INFORMAZIONI_AGGIUNTIVE: "Additional Information",
        NESSUNA_INFORMAZIONE_AGGIUNTIVA: "No additional information for this order.",
        DETTAGLI_ULTIMO_INVIO_A_NETSUITE: "Details of the last send to NetSuite",
        NESSUN_INVIO_A_NETSUITE: "No send to NetSuite available for this order.",
        NESSUN_ARTICOLO_PRESENTE: "No items in the order.",
        ARTICOLO_NON_PRESENTE: "Item not found in inventory.",
        APPLICA_A_SKU_STESSO_PARENT: "Apply to all variations of the same item in the order",
    },
    PAGE_CATEGORY :{
        CATEGORY:"Category",
        TABS:{
            SUBCATEGORY:{
                TITLE:"Subcategory",
                SUBCATEGORY_OF:"Subcategory list of",
                SUBCATEGORY_NEW:"New SubCategory",
            },
            SHOPS:{
                TITLE:"Shops associations",
                NO_CAT_SHOP:"No mapping was found for the category",
                DELETE_CAT_TITLE:"Do you want to delete the selected category?",
                SELECT_CAT:"Select category",
                TITLE_CAT_SHOP:"Add news category association",
                ADD_NEW_CAT_SHOP: "Add news category association",
                SELECTED_CAT:"Category",
            },
            TRANSLATIONS:{
                TITLE:"Translations",
                TRANSLATION: "Traslation",
                COLUMNS : {
                    LANG:"Lingua",
                    TRANSLATION:"Traduzioni"
                },
                NEW_TRANSLATION:"Add new translation",
                NEW_LANGUAGE:"Select language",
                NEW_LANGUAGE_TO_ADD:"New language to add",
                NEW_TRANSLATION_ADD_CONFERMATION:"Are you sure you want to add the new translations?",
                REMOVE_TRANSLATION_CONFIRMATION:"Are you sure you want to delete the translation?",
                ADD_NEW_CAT_TRANSLATION: "Press the button to add news translations",
            }
        },
        SELECT_CAT_FROM_LIST: "Search or select category from the search menu",
        SEARCH_CAT_TITILE:"Serch categories",
        SEARCH_PLACEHOLDER:"At least 3 characters",
        ADD_CATEGORY:{
            TITLE: 'Add new category',
            NEW_CAT:'New category name',
            SUCCESS:"New category added successfully",
        },
        UPDATE_SHOP_CATEGORY:{
            TITLE: 'Browse the categories of the selected shop',
            SELECTED_CATEGORY:'Selected category',
            SELECTED_CATEGORY_NEW : 'Select new category',
        },
        REMOVE_CATEGORY: "Remove Category",
        REMOVE_CATEGORY_TEXT: "Warning: You are about to remove the category __CATEGORY__. Proceeding with this operation will delete both the category and all connected subcategories.",
        REMOVE_CATEGORY_SUCCESS:"Category __CATEGORY__ removal successful",
    },
    PAGE_SHOPS_WAREHOUSES_BINDINGS: {
        MAGAZZINI_NON_SELEZIONATI: "The following shops have no warehouse selected",
        CONFERMA_SALVATAGGIO: ()=><><p>We are about to update the warehouse configuration; this operation involves recalculating the available quantities for the shop for which the warehouse configuration is being changed. The operation may take some time and can be monitored through long-running task queues.</p><p>At the same time, it is possible to create the bulk update procedure for quantities on the sales channel. It is preferable to <strong>NOT</strong> perform this operation if the sales channel has just been activated and does not yet have a published catalog.</p><p><strong>Do you still want to proceed with the sales channel synchronization?</strong></p></>,
        SALVA_SENZA_SINCRONIZZARE: "Save without syncing",
        SALVA_E_SINCRONIZZA: "Save and sync",
        SALVATAGGIO_AVVENUTO_CORRETTAMENTE: "Configuration saved successfully",
        EVIDENZIA_RIGA: "Highlight this row",
        EVIDENZIA_COLONNA: "Highlight this column",
        SELEZIONA_RIGA: "Select this row (alt+click)",
        SELEZIONA_COLONNA: "Select this column (alt+click)",
        NOTIFY_SAVE_ERRORS: "The following errors occurred while performing the operation",
        SALVATAGGIO_SHOP: "Shop saving",
        RICALCOLO_QUANTITA: "Recalculate quantities",
        AGGIORNAMENTO_CANALI: "Sales channel update",
        NOME_SHOP: "Shop name",
        STEP: "Step",
        ERRORE: "Error"
    },
    PAGE_WAREHOUSES_MOVEMENTS: {
        WARNING_MANDATORY_FILTERS: "Select the date filter and at least one between the SKU and warehouse filters."
    },
    PAGE_RETURNS:{
        DETAILS: "Return details",
        RETURN:"Return",
        LOADING: "loading...",
        NOT_FOUND: "Not found",
        ON_ERROR: "on error",
        INFO: "Return information",
        ORIGIN_ORDER: "Origin order",
        REASON:"Reason",
        RESOLUTION:"Resolution",
        ITEMS: "Items",
        TABLE:{
            QUANTITY:"Quantity",
            STATUS:"Status",
            DATE:"Date",
            CREATION_DATE:"Creation date",
            ID:"Identifier",
            RETURN:"Return",
            CUSTOMER_CODE: "Customer code",
            UPDATE_DATE:"Update date",
            VAI_A_DETTAGLIO: "Goto details",
        }
    },
    PAGE_LOGS:{
        TRACKING:{
            TRACKING:"Tracking",
            ELEMENT_CREATION:"Element Creation",
            ELEMENT_PROCESSING: "Element Processing"
        },
        SETTINGS:{
            NEW_SETTINGS:"Add new configuraztion",
            FREQUENCY:"Frequency",
            SEARCH_TERMS:"Search terms",
            NO_SERCH_TERMS:"No serch terms",
            GROUP:"Group",
            LEVELS:"Levels",
            EMAILS: "Emails",
            COMPONENT:"Component",
            INSERT_MAILS: "Emails address",
            MODIFY_CONFIG:"Modify configuration",
            DELETE_CONFIG:"Delete configuration",
            DELETE_CONFIRM_MESSAGE:"Proceed to delete the activity log configuration",
            DELETE_CONFIRM:"Configuration removal successful",
        },
        WAREHOUSES_DETAILS:"Warehouse details"
    },
    PAGE_QUEUES:{
        SEARCH_TERM_PLACEHOLDER:"Search queue",
        FAVORITES:"Favorite",
        CORE:"Core queues",
        MODULE:"Module queues",
        EXTERNAL:"External queues",
        SEARCH_RESULTS:"Search results",
        RERUN_QUEUE:"Rerun queues",
        QUEUE_ERROR:"The requested queue was not found.",
        QUEUE_NOT_FOUND:"An error occurred while loading the queue, please try again.",
        QUEUE_EMPTY_BOX:"No items found for the selected queue",
        QUEUE_EMPTY_EXECUTIONS_DETAILS:"No executions were found for the specific queue",
        PANORAMICA: "Panoramica",
        NO_FAVORITES1 : "No favorites selected. To add queues to favorites click on the ",
        NO_FAVORITES2 : "icon of the queue itself",
        REWORK_CONFERMATIONS:"Are you sure you want to rework the selected queue item?",
        REWORK_CONFERMATIONS_ERROR:"Error reprocessing queue item",
        TABLE: {
            CREATION_DATE:"Creation date",
            UPDATE_DATE:"Update date",
            GO_TO_DETAIL: "Go to detail",
            COPY_ID: "Copy ID",
            EXECUTIONS: "Executions",
            STATUS: "Status",
            STATUS_ELABORATED:"Elaborato",
            STATUS_PENDING: "Pending",
            STATUS_TO_BE_PROCESSED: "Processed",
            STATUS_PROCESSED_SUCCESSFUL:"Process completed",
            STATUS_PROCESSED_FAILED: "Process failed",
            RECORDS: "Records #",
            GOTO_DETAILS:"Go to details",
            REWORK:"Rework again",
            PROCESSING_TRAKING:"processing tracking",
            EXTERNAL_CODE:"External code"
        },
        STATUS: {
            TO_BE_PROCESSED: "Pending",
            PROCESSING: "Processing",
            PROCESSED_SUCCESSFUL: "Processed",
            PROCESSED_FAILED: "Failed"
        },
        DETAILS: {
            TAB_INFO:"Information",
            TAB_INFO_TITLE_1:"Queue Information",
            TAB_INFO_TITLE_2:"Queue Execution",
            TAB_PAYLOAD:"Payload",
            TAB_DETAIL:"Detail",
            EXTERNAL_CODE:"External code",
            CREATION_DATE:"Creation date",
            UPDATE_DATA:"Last update date",
            STATUS:"Status",
            REPORT:"Report",
            TIMELINE_ELEMENT:"There is no information for this specific execution"
        }
    },
    TABLE: {
        PAGINA_NUMERO_DI: "Page __NUMBER__ of __TOTAL__",
        RISULTATI: "results",
        RISULTATI_FILTRATI_DA: "results (filtered from __TOTAL__ total)",
        VAI_A: "Go to...",
        PAGINA: "Page",
        NESSUN_RISULTATO: "No results.",
        SI_E_VERIFICATO_UN_ERRORE: "An error occurred. Click on the button below to retry."
    },
    DATE_PICKER: {
        DEFAULT_DATE_RANGES: {
            OGGI: "Today",
            IERI: "Yesterday",
            ULTIMA_SETTIMANA: "Last week",
            PRIMO_MESE: "From first of month",
            MESE_PRECEDENTE: "In previous month"
        },
        SALVA_FILTRO_PERSONALIZZATO: "Save custom filter"
    },
    REACT_SELECT: {
        PLACEHOLDER: "Select a value...",
        NESSUN_RISULTATO: "No results found."
    },
    CONFIGURAZIONE: {
        NUOVA_CONFIGURAZIONE: "New Configuration"
    },
    FILTERS: {
        SELEZIONARE_ALMENO_UN_FILTRO: "Select at least one filter to view the results.",
        NESSUN_RISULTATO: "No results found.",
        ERRORE: "An error occurred, please try again."
    },
    ADVANCED_FILTERS: {
        FILTRO_AVANZATO: "Advanced filter",
        LABELS: {
            BRAND: "Brand",
            CATEGORIE: "Categories",
            STAGIONI: "Seasons",
            CANALE_DI_VENDITA: "Sales channel",
            QUANTITY: "Quantity",
            SELEZIONA_LE_CATEGORIE: "Select categories...",
            CATEGORIE_SELEZIONATE: "Selected categories",
            DATA: "Date",
            SKU: "SKU",
            STATO: "Status",
            ORDER_TYPE: "Order type",
            ORDER: "Order",
            ORDERS: "Orders in",
            WAREHOUSES: "Warehouses",
            SELEZIONA_ELEMENTI: "Select items...",
            ELEMENTI_SELEZIONATI: "Selected items",
            COMPONENTS: "Components",
            GROUP: "Group",
            CUSTOMER: "Customer",
            SHOP: "Shop",
            ARTICOLO: "Item",
            ARTICOLI: "Items included in"
        },
        NESSUN_FILTRO_APPLICATO: "No filter applied.",
        ERRORE_CARICAMENTO_FILTRI: "An error occurred while loading the filters.",
        SHOP: "Shops in ",
        BRAND: "Brands in ",
        SEASON: "Seasons in ",
        E_ALTRI: " and others",
        E_ALTRE: " and others",
        DATA: "Date",
        DATA_DA: "from",
        DATA_A: "to",
        IL_GIORNO: "on the day",
        QUANTITA_NO: "DO NOT need to have available quantity",
        QUANTITA_SI: "must have at least one available quantity",
        FILTRI_PERSONALIZZATI: "Custom filters",
        NESSUN_FILTRO_PERSONALIZZATO: "No custom filters available.",
        SALVA_FILTRO_PERSONALIZZATO: "Save custom filter",
        SALVA_FILTRO_PLACEHOLDER: "Custom filter",
        APPLICA: "Apply",
        ANNULLA: "Cancel",
        RESETTA: "Reset",
        FILTRO_NON_APPLICATO: "Filter not yet applied",
        CATEGORIES: "Categories in",
        SKU: "SKUs in",
        QUALSIASI_STATO: "Any status",
        IN_STATO: "In status"
    },
    DOCUMENT_TYPES: {
        COMMERCIAL_INVOICE: "Invoice",
        AIRWAY_BILL: "Airway bill",
        RETURN_INSTRUCTIONS: "Return instructions",
        RETURN_LABEL: "Return labels",
        CITES_DECLARATION: "Washington Declaration",
        ORDER_DESCRIPTION: "Order description",
        SHIPPING_DESCRIPTION: "Shipping description",
        SHIPPING_LABEL: "Shipping labels",
        OTHER: "Not defined"
    },
    INPUT_TAGS:{
        ADD_TAGS:"Add a tag",
        REMOVE:"Click to remove",
        TITLE: "Tags"
    },
    QUEUES: {
        STATUSES: {
            TO_BE_PROCESSED: "Waiting",
            PROCESSING: "Processing",
            PROCESSED_SUCCESSFUL: "Successful",
            PROCESSED_FAILED: "Failed"
        },
        CARD:{
            ACCOUNT:"Account",
            SHOP:"Shop",
            AMAZON: "Amazon",
            BC:"Business Conditions",
            WHS:"Warehouses",
            ERP:"ERP Module",
            NETSUITE:"NetSuite",
            SAVAIT:"Savait",
            FXFF:"FXFF",
            TON:"Tony",
            RETAILPRO:"RetailPro",
            EFASHION:"e-Fashion",
            NEW_EXTERNAL:"New external queues",
            THE_CORNER:"The Corner",
            NEW_QUEUE:"New queues"
        },
        DESCRIPTIONS: {
            UNKNOWN_QUEUE: "Custom Queue",
            INTERNAL_QUEUE_IN_CLOUDSTORE: "Internal Queue in CloudStore",
            EXTERNAL_QUEUE_TO_CLOUDSTORE: "External Queue to CloudStore",
            ITEMS_TO_BE_PROCESSED: "Items to be processed",
            ITEMS_IN_ERROR: "Items in error",
            NO_ERROR: "No errors!",
            DEFAULT_DESCRIPTION: "There is no description available for the queue",
            OUTBOUND: {
                MOD_M2_PRICE_QUANTITY: {
                    LABEL: "Outbound prices and quantities",
                    DESCRIPTION: "Export of price and quantity information to the ESB."
                },
                MOD_M2_ITEMS: {
                    LABEL: "Outbound items",
                    DESCRIPTION: "Export of item registry information to the ESB."
                },
                TON_ITEM_NS: {
                    LABEL: "Outbound item",
                    DESCRIPTION: "Export of item registry from CloudStore to NetSuite"
                },
                SVT_ORDER: {
                    LABEL: "Outbound orders to Account Partner",
                    DESCRIPTION: "Outbound partner orders from Marketplace Account to Account Partner"
                },
                SVT_AC2SS: {
                    LABEL: "Outbound orders to Savait Servant",
                    DESCRIPTION: "Outbound partner orders from Marketplace Account to Savait Servant"
                },
                ERP_ORDERS: {
                    LABEL: "Outbound sales orders",
                    DESCRIPTION: "Sending of sales orders received from CloudStore to the connected ERP"
                },
                NS_OUTBOUND_SALES_ORDERS: {
                    LABEL: "Outbound sales orders",
                    DESCRIPTION: "Sending to NetSuite of sales orders received from CloudStore"
                },
                NS_OUTBOUND_TRANSFER_ORDERS: {
                    LABEL: "Outbound transfer orders",
                    DESCRIPTION: "Sending to NetSuite of transfer orders received from CloudStore"
                },
                OTHER_OUTBOUND_PACKAGES: {
                    LABEL: "Sending packaging information",
                    DESCRIPTION: "Sending of packaging information to Shops with this stream active",
                    EXTERNAL_KEY: "Order to be shipped",
                    PARTITON_ID:"Shop"
                },
                OTHER_OUTBOUND_SHIPMENTS: {
                    LABEL: "Sending shipments",
                    DESCRIPTION: "Sending of shipments to Shops with this stream active"
                },
                OTHER_OUTBOUND_RETURN: {
                    LABEL: "Sending returns without RMA",
                    DESCRIPTION: "Sending of information related to returns without RMA to Shops with this stream active"
                },
                OTHER_OUTBOUND_RETURN_REQUEST: {
                    LABEL: "Return request response",
                    DESCRIPTION: "Sending of the response to return requests to Shops with this stream active"
                },
                AMZN_FEED_REQUEST_FULFILLMENT: {
                    LABEL: "Feed shipments",
                    DESCRIPTION: "Sending of shipment information"
                },
                AMZN_FEED_REQUEST_INVENTORY: {
                    LABEL: "Feed inventory",
                    DESCRIPTION: "Sending inventory item updates"
                },
                AMZN_FEED_RESPONSE: {
                    LABEL: "Feed response",
                    DESCRIPTION: "Reading Amazon's responses to various feeds"
                },
                TON_OUTBOUND_NS:{
                    LABEL: "Element outgoing to NetSuite",
                    DESCRIPTION: "Element outgoing to NetSuite"
                },
                MKPLSVT_SS_ORDER:{
                    LABEL:"Outbound Orders to SCSC",
                    DESCRIPTION:"Outbound Orders to SCSC",
                },
                MKPLSVT_ORDER:{
                    LABEL:"Outbound Orders to CS Connector",
                    DESCRIPTION:"Outbound Orders to CS Connector",
                }
            },
            INBOUND: {
                TON_RP: {
                    LABEL: "Inbound warehouse movements",
                    DESCRIPTION: "Import of warehouse movements generated by Retail Pro"
                },
                EFASHION_ITEMS: {
                    LABEL: "Inbound items from eFashion",
                    DESCRIPTION: "Processing of items to be imported into CloudStore from eFashion"
                },
                NS_INBOUND_CASHSALE_INVOICE: {
                    LABEL: "Inbound invoices / receipts",
                    DESCRIPTION: "Import of information related to receipts and invoices"
                },
                NS_INBOUND_CUSTOMER: {
                    LABEL: "Inbound customers",
                    DESCRIPTION: "Import of customers who have access to the B2B module"
                },
                NS_INBOUND_FULFILLMENTS: {
                    LABEL: "Inbound shipments",
                    DESCRIPTION: "Import of information related to shipments"
                },
                NS_INBOUND_ITEM_AVAILABILITY: {
                    LABEL: "Inbound quantity updates",
                    DESCRIPTION: "Import of remaining available quantities of items"
                },
                NS_INBOUND_ORDERS: {
                    LABEL: "Inbound order updates",
                    DESCRIPTION: "Import of updates related to orders"
                },
                NS_INBOUND_ITEMS: {
                    LABEL: "Inbound items",
                    DESCRIPTION: "Import of item registries"
                },
                RP_INBOUND_MOVIMENTI: {
                    LABEL: "Inbound movements",
                    DESCRIPTION: "Import of warehouse movements created on Retail Pro"
                },
                OTHER_INBOUND_ITEMS: {
                    LABEL: "Import items",
                    DESCRIPTION: "Import of item registries"
                },
                OTHER_INBOUND_ITEM_IMAGES: {
                    LABEL: "Import item images",
                    DESCRIPTION: "Import of item images"
                },
                OTHER_BULK_INBOUND_ITEMS: {
                    LABEL: "Import items stream",
                    DESCRIPTION: "Mass import of item registry information"
                },
                OTHER_BULK_INBOUND_ITEM_IMAGES: {
                    LABEL: "Import item images stream",
                    DESCRIPTION: "Mass import of item images"
                },
                OTHER_BULK_INBOUND_WHS_MOVEMENTS: {
                    LABEL: "Import warehouse movements stream",
                    DESCRIPTION: "Mass import of warehouse movements"
                },
                OTHER_INBOUND_WHS_MOVEMENTS: {
                    LABEL: "Import warehouse movements",
                    DESCRIPTION: "Import of warehouse movements"
                }
            },
            NETSUITE: {
                CONSULT_SEND_ORDER: "Consult the send to NetSuite",
                SEND_ORDER: "Send order to NetSuite",
                SEND_ORDER_NOT_AVAILABLE: "The order is already queued to be sent to NetSuite",
                SEND_ORDER_TITLE: "Order submission",
                SEND_ORDER_CONTENT_OK: "The order has been queued to be sent to <strong>NetSuite</strong>.",
                SEND_ORDER_CONTENT_KO: "It was not possible to prepare the order to be sent to <strong>NetSuite</strong>, or it is already queued to be sent."
            },
            OTHER: {
                REPORT: {
                    LABEL: "Report",
                    DESCRIPTION: "Processing of reports configured by the user"
                },
                LISTING: {
                    LABEL: "Listing",
                    DESCRIPTION: "Creation of listings for items subject to automatic publication"
                },
                LRT: {
                    LABEL: "Long-running tasks",
                    DESCRIPTION: "Queue processing long-running tasks"
                },
                LISTING_EXCLUSION_RULES: {
                    LABEL: "Listing exclusion rules",
                    DESCRIPTION: "Application of exclusion rules to the listing of a subset of catalog items"
                }, 
                AVAILABILITY_PRICE:{
                    LABEL:"Outbound Availability and Prices",
                    DESCRIPTION:"",
                },
                AVAILABILITY_PRICE_RESULT:{
                    LABEL:" Inbound Availability and Prices Result",
                    DESCRIPTION:"",
                }
                
            },
            BC:{
                BUSINESS_CONDITION_1: {
                    LABEL: "Shop business conditions",
                    DESCRIPTION: "Application of business conditions to shops of a subset of catalog items"
                },
                BUSINESS_CONDITION_2: {
                    LABEL: "Item business conditions",
                    DESCRIPTION: "Application of business conditions to items of a subset of catalog items"
                }
            },
        }
    },
    STREAMS: {
        INVENTORY_AND_CATALOG_INBOUND: "Inventory / Catalog inbound",
        ORDER_INBOUND: "Order inbound",
        ORDER_DOCUMENTS_INBOUND: "Order documents inbound",
        RETURN_INBOUND: "Returns inbound",
        RETURN_REQUEST_INBOUND: "Returns request inbound",
        SHIPMENT_INBOUND: "Shipment inbound",
        AVAILABILITY_AND_PRICES_OUTBOUND: "Availability and prices outbound",
        ITEM_OUTBOUND: "Item outbound",
        ORDER_CANCELLATION_OUTBOUND: "Order cancellation outbound",
        ORDER_REPLY_OUTBOUND: "Order reply outbound",
        ORDER_PACKAGING_OUTBOUND: "Order packaging outbound",
        RETURN_OUTBOUND: "Return outbound",
        RETURN_REQUEST_REPLY_OUTBOUND: "Return request reply outbound",
        SHIPMENT_OUTBOUND: "Shipment outbound",
        PAYMENT_OUTBOUND: "Payment outbound",
        ORDER_INBOUND_PRICE_CHECK_CONFIG: {
            CONTROLLO_ATTIVO: "Check enabled",
            TIPO_TOLLERANZA: "Tolerance type",
            ASSOLUTO: "Absolute",
            PERCENTUALE: "Percentage",
            VALORE_TOLLERANZA: "Tolerance value",
            SCRIVI_NEL_REGISTRO_ATTIVITA: "Write in the Activity Log",
            BLOCCA_RISPOSTA: "Block response",
            MAI: "Never",
            SOLO_SE_MINORE: "Only if the price is lower than the minimum limit",
            SOLO_SE_MAGGIORE: "Only if the price is higher than the maximum limit",
            MAGGIORE_MINORE: "If the price is lower than the minimum limit or higher than the maximum limit"
        },
        REPLY_POLICIES: {
            REPLY_ALWAYS: "Automatic without inventory control",
            REPLY_NEVER: "Manual",
            REPLY_IF_IN_CATALOG: "Automatic only if in inventory",
            REPLY_IF_IN_CATALOG_AND_AVAILABLE: "Automatic if available"
        },
        ERRORE_POLICY_DIVERSA_NONE_CONTROLLO_PREZZI_NON_ATTIVO: "If price check is not active, this value must be set to 'Never'.",
        CONTROLLO_PREZZI_DISABILITATO_SE_INBOUND_ORDER_NON_ATTIVO: "Price check cannot be enabled if order inbound is disabled.",
        AVAILABILITY_AND_PRICES_OUTBOUND_OPTIONS: {
            AVAILABILITY: "Only availability",
            PRICES: "Only prices",
            AVAILABILITY_PRICES: "Availability and prices"
        },
        ITEM_OUTBOUND_ALWAYS_ENABLED: "For this shop the item outbound is always enabled.",
    },
    OVERRIDE_WAREHOUSE_ALGORITHM: {
        EREDITA_STRATEGIA_DEFAULT: "Inherit the default strategy",
        NONE: "NONE",
        NO_OP: "No operation",
        WHS_PRIORITY: "Warehouse priority",
        TEMP_COMMIT: "Temporary warehouse",
        SHOP_DRIVEN: "SHOP_DRIVEN"
    },
    FORMS: {
        ERRORI_NEL_FORM: "There are errors in the form fields, please check.",
        CAMPO_OBBLIGATORIO: "Required field",
        LUNGHEZZA_COMPRESA: "The field length must be between __MIN__ and __MAX__ characters.",
        VALORE_COMPRESO: "The field value must be between __MIN__ and __MAX__.",
        EMAIL_NON_VALIDA: "Invalid email",
        FORMATO_UUID_NON_VALIDO: "The field value must be in UUID format",
        ERRORE_REGEX: "Wrong format"
    },
    LANGUAGES : {
        DE: "German",
        DK: "Danish",
        ES: "Spanish",
        EU: "European Union",
        FR: "French",
        EN: "English",
        IT: "Italian",
        RU: "Russian",
        SE: "Swedish",
        CN: "Chinese",
        KR: "Korean",
    },
    TREE_SEARCH : {
        OTHER_RESULTS : " Show more results (__REMINIG__ remaining )",
    },
    COMPONENT_PRICEBOOKS: {
        CURRENCY: "Currency",
        STOCK_PRICEBOOK: "Stock price",
        SALE_PRICEBOOK: "Sale price",
        AGGIUNGI: "Add",
        RIMUOVI: "Remove",
        SELECT_PRICEBOOK:"Select Pricebook",
        INCLUDES_VAT:"Include Vat"
    },
    COUNTRIES: {
        SPAGNA: "Spain",
        REGNO_UNITO: "U.K.",
        FRANCIA: "France",
        BELGIO: "Belgium",
        PAESI_BASSI: "Netherlands",
        GERMANIA: "Germany",
        ITALIA: "Italy",
        SVEZIA: "Sweden",
        POLONIA: "Poland"
    },
    HOME_WIDGETS: {
        ORDINI_DI_OGGI: "Today's orders",
        NESSUN_ORDINE_OGGI: "No new order."
    },
    YOOX_SALE_CHANNEL : {
        BE: 'Benelux',
        CEU: 'Central Europe',
        DE: 'Germany, Austria',
        DK: 'Scandinavia-Eire',
        EEU: 'Eastern',
        ES: 'Spain',
        FR: 'France',
        GR: 'Greece',
        IT: 'Italy',
        NL: 'Netherlands',
        SEU: 'Southern Europe',
    },
    GLF_SALE_CHANNEL:{
        FR: 'Private Sales Channel (Francia)' 
    },
    DECATHLON_SALE_CHANNEL:{
        BE: 'Benelux',
        CEU: 'Central Europe',
        DE: 'Germany, Austria',
        DK: 'Scandinavia-Eire',
        EEU: 'Eastern',
        ES: 'Spain',
        FR: 'France',
        GR: 'Greece',
        IT: 'Italy',
        NL: 'Netherlands',
        SEU: 'Southern Europe',
    },
    MIRAKL_SALE_CHANNEL_DESC:{
        BE_DESC: 'Belgium, Luxembourg',
        CEU_DESC: 'Poland, Romania, Slovakia, Hungary, Czech Republic',
        DE_DESC: 'Germany, Austria',
        DK_DESC: 'Denmark, Finland, Ireland, Sweden',
        EEU_DESC: 'Estonia, Latvia, Lithuania',
        ES_DESC: 'Spain',
        FR_DESC: 'France',
        GR_DESC: 'Greece',
        IT_DESC: 'Italy',
        NL_DESC: 'Netherlands',
        SEU_DESC: 'Bulgaria, Croatia, Cyprus, Malta, Slovenia, Portugal' 
    },
    COUNTRIES_CODE: {
        AC: "Ascension Island",
        AD: "Andorra",
        AE: "United Arab Emirates",
        AF: "Afghanistan",
        AG: "Antigua and Barbuda",
        AI: "Anguilla",
        AL: "Albania",
        AM: "Armenia",
        AO: "Angola",
        AQ: "Antarctica",
        AR: "Argentina",
        AS: "American Samoa",
        AT: "Austria",
        AU: "Australia",
        AW: "Aruba",
        AX: "Åland Islands",
        AZ: "Azerbaijan",
        BA: "Bosnia and Herzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Belgium",
        BF: "Burkina Faso",
        BG: "Bulgaria",
        BH: "Bahrain",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint Barthélemy",
        BM: "Bermuda",
        BN: "Brunei",
        BO: "Bolivia",
        BQ: "Caribbean Netherlands",
        BR: "Brazil",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Bouvet Island",
        BW: "Botswana",
        BY: "Belarus",
        BZ: "Belize",
        CA: "Canada",
        CC: "Cocos (Keeling) Islands",
        CD: "Congo (Kinshasa)",
        CF: "Central African Republic",
        CG: "Congo (Brazzaville)",
        CH: "Switzerland",
        CI: "Côte d'Ivoire",
        CK: "Cook Islands",
        CL: "Chile",
        CM: "Cameroon",
        CN: "China",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Cape Verde",
        CW: "Curaçao",
        CX: "Christmas Island",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DE: "Germany",
        DJ: "Djibouti",
        DK: "Denmark",
        DM: "Dominica",
        DO: "Dominican Republic",
        DZ: "Algeria",
        EC: "Ecuador",
        EE: "Estonia",
        EG: "Egypt",
        EH: "Western Sahara",
        ER: "Eritrea",
        ES: "Spain",
        ET: "Ethiopia",
        FI: "Finland",
        FJ: "Fiji",
        FK: "Falkland Islands",
        FM: "Micronesia",
        FO: "Faroe Islands",
        FR: "France",
        GA: "Gabon",
        GB: "United Kingdom",
        GD: "Grenada",
        GE: "Georgia",
        GF: "French Guiana",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibraltar",
        GL: "Greenland",
        GM: "Gambia",
        GN: "Guinea",
        GP: "Guadeloupe",
        GQ: "Equatorial Guinea",
        GR: "Greece",
        GS: "South Georgia and the South Sandwich Islands",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Heard Island and McDonald Islands",
        HN: "Honduras",
        HR: "Croatia",
        HT: "Haiti",
        HU: "Hungary",
        ID: "Indonesia",
        IE: "Ireland",
        IL: "Israel",
        IM: "Isle of Man",
        IN: "India",
        IO: "British Indian Ocean Territory",
        IQ: "Iraq",
        IR: "Iran",
        IS: "Iceland",
        IT: "Italy",
        JE: "Jersey",
        JM: "Jamaica",
        JO: "Jordan",
        JP: "Japan",
        KE: "Kenya",
        KG: "Kyrgyzstan",
        KH: "Cambodia",
        KI: "Kiribati",
        KM: "Comoros",
        KN: "Saint Kitts and Nevis",
        KP: "North Korea",
        KR: "South Korea",
        KW: "Kuwait",
        KY: "Cayman Islands",
        KZ: "Kazakhstan",
        LA: "Laos",
        LB: "Lebanon",
        LC: "Saint Lucia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Lithuania",
        LU: "Luxembourg",
        LV: "Latvia",
        LY: "Libya",
        MA: "Morocco",
        MC: "Monaco",
        MD: "Moldova",
        ME: "Montenegro",
        MF: "Saint Martin",
        MG: "Madagascar",
        MH: "Marshall Islands",
        MK: "North Macedonia",
        ML: "Mali",
        MM: "Myanmar (Burma)",
        MN: "Mongolia",
        MO: "Macau",
        MP: "Northern Mariana Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauritius",
        MV: "Maldives",
        MW: "Malawi",
        MX: "Mexico",
        MY: "Malaysia",
        MZ: "Mozambique",
        NA: "Namibia",
        NC: "New Caledonia",
        NE: "Niger",
        NF: "Norfolk Island",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Netherlands",
        NO: "Norway",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "New Zealand",
        OM: "Oman",
        PA: "Panama",
        PE: "Peru",
        PF: "French Polynesia",
        PG: "Papua New Guinea",
        PH: "Philippines",
        PK: "Pakistan",
        PL: "Poland",
        PM: "Saint Pierre and Miquelon",
        PN: "Pitcairn Islands",
        PR: "Puerto Rico",
        PS: "Palestine",
        PT: "Portugal",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Réunion",
        RO: "Romania",
        RS: "Serbia",
        RU: "Russia",
        RW: "Rwanda",
        SA: "Saudi Arabia",
        SB: "Solomon Islands",
        SC: "Seychelles",
        SD: "Sudan",
        SE: "Sweden",
        SG: "Singapore",
        SH: "Saint Helena",
        SI: "Slovenia",
        SJ: "Svalbard and Jan Mayen",
        SK: "Slovakia",
        SL: "Sierra Leone",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Suriname",
        SS: "South Sudan",
        ST: "São Tomé and Príncipe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SY: "Syria",
        SZ: "Eswatini",
        TC: "Turks and Caicos Islands",
        TD: "Chad",
        TF: "French Southern Territories",
        TG: "Togo",
        TH: "Thailand",
        TJ: "Tajikistan",
        TK: "Tokelau",
        TL: "Timor-Leste",
        TM: "Turkmenistan",
        TN: "Tunisia",
        TO: "Tonga",
        TR: "Turkey",
        TT: "Trinidad and Tobago",
        TV: "Tuvalu",
        TW: "Taiwan",
        TZ: "Tanzania",
        UA: "Ukraine",
        UG: "Uganda",
        UM: "United States Minor Outlying Islands",
        US: "United States",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VA: "Vatican City",
        VC: "Saint Vincent and the Grenadines",
        VE: "Venezuela",
        VG: "British Virgin Islands",
        VI: "U.S. Virgin Islands",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis and Futuna",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Yemen",
        YT: "Mayotte",
        ZA: "South Africa",
        ZM: "Zambia",
        ZW: "Zimbabwe",
        UNDEFINED: "Undefined"
    },
    CURRENCY : {
        AED: "United Arab Emirates Dirham",
        AFN: "Afghani",
        ALL: "Lek",
        AMD: "Armenian Dram",
        ANG: "Netherlands Antillean Guilder",
        AOA: "Kwanza",
        ARS: "Argentine Peso",
        AUD: "Australian Dollar",
        AWG: "Aruban Florin",
        AZN: "Azerbaijan Manat",
        BAM: "Convertible Mark",
        BBD: "Barbados Dollar",
        BDT: "Taka",
        BGN: "Bulgarian Lev",
        BHD: "Bahraini Dinar",
        BIF: "Burundi Franc",
        BMD: "Bermudian Dollar",
        BND: "Brunei Dollar",
        BOB: "Boliviano",
        BRL: "Brazilian Real",
        BSD: "Bahamian Dollar",
        BTN: "Ngultrum",
        BWP: "Pula",
        BYN: "Belarusian Ruble",
        BZD: "Belize Dollar",
        CAD: "Canadian Dollar",
        CDF: "Congolese Franc",
        CHF: "Swiss Franc",
        CLP: "Chilean Peso",
        CNY: "Yuan Renminbi",
        COP: "Colombian Peso",
        CRC: "Costa Rican Colon",
        CUC: "Peso Convertible",
        CUP: "Cuban Peso",
        CVE: "Cabo Verde Escudo",
        CZK: "Czech Koruna",
        DJF: "Djibouti Franc",
        DKK: "Danish Krone",
        DOP: "Dominican Peso",
        DZD: "Algerian Dinar",
        EGP: "Egyptian Pound",
        ERN: "Nakfa",
        ETB: "Ethiopian Birr",
        EUR: "Euro",
        FJD: "Fiji Dollar",
        FKP: "Falkland Islands Pound",
        GBP: "Pound Sterling",
        GEL: "Lari",
        GHS: "Ghana Cedi",
        GIP: "Gibraltar Pound",
        GMD: "Dalasi",
        GNF: "Guinea Franc",
        GTQ: "Quetzal",
        GYD: "Guyana Dollar",
        HKD: "Hong Kong Dollar",
        HNL: "Lempira",
        HRK: "Kuna",
        HTG: "Gourde",
        HUF: "Forint",
        IDR: "Rupiah",
        ILS: "New Israeli Sheqel",
        INR: "Indian Rupee",
        IQD: "Iraqi Dinar",
        IRR: "Iranian Rial",
        ISK: "Iceland Krona",
        JMD: "Jamaican Dollar",
        JOD: "Jordanian Dinar",
        JPY: "Yen",
        KES: "Kenyan Shilling",
        KGS: "Som",
        KHR: "Riel",
        KMF: "Comoro Franc",
        KPW: "North Korean Won",
        KRW: "Won",
        KWD: "Kuwaiti Dinar",
        KYD: "Cayman Islands Dollar",
        KZT: "Tenge",
        LAK: "Kip",
        LBP: "Lebanese Pound",
        LKR: "Sri Lanka Rupee",
        LRD: "Liberian Dollar",
        LSL: "Loti",
        LYD: "Libyan Dinar",
        MAD: "Moroccan Dirham",
        MDL: "Moldovan Leu",
        MGA: "Malagasy Ariary",
        MKD: "Denar",
        MMK: "Kyat",
        MNT: "Tugrik",
        MOP: "Pataca",
        MRU: "Ouguiya",
        MUR: "Mauritius Rupee",
        MVR: "Rufiyaa",
        MWK: "Malawi Kwacha",
        MXN: "Mexican Peso",
        MYR: "Malaysian Ringgit",
        MZN: "Mozambique Metical",
        NAD: "Namibia Dollar",
        NGN: "Naira",
        NIO: "Cordoba Oro",
        NOK: "Norwegian Krone",
        NPR: "Nepalese Rupee",
        NZD: "New Zealand Dollar",
        OMR: "Rial Omani",
        PAB: "Balboa",
        PEN: "Sol",
        PGK: "Kina",
        PHP: "Philippine Peso",
        PKR: "Pakistan Rupee",
        PLN: "Zloty",
        PYG: "Guarani",
        QAR: "Qatari Rial",
        RON: "Romanian Leu",
        RSD: "Serbian Dinar",
        RUB: "Russian Ruble",
        RWF: "Rwanda Franc",
        SAR: "Saudi Riyal",
        SBD: "Solomon Islands Dollar",
        SCR: "Seychelles Rupee",
        SDG: "Sudanese Pound",
        SEK: "Swedish Krona",
        SGD: "Singapore Dollar",
        SHP: "Saint Helena Pound",
        SLL: "Leone",
        SOS: "Somali Shilling",
        SRD: "Surinam Dollar",
        SSP: "South Sudanese Pound",
        STN: "Dobra",
        SYP: "Syrian Pound",
        SZL: "Lilangeni",
        THB: "Baht",
        TJS: "Somoni",
        TMT: "Turkmenistan New Manat",
        TND: "Tunisian Dinar",
        TOP: "Paʻanga",
        TRY: "Turkish Lira",
        TTD: "Trinidad and Tobago Dollar",
        TWD: "New Taiwan Dollar",
        TZS: "Tanzanian Shilling",
        UAH: "Hryvnia",
        UGX: "Uganda Shilling",
        USD: "US Dollar",
        UYU: "Peso Uruguayo",
        UZS: "Uzbekistan Sum",
        VES: "Bolívar Soberano",
        VND: "Dong",
        VUV: "Vatu",
        WST: "Tala",
        XAF: "CFA Franc BEAC",
        XCD: "East Caribbean Dollar",
        XOF: "CFA Franc BCEAO",
        XPF: "CFP Franc",
        YER: "Yemeni Rial",
        ZAR: "Rand",
        ZMW: "Zambian Kwacha",
        ZWL: "Zimbabwe Dollar"
    }

}

export default en