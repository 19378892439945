import { resetCustomerPassword, updateCustomerStatus, updateCustomerUsername } from "@api/Customers"
import CSInput from "@components/Form/CSInput"
import CSSwitch from "@components/Form/CSSwitch"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import SectionSubGroup from "@components/SectionGroup/SectionSubGroup"
import { Customer } from "cs.node.utils/csapi/provisioning"
import Localization from "@localization/Index"
import { checkForm, FormErrors } from "@utils/FormUtils"
import { askConfirmation, closeWaiting, showError, showSuccess, showWaiting } from "@utils/NotificationsUtils"
import { PageStates } from "@utils/PageStateUtils"
import { useState } from "react"

type Props = {
    customer: Customer
    onChange: ()=>unknown
}

const CustomerAccount = (props: Props) =>{
    const { customer, onChange } = props
    const { _id, status, security={} } = customer
    const { username="" } = security
    const [editUsername, setEditUsername] = useState(username)
    const [componentState, setComponentState] = useState<PageStates>(PageStates.IDLE)
    const [formErrors, setFormErrors] = useState<FormErrors>({})

    const changeUsername = async()=>{

        setFormErrors({})

        const checkResponse = checkForm([
            {id: "username", value: editUsername, required: true, regex: "^[\\w.-]+@[\\w.-]+\\.[A-Za-z]{2,}$"},
        ])

        if(Object.keys(checkResponse).length>0) {
            setFormErrors(checkResponse)
            return setComponentState(PageStates.FORM_ERROR)
        }

        const confirmResponse = await askConfirmation({html: Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.CONFERMA_USERNAME(editUsername)})
        if(!confirmResponse.isConfirmed) return false

        try{
            showWaiting(Localization.ATTENDERE)
            await updateCustomerUsername(_id?.$oid!, editUsername)
            closeWaiting()
            showSuccess(Localization.OPERAZIONE_AVVENUTA_CON_SUCCESSO, undefined, undefined, ()=>{
                return onChange()
            })
        } catch(err:any) {
            closeWaiting()
            if(err.response?.status === 400) {
                return showError(Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.USERNAME_GIA_IN_USO)
            }
            return showError(Localization.ERRORE_GENERICO)
        }
    }

    const toggleUserStatus = async() =>{
        const confirmResponse = await askConfirmation({html: status==="ACTIVE"?Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.CONFERMA_DISATTIVAZIONE:Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.CONFERMA_ATTIVAZIONE})
        if(!confirmResponse.isConfirmed) return false

        try{
            showWaiting(Localization.ATTENDERE)
            await updateCustomerStatus(_id?.$oid!, (status==="ACTIVE"?"INACTIVE":"ACTIVE"))
            closeWaiting()
            showSuccess(Localization.OPERAZIONE_AVVENUTA_CON_SUCCESSO, undefined, undefined, ()=>{
                return onChange()
            })
        } catch(err:any) {
            closeWaiting()
            return showError(Localization.ERRORE_GENERICO)
        }

    }

    const sendPasswordReset = async()=>{
        const confirmResponse = await askConfirmation({html: Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.CONFERMA_RESET})
        if(!confirmResponse.isConfirmed) return false

        try{
            showWaiting(Localization.ATTENDERE)
            await resetCustomerPassword(username)
            closeWaiting()
            showSuccess(Localization.OPERAZIONE_AVVENUTA_CON_SUCCESSO)
        } catch(err:any) {
            closeWaiting()
            return showError(Localization.ERRORE_GENERICO)
        }
    }

    return <>
        <SectionSubGroup small className="bg-warning-light">
            <div className="d-flex align-items-center justify-content-left">
                <i className="bi-exclamation-triangle fs-1 mx-5" />
                <div className="fs-6">{Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.DESCRIPTION()}</div>
            </div>
        </SectionSubGroup>
        <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.MODIFICA_UTENTE}>
            <div className="row">
                <div className="col-md-10">
                    <div className="mt-field">
                        <CSInput className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.USERNAME}`} type="text" controlId="sec_usr" 
                            value={editUsername ?? ""}
                            onChange={(e)=>setEditUsername(e.target.value)} 
                            width={'100%'}
                            disabled={false}
                            hasError={componentState===PageStates.FORM_ERROR && !!formErrors.username}
                            errorText={formErrors.username}
                            buttons={(editUsername !== username) ?[{icon: "bi-arrow-counterclockwise text-muted", tooltip: Localization.ANNULLA, action: ()=>{setFormErrors({}); setEditUsername(username)}}, {icon: "bi-check-circle text-success", tooltip: Localization.SALVA, action: ()=>changeUsername()}]: undefined}
                        />
                    </div>
                </div>
            </div>
        </SectionGroup>
        <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.RESET_PASSWORD}>
            <div className="row">
                <div className="col-md-10">
                    <div className="mt-field">
                        <button type="button" onClick={()=>sendPasswordReset()} className="btn btn-warning">{Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.INVIA_RICHIESTA}</button>
                    </div>
                </div>
            </div>
        </SectionGroup>
        <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.STATO_CLIENTE}>
            <div className="row">
                <div className="col-md-8">
                    <div className="mt-field">
                        <CSSwitch
                            label={(status==="ACTIVE")?Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.DISATTIVA_CLIENTE:Localization.PAGE_CUSTOMERS.TABS.ACCOUNT.ATTIVA_CLIENTE}
                            controlId={'customer_status'} 
                            checked={(status==="ACTIVE")} 
                            onChange={(e) => {
                                toggleUserStatus()
                            }}
                        />
                    </div>
                </div>
            </div>
        </SectionGroup>
    </>
}

export default CustomerAccount