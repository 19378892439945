import CSInput from "@components/Form/CSInput"
import CSReactSelect, { CSOption } from "@components/Form/CSReactSelect"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import { Customer } from "cs.node.utils/csapi/provisioning"
import { LanguageId } from "@interfaces/Icons"
import Localization from "@localization/Index"
import { SUPPORTED_LANGUAGES } from "@utils/Constants"
import { FormErrors } from "@utils/FormUtils"
import { PageStates } from "@utils/PageStateUtils"
import equal from 'deep-equal'

type Props = {
    customer: Customer
    isEditable: boolean
    formErrors: FormErrors
    pageState: PageStates
    onChange: (updatedCustomer: Customer)=>unknown
}

export const isGeneralInfoChanged = (prev: Customer, curr: Customer) =>{
    return !equal({
        first_name: prev.first_name ?? "",
        last_name: prev.last_name ?? "",
        company_name: prev.company_name ?? "",
        customer_code: prev.customer_code ?? "",
        email: prev.contacts?.email ?? "",
        mobile: prev.contacts?.mobile ?? "",
        pec: prev.contacts?.pec ?? "",
        phone: prev.contacts?.phone ?? "",
        default_lang: prev.default_lang ?? ""
    }, {
        first_name: curr.first_name ?? "",
        last_name: curr.last_name ?? "",
        company_name: curr.company_name ?? "",
        customer_code: curr.customer_code ?? "",
        email: curr.contacts?.email ?? "",
        mobile: curr.contacts?.mobile ?? "",
        pec: curr.contacts?.pec ?? "",
        phone: curr.contacts?.phone ?? "",
        default_lang: curr.default_lang ?? ""
    })
}

export const isGeneralInfoError = (formErrors: FormErrors) =>{
    return ["first_name", "last_name", "company_name", "email", "mobile", "pec", "phone", "default_lang"].find(key=>Object.keys(formErrors).includes(key))
}

const CustomerGeneralInfo = (props: Props) =>{
    const { customer, isEditable, formErrors, pageState, onChange } = props
    const { first_name, last_name, company_name, contacts={}, default_lang, customer_code } = customer
    const { email, phone, pec, mobile } = contacts

    const changeValue = (value: any, field: string) =>{
        const updatedCustomer = {
            ...customer,
            [field]: value
        }

        return onChange(updatedCustomer)
    }

    const changeContact = (value: any, field: string) =>{
        const updatedContacts = {
            ...contacts,
            [field]: value
        }
        const updatedCustomer = {
            ...customer,
            contacts: updatedContacts
        }

        return onChange(updatedCustomer)
    }

    const languageOptions:CSOption[] = SUPPORTED_LANGUAGES.map(lang=>{
        return {
            value: lang,
            label: Localization.LANGUAGES[lang.toUpperCase() as keyof typeof LanguageId ]
        }
    })

    const languageOptionSelected = default_lang ? {
        value: default_lang,
        label: Localization.LANGUAGES[default_lang.toUpperCase() as keyof typeof LanguageId ]
    }: undefined

    return <>
        <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.ANAGRAFICA}>
            <div className="row pt-0">
                <div className="col-md-12">
                    <div className="mt-field">
                        <CSInput mandatory className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.NOME}`} type="text" controlId="first_name" 
                            value={first_name ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'first_name')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.first_name}
                            errorText={formErrors.first_name}
                        />
                        <CSInput mandatory className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.RAGIONE_SOCIALE}`} type="text" controlId="company_name" 
                            value={company_name ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'company_name')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.company_name}
                            errorText={formErrors.company_name}
                        />
                        <CSInput className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.CODICE_CLIENTE}`} type="text" controlId="customer_code" 
                            value={customer_code ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'customer_code')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.customer_code}
                            errorText={formErrors.customer_code}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="mt-field">
                        <CSInput mandatory className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.COGNOME}`} type="text" controlId="last_name" 
                            value={last_name ?? ""}
                            onChange={(e)=>changeValue(e.target.value, 'last_name')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.last_name}
                            errorText={formErrors.last_name}
                        />
                        <CSReactSelect 
                            controlId="default_lang"
                            mandatory
                            disabled={!isEditable}
                            className="mt-field"
                            label={Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.LINGUA}
                            client
                            options={languageOptions}
                            value={languageOptionSelected}
                            onChange={(option:CSOption)=>{
                                return changeValue(option.value, 'default_lang')
                            }}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.default_lang}
                            errorText={formErrors.default_lang}
                        />
                    </div>
                </div>
            </div>
        </SectionGroup>
        <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.CONTATTI}>
            <div className="row pt-0">
                <div className="col-md-12">
                    <div className="mt-field">
                        <CSInput mandatory className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.EMAIL}`} type="text" controlId="email" 
                            value={email ?? ""}
                            onChange={(e)=>changeContact(e.target.value, 'email')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.email}
                            errorText={formErrors.email}
                        />
                        <CSInput className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.PEC}`} type="text" controlId="pec" 
                            value={pec ?? ""}
                            onChange={(e)=>changeContact(e.target.value, 'pec')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.pec}
                            errorText={formErrors.pec}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="mt-field">
                        <CSInput mandatory className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.CELLULARE}`} type="text" controlId="mobile" 
                            value={mobile ?? ""}
                            onChange={(e)=>changeContact(e.target.value, 'mobile')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.mobile}
                            errorText={formErrors.mobile}
                        />
                        <CSInput className="mt-field" label={`${Localization.PAGE_CUSTOMERS.TABS.INFORMAZIONI_GENERALI.TELEFONO}`} type="text" controlId="phone" 
                            value={phone ?? ""}
                            onChange={(e)=>changeContact(e.target.value, 'phone')} 
                            width={'100%'}
                            disabled={!isEditable}
                            hasError={pageState===PageStates.FORM_ERROR && !!formErrors.phone}
                            errorText={formErrors.phone}
                        />
                    </div>
                </div>
            </div>
        </SectionGroup>
    </>
}

export default CustomerGeneralInfo