import styles from './css/ListItemElement.module.scss'

type Props = {
    icon?: string | JSX.Element,
    label?: string,
    value: string | JSX.Element,
    colspan?: number,
    position?: "start" | "center" | "end",
    hover?: boolean,
    onClick?: Function,
    selected?: boolean

}

const ListItemElement = (props: Props) =>{
    const { colspan, icon, label, value, position="start", hover=false, onClick, selected=false } = props
    return <div className={`d-flex${colspan?` col-${colspan} `:``}justify-content-${position} align-items-center ${hover?styles.hover:""}`} onClick={()=>{onClick?.()}}>
                {icon && typeof icon ==="string" && <i className={`${icon} font-2x me-2`} />}
                {icon && typeof icon !=="string" && <div className="me-2">{icon}</div>}
                <div className={`d-flex w-100 flex-column justify-content-${position} align-items-start`}>
                    <div className={`d-flex w-100 justify-content-${position} align-items-start ${styles.value} ${styles.ellipsis} ${selected?styles.selected:""}`}>
                        {value}
                    </div>
                    {label && <div className={`d-flex w-100 justify-content-${position} align-items-start w-100 ${styles.label} ${selected?styles.selected:""}`}>{label}</div>}
                </div>
            </div>
}

export default ListItemElement