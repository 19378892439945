import { getBusinessCondition } from "@api/BusinessConditions"
import { getModuleDetails } from "@api/Modules"
import CSReactSelect, { CSOption } from "@components/Form/CSReactSelect"
import SectionGroup from "@components/SectionGroup/SectionGroup"
import SectionSubGroup from "@components/SectionGroup/SectionSubGroup"
import { BusinessCondition, Customer, CustomerBusinessPriceLogicTypeEnum, EntitySingleResponseModuleResponseModuleB2B, ModuleB2B, ModuleIdEnum, ModuleResponseModuleB2B } from "cs.node.utils/csapi/provisioning"
import Localization from "@localization/Index"
import { AuthContext } from "@providers/AuthProvider"
import { getBusinessConditionStatus } from "@utils/BusinessConditionsUtils"
import { FormErrors } from "@utils/FormUtils"
import { PageStates } from "@utils/PageStateUtils"
import { format, parseISO } from "date-fns"
import { useContext, useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import CustomerBusinessCondition from "./CustomerBusinessCondition"

type Props = {
    customer: Customer
    isEditable: boolean
    formErrors: FormErrors
    pageState: PageStates
    onChange: (updatedCustomer: Customer)=>unknown
}

export const isBusinessConditionsChanged = (prev: Customer, curr: Customer) =>{
    return prev.business_price_logic_type !== curr.business_price_logic_type
}

export const isBusinessConditionsError = (formErrors: FormErrors) =>{
    return ["business_price_logic_type"].find(key=>Object.keys(formErrors).includes(key))
}

const CustomerBusinessConditions = (props: Props) =>{
    const { customer, isEditable, formErrors, pageState, onChange } = props
    const { business_price_logic_type, business_conditions=[] } = customer
    const [componentState, setComponentState] = useState<PageStates>(PageStates.DATA_LOADING)
    const [activeBusinessCondition, setActiveBusinessCondition] = useState<BusinessCondition>()
    const [accountBusinessPriceLogicType, setAccountBusinessPriceLogicType] = useState<"MARKUP"|"DISCOUNT">()
    const auth = useContext(AuthContext)

    const businessPriceLogicTypeOptions:CSOption[] = [
        {label: "DISCOUNT", value: "DISCOUNT"},
        {label: "MARKUP", value: "MARKUP"}
    ]

    const businessPriceLogicTypeOptionSelected = businessPriceLogicTypeOptions.find(option=>option.value === business_price_logic_type)
    const isB2BEnabled = auth.userData?.modules?.find(module=>module._id === ModuleIdEnum.B2B && module.enabled)
    const init = async()=>{
        if(!customer._id?.$oid) {
            return
        }
        try{
            setComponentState(PageStates.DATA_LOADING)
            
            // default populate the active bc if present
            // the others (history) will be loaded on demand
            const activeBusinessConditionReference = business_conditions?.find(bc=>bc.status === "CONFIRMED")
            if(activeBusinessConditionReference?.bc_id?.$oid) {
                const activeBusinessConditionResponse = await getBusinessCondition(activeBusinessConditionReference.bc_id.$oid)
                setActiveBusinessCondition(activeBusinessConditionResponse.content)
            }
    
            if(isB2BEnabled) {
                const B2BModuleResponse = await getModuleDetails(ModuleIdEnum.B2B) as { content: ModuleB2B }
                if(B2BModuleResponse?.content?.default_business_price_logic_type) {
                    setAccountBusinessPriceLogicType(B2BModuleResponse?.content?.default_business_price_logic_type)
                }
            }

            setComponentState(PageStates.IDLE)
        } catch(err) {
            setComponentState(PageStates.DATA_LOADING_ERROR)
        }
    }

    useEffect(()=>{
        init()
    }, [customer._id?.$oid])

    const historyBusinessConditions = (business_conditions?.filter(bc=>bc.bc_id.$oid !== activeBusinessCondition?._id?.$oid)) ?? []

    return <>
    <SectionGroup>
        <div className="row pt-0">
            <div className="col-md-8">
                <div>
                    <CSReactSelect
                        mandatory
                        controlId="business_price_logic_type"
                        label={`${Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.GESTIONE_PREZZI}`}
                        options={businessPriceLogicTypeOptions}
                        value={businessPriceLogicTypeOptionSelected}
                        client
                        onChange={(option:CSOption)=>{
                            const updatedCustomer = {
                                ...customer,
                                business_price_logic_type: option.value as CustomerBusinessPriceLogicTypeEnum
                            }
                            return onChange(updatedCustomer)
                        }}
                        disabled={!isEditable}
                        hasError={pageState===PageStates.FORM_ERROR && !!formErrors.business_price_logic_type}
                        errorText={formErrors.business_price_logic_type}
                    />
                </div>
            </div>
            <div className="col-md-16">
                {accountBusinessPriceLogicType && <SectionSubGroup small className="bg-info-light">
                    <div className="d-flex align-items-center justify-content-start">
                        <div className="d-flex align-items-center fs-2 me-5">
                            <i className="bi-info-circle"></i>
                        </div>
                        <div className="d-flex align-items-center justify-content-start">
                            {Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.GESTIONE_PREZZI_INFO(accountBusinessPriceLogicType)}
                        </div>
                    </div>
                </SectionSubGroup>}
            </div>
        </div>
        
    </SectionGroup>
    <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.CONDIZIONE_COMMERCIALE_ATTIVA}>
        {componentState === PageStates.DATA_LOADING && 
            <div className="d-flex align-items-center justify-content-center mt-field w-100">
                <Spinner animation="border" variant="primary" style={{width: '40px', height: "40px"}} />
            </div>
        }
        {componentState === PageStates.DATA_LOADING_ERROR &&
            <div className="d-flex align-items-center justify-content-start mt-field w-100">
                <div className="fs-6">{Localization.ERRORE_GENERICO}</div>
            </div>
        }
        {componentState === PageStates.IDLE && !activeBusinessCondition &&
            <div className="d-flex align-items-center justify-content-start mt-field w-100">
                <div className="fs-6">{Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.NESSUNA_CONDIZIONE_COMMERCIALE_ATTIVA}</div>
            </div>
        }
        {componentState === PageStates.IDLE && activeBusinessCondition &&
            <CustomerBusinessCondition businessCondition={activeBusinessCondition} />
        }

    </SectionGroup>
    <SectionGroup title={Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.STORICO}>
        {componentState === PageStates.DATA_LOADING && 
            <div className="d-flex align-items-center justify-content-center mt-field w-100">
                <Spinner animation="border" variant="primary" style={{width: '40px', height: "40px"}} />
            </div>
        }
        {componentState === PageStates.DATA_LOADING_ERROR &&
            <div className="d-flex align-items-center justify-content-start mt-field w-100">
                <div className="fs-6">{Localization.ERRORE_GENERICO}</div>
            </div>
        }
        {componentState === PageStates.IDLE && historyBusinessConditions.length === 0 &&
            <div className="d-flex align-items-center justify-content-start mt-field w-100">
                <div className="fs-6">{Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.NESSUNO_STORICO}</div>
            </div>
        }
        {componentState === PageStates.IDLE && historyBusinessConditions.length > 0 &&
        <>
            {historyBusinessConditions?.map((historyBusinessCondition, index)=>{
                const { status, creation_dt } = historyBusinessCondition
                const bcStatus = getBusinessConditionStatus(status!)
                const bcDate = (creation_dt?.$date)?format(parseISO(creation_dt?.$date), 'dd/MM/yyyy HH:mm'):"N.D."
                const placeholder = Localization.PAGE_CUSTOMERS.TABS.CONDIZIONI_COMMERCIALI.PLACEHOLDER_CONDIZIONE_COMMERCIALE(bcDate,bcStatus?.variant, bcStatus?.label)

                return  <CustomerBusinessCondition key={`history_business_condition_${historyBusinessCondition.bc_id.$oid!}_${index}`} id={historyBusinessCondition.bc_id.$oid} placeholder={placeholder} />
            })}
        </> 
        }
    </SectionGroup>
    </>
}

export default CustomerBusinessConditions