import axios, { AxiosError, AxiosResponse } from "axios"
import { checkResponseStatus } from "@api/Utils"
import { EntityListResponseLogEntry, EntitySingleResponseLogServiceMetadata } from 'cs.node.utils/csapi/provisioning'
import { CurrentFilters } from "@utils/AdvancedFiltersUtils"
import { LogsLevel } from "@utils/LogsUtils"

interface ComponentValue {
  value: string
  label: string
}
interface ComponentFilter {
  value: ComponentValue | string;
}


  const getLogs = async (searchTerm: string,logsLevel: LogsLevel, filters:CurrentFilters, pageIndex: number=0, pageSize: number=20): Promise<EntityListResponseLogEntry> => {
    try {
      const date = filters.find(filter=>filter.id==="date")
      const filterDate = (date?.value?? [null, null]) as [Date|null, Date|null] 
      const componentId = filters.find(filter => filter.id === "components")?.value as ComponentFilter | undefined
      const groupId = filters.find(filter=>filter.id==="groupId")?.value as ComponentFilter | undefined
      const level = logsLevel !== LogsLevel.ALL  ? logsLevel  : undefined
      const dateFrom = filterDate[0]
      const dateTo = filterDate[1]
     
      const params = {
        pageIndex, 
        pageSize,
        dateFrom: dateFrom?.toISOString(),
        dateTo: dateTo?.toISOString(),
        componentId : componentId && typeof componentId === 'object' && 'value' in componentId ? componentId?.value : undefined,
        groupId:  groupId && typeof groupId === 'object' && 'value' in groupId ? groupId?.value : undefined,
        level: level,
        searchTerm
      }

      //console.log(params)

      const response:AxiosResponse<EntityListResponseLogEntry,any> = await axios.get('api/logs', {
        params
      })

      return response.data
    } catch (error: any) {
      checkResponseStatus(error)
      throw error;
    }
  }



  const getLogsMetadata  = async () =>{
    try {
      const response:AxiosResponse<EntitySingleResponseLogServiceMetadata> = await axios.get(`api/logs/getLogsMetadata`)
      return response.data
    } catch(error: any) {
      if(error.response?.status === 404) {
          return undefined
      }
      checkResponseStatus(error)
      throw error
  }
}



export { getLogsMetadata, getLogs }