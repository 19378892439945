import Localization from '@localization/Index'
import Swal, {SweetAlertOptions} from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast, ToastContent } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import brandStyle from '../brand.module.scss'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { copyToClipboard } from './StringUtils'
import { useState } from 'react'
import { AxiosError, isAxiosError } from 'axios'

const askConfirmation = async(options: SweetAlertOptions) => {
    const { 
        confirmButtonText=Localization.CONFERMA, 
        cancelButtonText=Localization.ANNULLA,
        denyButtonText=Localization.RIFIUTA,
        confirmButtonColor=brandStyle.bPrimary, 
        cancelButtonColor=brandStyle.white,
        customClass= {
            cancelButton: 'border border-dark bg-white text-dark'
        }, 
        denyButtonColor=brandStyle.bDanger,
        showCancelButton=true,
        showConfirmButton=true,
        showDenyButton=false,
        reverseButtons=true,
        icon="question"
    } = options
    const swal = withReactContent(Swal)
    const confirmResponse = swal.fire({
        ...options,
        confirmButtonText,
        cancelButtonText,
        denyButtonText,
        confirmButtonColor,
        cancelButtonColor,
        customClass,
        denyButtonColor,
        showCancelButton,
        showConfirmButton,
        showDenyButton,
        reverseButtons,
        icon
    })

    return confirmResponse
}

const showWaiting = (message: string | JSX.Element) => {
    const swal = withReactContent(Swal)

    const html = <div className='m-4 fs-4'>{message}</div>
    swal.fire({
        html,
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: ()=>{
            swal.showLoading()
        }
    })
    return
}

const ErrorHtml = (props: {message: string | JSX.Element, error?: any}) => {
    const { message, error } = props
    const [copyMessage, setCopyMessage] = useState(Localization.COPIA_VALORE)

    let errorMessages: string[] = []
    if(error?.response?.data?.data?.messages !== undefined) {
        // error from parseAxiosError that have the CS API error messages
        errorMessages = error.response.data.data.messages
    } else if(typeof error?.response?.data?.data === "string" && error?.response?.data?.data?.length > 0) {
        // case in which the error is flat string in the data field, usually more signifiant than the one in the message field
        errorMessages = [error.response.data.data]
    } else if(isAxiosError(error)) {
        // error from generic axios calls / API 
        const axiosError = error as AxiosError
        errorMessages = [axiosError.message]
    } else if(error?.message !== undefined) {
        // standard js Error class
        errorMessages = [error.message]
    } else if(typeof error === "object") {
        // whatever json passed
        errorMessages = [JSON.stringify(error, null, 2)]
    } else {
        // string or other native js types
        errorMessages = [error]
    }
    
    return <div className='d-flex flex-column align-items-center justify-content-center'>
    {typeof message === "string" && <h5>{message}</h5>}
    {typeof message === "object" && message}
    {error && <>
    <h6 className='mt-4 mb-4'>{`${Localization.DETTAGLI_ERRORE}:`}</h6>
    <OverlayTrigger placement='top' overlay={<Tooltip id="error_copy_tooltip"  style={{position: 'fixed'}}>{copyMessage}</Tooltip>}>
        <div className='w-100 border border-dark text-center fs-6 cs-link p-3' style={{borderRadius: '1rem', maxHeight: '300px', overflowY: 'auto'}} onClick={async ()=>{
            await copyToClipboard({text: errorMessages.join("\n\n")})
            setCopyMessage(Localization.COPIATO)
        }}>
            {errorMessages.map((errorMessage, index)=>{
                return <div key={`error_details_${index}`} className="mt-2 text-start" style={{whiteSpace: "pre-wrap", wordBreak: "break-word"}}>{errorMessage}</div>
            })}
        </div>
    </OverlayTrigger>
    </>}
</div>
}

const showError = (message: string | JSX.Element, error: any|undefined=undefined, options: SweetAlertOptions={}, callback: Function|undefined=undefined) => {
    const swal = withReactContent(Swal)
    swal.fire({
        ...options,
        icon: 'error',
        html: <ErrorHtml message={message} error={error} />
    }).then(()=>{
        callback?.()
    })
}

const showWarning = (message: string | JSX.Element, title: string | JSX.Element="", options: SweetAlertOptions={}, callback: Function|undefined=undefined) => {
    const swal = withReactContent(Swal)
    swal.fire({
        ...options,
        icon: 'warning',
        title,
        html: message
    }).then(()=>{
        callback?.()
    })
}

const showSuccess = (message: string | JSX.Element, title: string | JSX.Element="", options: SweetAlertOptions={}, callback: Function|undefined=undefined) => {
    const swal = withReactContent(Swal)
    swal.fire({
        ...options,
        icon: 'success',
        title,
        html: message
    }).then(()=>{
        callback?.()
    })
}

const showInfo = (message: string | JSX.Element, title: string | JSX.Element="", options: SweetAlertOptions={}, callback: Function|undefined=undefined) => {
    const swal = withReactContent(Swal)
    swal.fire({
        ...options,
        icon: 'info',
        title,
        html: message
    }).then(()=>{
        callback?.()
    })
}

const closeWaiting = () => {
    const swal = withReactContent(Swal)
    swal.close()
    return
}

/**
 * shows a success toast
 * N.B. YOU MUST ADD A ToastContainer COMPONENT IN THE PAGE THAT CALLS THIS FUNCTION TO BE SHOW CORRECTLY!
 * @param content the content to be show in the toast
 * @returns 
 */
const toastSuccess = (content:ToastContent<unknown>) => {
    toast.success(content, {
        hideProgressBar: true,
        draggable: false,
        closeButton: false,
        autoClose: 2000,
        theme: 'colored',
        icon: <i className="bi-check font-2x text-white" />
    })
}

/**
 * shows a error toast
 * N.B. YOU MUST ADD A ToastContainer COMPONENT IN THE PAGE THAT CALLS THIS FUNCTION TO BE SHOW CORRECTLY!
 * @param content the content to be show in the toast
 * @returns 
 */
const toastError = (content:ToastContent<unknown>) => {
    toast.error(content, {
        hideProgressBar: true,
        draggable: false,
        closeButton: false,
        autoClose: 2000,
        theme: 'colored',
        icon: <i className="bi-x font-2x text-white" />
    })
}

/**
 * shows a warning toast
 * N.B. YOU MUST ADD A ToastContainer COMPONENT IN THE PAGE THAT CALLS THIS FUNCTION TO BE SHOW CORRECTLY!
 * @param content the content to be show in the toast
 * @returns 
 */
const toastWarning = (content:ToastContent<unknown>) => {
    toast.warning(content, {
        hideProgressBar: true,
        draggable: false,
        closeButton: false,
        autoClose: 2000,
        theme: 'colored',
        icon: <i className="bi-exclamation-triangle font-2x text-white" />
    })
}


const littleMessage = async(options: SweetAlertOptions) => {
    const { 
        confirmButtonText=Localization.OK, 
        confirmButtonColor=brandStyle.bPrimary, 
        cancelButtonColor=brandStyle.bMuted, 
        showCancelButton=false,
        showConfirmButton=true
    } = options
    const swal = withReactContent(Swal)
    const confirmResponse = swal.fire({
        ...options,
        iconColor: brandStyle.bPrimary,
        confirmButtonText,
        confirmButtonColor,
        cancelButtonColor,
        showCancelButton,
        showConfirmButton
    })

    return confirmResponse
}

export { askConfirmation, showWaiting, showError, showWarning, showSuccess, showInfo, closeWaiting, toastSuccess, toastError, toastWarning, littleMessage}