import Localization from "@localization/Index"
import { validate } from 'uuid'

export type FormErrors = {
    [controlId: string]: string
}


export type  CheckFieldArgs = {
    id: string,
    isUUID?: boolean
    required?: boolean | {isRequired: boolean, message: string}
    regex?: string | {expression: string, message: string}
    customCheckFunction?: ()=>string|undefined
} & (
    {
        value?: string,
        boundaries?: {max: number, min?: number, message?: string}
    } |
    {
        value?: number
        boundaries?: {max: number, min?: number, message?: string}
    } 
)

/**
 * check if the field and, if in error, returns the proper label
 * @param args 
 * @returns {string | undefined} undefined if the field is ok, the error label otherwise
 */
export const checkField = (args: CheckFieldArgs)=>{
    const { value, required=false, isUUID, boundaries, regex, customCheckFunction } = args

    if(customCheckFunction) {
        const customResult = customCheckFunction()
        return customResult
    }

    let isEmptyValue = false
    let valueLength = 0
    switch(typeof value) {
        case 'string':
            isEmptyValue = value===undefined || value.trim().length===0
            valueLength = isEmptyValue?0: value.length
            break;
        case 'number':
            isEmptyValue = value === undefined || value === null || isNaN(value)
            valueLength = isEmptyValue?0: value
            break;
        default:
            isEmptyValue = value === undefined
            break;
    }

    //Check if required and the field is not undefined or empty
    const _required = typeof required === 'boolean'?{isRequired: required, message: Localization.FORMS.CAMPO_OBBLIGATORIO}: required
    if(_required.isRequired && isEmptyValue) return _required.message

    //Check if value respects the min and max values
    if(value!==undefined && !isEmptyValue && boundaries) {
        const { max, min=0, message:boundariesMessage=(typeof value==='string')?Localization.FORMS.LUNGHEZZA_COMPRESA:Localization.FORMS.VALORE_COMPRESO } = boundaries
        if(valueLength > max || valueLength < min) return boundariesMessage.replace("__MAX__", max.toString()).replace("__MIN__", min.toString())
    }
    
    // check the validity of an UUID string if requested
    if(typeof value==='string' && isUUID) {
        if(!validate(value)) return Localization.FORMS.FORMATO_UUID_NON_VALIDO
    }

    // regex check
    if(value!==undefined && !isEmptyValue && regex) {
        const expression = (typeof regex === "string") ? regex: regex.expression
        const message = (typeof regex === "string") ? Localization.FORMS.ERRORE_REGEX: regex.message
        let tobeChecked = (typeof value === 'string')?value: value.toString()
        const isValidRegex = tobeChecked.match(new RegExp(expression))
        if(!isValidRegex) {
            return message
        }
    }

    //TODO: other checks

    // field is ok, return undefined
    return undefined
}

/**
 * check a whole form passed to the function as an array of CheckField
 * @param fields the fields of the form to be checked
 * @returns {FormErrors} the error response
 */
export const checkForm = (fields: CheckFieldArgs[]): FormErrors=>{
    const response: FormErrors={}
    fields.forEach(field => {
        const fieldCheck = checkField(field)
        if(fieldCheck) response[field.id] = fieldCheck
    })

    return response
}