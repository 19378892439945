import { UserRoleEnum } from "@interfaces/User"
import React from "react"
import { AuthContext } from "@providers/AuthProvider"
import Collapse from "react-bootstrap/esm/Collapse"
import styles from './css/SideMenuGroup.module.scss'
import { ModuleIdEnum } from "cs.node.utils/csapi/provisioning"

type Props = {
    name: string,
    icon: string,
    expanded: boolean,
    onExpand: ()=>void,
    children?: any,
    selected: boolean
    modules?: (keyof typeof ModuleIdEnum)[]
    roles?: (keyof typeof UserRoleEnum)[]
}

const SideMenuGroup = (props: Props) => {
    const auth = React.useContext(AuthContext)
    const {name, icon, expanded, onExpand, children, selected, roles=[UserRoleEnum.SUPPORT, UserRoleEnum.BASE], modules} = props
    
    // check if the user has access to at least one of the modules listed in the modules property
    const modulesNotAllowed = modules && !auth.userData.modules?.find(module=>{
        return module._id && modules.includes(module._id) && module.enabled
    })

    // check if the user has the role needed for this page
    const roleNotAllowed = roles && !roles.includes(auth.userData.role)

    if(modulesNotAllowed || roleNotAllowed) return <></>

    const menuItemClass = expanded? 'cs-sidebar-item-open':'cs-sidebar-item-close'

    return (
        <div className={styles.container}>
            <div 
                role="button"
                className={styles.link}
                aria-controls="menu-item-content"
                onClick={() => onExpand()}>
                <div className="d-flex justify-content-between align-items-center" style={{width:'100%'}}>
                    <div className='d-flex justify-content-start align-items-center' style={{fontWeight: 600, fontSize: '1.15rem'}}>
                        {icon && <span className={` ${styles.icon}`}><i className={`${icon} ${selected?"text-primary":"text-gray-500"}`} /></span>}
                        <div className={`${selected?"text-primary":"text-gray-800"}`}>{name}</div>
                    </div>
                    <span className={`${menuItemClass} small`}>
                        <i className={`bi-chevron-up ${selected?"text-primary":"text-gray-500"}`} />
                    </span>
                </div>
            </div>
            <div>
                <Collapse in={expanded}>
                    <div id="menu-item-content">
                        <ul className="btn-toggle-nav list-unstyled fw-normal ps-1" style={{marginBottom: 0}}>
                            {children}
                        </ul>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export default SideMenuGroup