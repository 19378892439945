import React, { Fragment, useState } from 'react'
import { AuthContext } from "@providers/AuthProvider"
import { DEFAULT_LANG, SUPPORTED_LANGUAGES } from '@utils/Constants'
import Spinner from 'react-bootstrap/esm/Spinner'
import { changeLanguage } from '@api/User'
import Localization from '@localization/Index'
import { PageStates } from '@utils/PageStateUtils'
import { showError } from '@utils/NotificationsUtils'
import FlagsIcon from '@components/FlagsIcon/FlagsIcon'

const HeaderLanguage = () => {

    const auth = React.useContext(AuthContext)
    const [isLangSelectorOpen, setIsLangSelectorOpen] = useState(false)
    const [componentState, setComponentState] = useState<PageStates>(PageStates.IDLE)
    const currentLanguage = auth.userData?.defaultLang ?? DEFAULT_LANG

    const toggleChangeLanguage = ()=>{
        setIsLangSelectorOpen(prev=>!prev)
    }

    const selectLanguage = async (language:string) =>{
        if(!language) return false
        try {
            setComponentState(PageStates.LOADING)
            await changeLanguage(language)
            setIsLangSelectorOpen(false)
            await auth.setUserData()
            Localization.setLanguage(language)
            setComponentState(PageStates.IDLE)
            return
        } catch(err) {
            console.log(err)
            setIsLangSelectorOpen(false)
            setComponentState(PageStates.IDLE)
            showError(Localization.ERRORE_GENERICO)
        }
    }

    return <div className="me-4 position-relative">
        {componentState === PageStates.LOADING &&
            <Spinner variant="muted" animation="border" style={{width: '36px', height: '36px'}} />
        }
        {componentState === PageStates.IDLE &&
        <>
        <FlagsIcon role="button" borderColor="#99a1b7" onClick={()=>toggleChangeLanguage()} flagId={currentLanguage.toLocaleLowerCase()} size={40} round={true} />
        {isLangSelectorOpen && <div className="position-absolute mt-2">
            {SUPPORTED_LANGUAGES.map(supportedLang=>{
                if(supportedLang === currentLanguage) return <Fragment key={`selector_lang_${supportedLang}`}></Fragment>

                return <FlagsIcon key={`selector_lang_${supportedLang}`} role="button" hover borderColor="#99a1b7" onClick={()=>selectLanguage(supportedLang)} flagId={supportedLang.toLocaleLowerCase()} size={40} round={true} />
            })}
        </div>}
        </>
        }
    </div>
}

export default HeaderLanguage