import React, { ForwardedRef } from "react"
import { PropsWithChildren } from "react"
import styles from './css/SectionGroup.module.scss'


const SectionGroup = React.forwardRef((props: PropsWithChildren<Omit<JSX.IntrinsicElements["div"],"title"> & {title?: string | JSX.Element, subTitle?: string | JSX.Element, backgroundIcon?: string, toolbar?: JSX.Element, small?: boolean}>, ref:ForwardedRef<HTMLDivElement>) =>{
    const { title, subTitle, children, style, className="", backgroundIcon, toolbar, small, ...rest } = props

    const hasTitleOrToolbar = title || toolbar

    return <div ref={ref} style={style} className={`${styles.container} ${small?styles.small:""} ${className} ${backgroundIcon?styles["with-icon"]:""}`} {...rest}>
                {backgroundIcon && <i className={`${backgroundIcon} ${styles.backgroundIcon}`}></i>}
                <div style={{position: 'relative'}}>
                    {hasTitleOrToolbar && <div className={`${styles['title-container']}`}>
                        <div>
                            <h2>{title}</h2>
                            {subTitle && 
                            <div className={`text-gray-500`}>
                                {subTitle}
                            </div>
                            }
                        </div>
                        {toolbar && <div>{toolbar}</div>}
                    </div>}
                    <div className="">
                        {children}
                    </div>
                </div>
            </div>
})

export default SectionGroup