import { GenericModule } from "cs.node.utils/csapiextended";
interface Encrypted {
    iv: string,
    content: string
}

export interface UserData {
    id: Encrypted,
    merchant_id?: string,
    role: UserRoleEnum,
    username: string,
    firstname?: string,
    lastname?: string,
    companyname?: string,
    shortIdentifier?: string,
    appToken: Encrypted,
    csapiAuthToken: Encrypted,
    csapiUrl: string,
    defaultLang?: string,
    defaultTaxRate?: number,
    modules?: GenericModule[],
}

export enum UserRoleEnum {
    BASE="BASE",
    SUPPORT="SUPPORT",
    PACKAGER="PACKAGER",
}